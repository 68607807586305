import React from "react";
import styled from "styled-components";
import { cssTemplate, media } from "../../../../../../constants/styles";
import Responsable from "../../../../../Responsable";
import { maxWidth } from "../../../../shared/styled";

export interface ValueCardProps {
  arrayKey: string;
  intersecting?: boolean;
  title: React.ReactNode;
  pcDesc: React.ReactNode;
  tableDesc?: React.ReactNode;
  mobilDesc?: React.ReactNode;
}
const ValueCard: React.FC<ValueCardProps> = ({
  intersecting,
  title,
  pcDesc,
  tableDesc,
  mobilDesc,
}) => {
  return (
    <Wrapper theme={{ isIntersecting: intersecting }}>
      <TitleWrapper>
        <span>
          {title === "전문성" ? "💻" : ""}
          {title === "목표의식" ? "📈" : ""}
          {title === "몰입" ? "🔎" : ""}
          {title === "문제해결" ? "💡" : ""}
          {title === "영향력" ? "🌞" : ""}
          {title === "정직" ? "🙆‍♀️" : ""} {title}
        </span>
      </TitleWrapper>
      <DescWrapper>{pcDesc}</DescWrapper>
    </Wrapper>
  );
};
export default React.memo(ValueCard);
const Wrapper = styled.div`
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px;
  gap: 24px;
  background: #f8f8f8;
  max-width: 580px;
  flex: 50%;
  position: relative;
  ${maxWidth}

  ${(props) => cssTemplate.fadeInOut(props)}
  ${media.TABLET} {
    max-width: 324px;
    flex: 1;
    height: 100%;
  }
  ${media.MOBILE} {
    flex: 1;
    width: 100%;
    padding: 32px;
    height: 184px;
    gap: 16px;
    border-radius: 12px;
    max-width: 100%;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;
  & > span {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    /* identical to box height, or 138% */

    /* Gray/Gray-08 (White) */

    color: #1e1e1e;
  }
  ${media.TABLET} {
    & > span {
    }
  }
  ${media.MOBILE} {
    & > span {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 34px;
      /* identical to box height, or 155% */

      /* Gray/Gray-08 (White) */

      color: #1e1e1e;
    }
  }
`;

const DescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* or 150% */

  /* Gray/Gray-05 */

  color: #646464;
  ${media.TABLET} {
    font-size: 20px;
    line-height: 30px;
    word-break: keep-all;
  }
  ${media.MOBILE} {
    font-size: 15px;
    line-height: 22px;
  }
`;
