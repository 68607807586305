import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { OutSourcingService } from "../../../../../../constants/outsourcingServices";
import { cssTemplate, media } from "../../../../../../constants/styles";
import { useIntersecting } from "../../../../../../hooks/useIntersecting";
import Button from "../../../../../Button";

import { Suspense } from "../../../../../Suspense";
import {
  cardDate,
  cardMain,
  cardDesc,
  maxWidth,
  ArrowIcon,
  cardSubTitle,
} from "../../../../shared/styled";
import { SectionProps } from "../../../../shared/types";

interface Props extends SectionProps {
  service: OutSourcingService;
}
const cardHeight = "374px";
const tabletCardHeight = "420px";
const mobileCardHeight = "204px";
const Card: React.FC<Props> = ({ service, screenType }) => {
  const { ref, isIntersecting } = useIntersecting();
  const [interacted, setInteracted] = useState<boolean>(false);
  const isMobile = screenType === "MOBILE";
  const getServiceSmallInfo = useCallback(
    (service: OutSourcingService) => {
      switch (screenType) {
        case "TABLET":
          return service.tabletSmallInfo || service.smallInfo;
        case "MOBILE":
          return (
            service.mobileSmallInfo ||
            service.tabletSmallInfo ||
            service.smallInfo
          );

        case "PC":
        default:
          return service.smallInfo;
      }
    },
    [screenType]
  );
  const getServiceMainInfo = useCallback(
    (service: OutSourcingService) => {
      switch (screenType) {
        case "TABLET":
          return service.mainInfo;
        case "MOBILE":
          return service.mobileMainInfo || service.mainInfo;

        case "PC":
        default:
          return service.mainInfo;
      }
    },
    [screenType]
  );
  const imgHeight = useMemo(() => {
    switch (screenType) {
      case "MOBILE":
        return mobileCardHeight;
      case "TABLET":
        return tabletCardHeight;
      case "PC":
      default:
        return cardHeight;
    }
  }, [screenType]);
  const navigateToPage = (link: string) => {
    window.open(link, "_blank");
  };

  const navigateWebAppToPage = (linkObj: {
    iosLink?: string;
    aosLink?: string;
    webLink?: string;
  }) => {
    const flatform = window.navigator.userAgent.toLowerCase();
    let link;
    if (flatform.includes("ios"))
      link = linkObj.iosLink ? linkObj.iosLink : linkObj.webLink;
    else if (flatform.includes("android"))
      link = linkObj.aosLink ? linkObj.aosLink : linkObj.webLink;
    else link = linkObj.aosLink ? linkObj.aosLink : linkObj.webLink;

    window.open(link, "_blank");
  };
  useEffect(() => {
    if (isIntersecting) setInteracted(true);
  }, [isIntersecting]);
  return (
    <CardWrapper ref={ref} theme={{ isIntersecting: interacted }}>
      <CardInfoWrapper>
        <CardLogo>
          <img src={service.logoSrc} height={24} />
          <CardTitle>{service.mainTitle}</CardTitle>
        </CardLogo>
        <CardText>
          <div className="card-sub-title">{getServiceMainInfo(service)}</div>
          <div className="card-date">{service.date}</div>
          <div className="card-main">
            <div className="card-desc">{getServiceSmallInfo(service)}</div>
          </div>
          {screenType === "PC" && (
            <Button
              types="gray"
              isAnimated={false}
              label={`${
                service.link === "comingsoon"
                  ? "Coming soon"
                  : "스쿼드 인터뷰 보기"
              }`}
              onClick={() =>
                service.link && service.link !== "comingsoon"
                  ? navigateToPage(service.link)
                  : ""
              }
            />
          )}
          {screenType !== "PC" && (
            <div style={{ display: "flex" }}>
              {service.link !== "comingsoon" ? (
                <Button
                  style={{
                    padding: "10px 14px",
                    borderRadius: "8px",
                    marginRight: "12px",
                  }}
                  types="gray"
                  isAnimated={false}
                  label={<img src="/image/webp/icons/Symbol.svg" />}
                  onClick={() => service.link && navigateToPage(service.link)}
                />
              ) : (
                ""
              )}
              <Button
                style={{
                  padding: service.appLinkObj?.webLink
                    ? "10px 10px"
                    : "10px 14px",
                  borderRadius: "8px",
                }}
                types="gray"
                isAnimated={false}
                label={
                  <img
                    src={
                      service.appLinkObj?.webLink
                        ? "/image/webp/icons/Web.svg"
                        : "/image/webp/icons/Mobile.svg"
                    }
                  />
                }
                onClick={() =>
                  service.appLinkObj && navigateWebAppToPage(service.appLinkObj)
                }
              />
            </div>
          )}
        </CardText>
      </CardInfoWrapper>
      <Suspense>
        <CardImgWrapper
          onClick={() =>
            service.appLinkObj && navigateWebAppToPage(service.appLinkObj)
          }
          isLink={service.link}
        >
          <img src={service.mainImg} width="100%" height={imgHeight} />
          {screenType === "PC" && (
            <div>
              <span>VISIT</span>
            </div>
          )}
        </CardImgWrapper>
      </Suspense>
    </CardWrapper>
  );
};

export default Card;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  gap: 60px;
  ${maxWidth};
  ${(props) => cssTemplate.fadeInOut(props)}
  ${media.TABLET} {
    justify-content: flex-end;
    flex-direction: column-reverse;
    align-items: center;
  }
  ${media.MOBILE} {
    justify-content: flex-end;
    flex-direction: column-reverse;
    align-items: center;
    padding: 0px;
    gap: 20px;
    max-width: 100%;
  }
`;
const CardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  ${media.TABLET} {
    width: 100%;
    gap: 32px;
  }
  ${media.MOBILE} {
    width: 100%;
    gap: 20px;
  }
`;

const CardTitle = styled.div<{ isLink?: string }>`
  display: flex;
  align-items: center;
  gap: 13px;

  ${({ isLink }) =>
    isLink &&
    css`
      cursor: pointer;

      &:hover {
        ${ArrowIcon} {
          stroke: #ffffff;
          fill: #ffffff;
        }
      }
    `}
`;

const CardText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  & > div.card-sub-title {
    ${cardSubTitle}
  }
  & > div.card-date {
    ${cardDate}
  }
  & > div.card-main {
    ${cardMain}
    margin-bottom: 32px;

    & > div.card-desc {
      ${cardDesc}
      margin-top: 16px;
    }
  }
  ${media.TABLET} {
    gap: 12px;
    & > div.card-main {
      margin-bottom: 12px;
    }
  }
  ${media.MOBILE} {
    gap: 4px;
    & > div.card-date {
      margin-bottom: 4px;
    }

    & > div.card-main {
      margin-bottom: 20px;
    }
  }
`;
const CardLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 68px;
  /* identical to box height, or 142% */

  /* Gray/Gray-08 (White) */

  color: #1e1e1e;
  ${media.MOBILE} {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    /* Gray/Gray-08 (White) */

    color: #1e1e1e;

    & > img {
      max-width: 122px;
      // height: 100%;
    }
  }
`;
const CardImgWrapper = styled.div<{ src?: string; isLink?: string }>`
  flex: 0 0 600px;
  border-radius: 24px;
  ${maxWidth}
  height: ${cardHeight};
  overflow: hidden;

  ${({ isLink }) =>
    isLink &&
    css`
      cursor: pointer;

      &:hover {
        & > div:nth-child(2) {
          background-color: rgba(17, 17, 17, 0.4);

          transition: all 0.3s;
          & > span {
            opacity: 1;
          }
        }
        & > img {
          transform: scale(1.1);
          transition: all 0.3s;
          ${media.TABLET} {
            transform: none;
          }
        }
      }
    `}
  & > img {
    transform: scale(1);
    transition: all 0.3s;
    object-fit: cover;
  }
  & > div:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${cardHeight};
    background-color: rgba(0, 0, 0, 0);

    & > span {
      font-family: "Fivo Sans Modern";
      font-style: italic;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: white;
      cursor: pointer;
      vertical-align: middle;
      opacity: 0;
    }

    transition: all 0.3s linear;
    justify-content: center;
    align-items: center;
    align-content: center;
    display: flex;
  }
  ${media.TABLET} {
    flex: 0 0 ${tabletCardHeight};
    & > div:nth-child(2) {
      height: ${tabletCardHeight};
      & > img {
        transform: scale(1.2);
      }
    }
  }
  ${media.MOBILE} {
    flex: 0 0 ${mobileCardHeight};
    border-radius: 12px;
    & > div:nth-child(2) {
      height: ${mobileCardHeight};
    }

    max-width: 100%;
    width: 100%;
  }
  position: relative;
`;
