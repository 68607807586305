import React from "react";
import styled, { css } from "styled-components";
import { cssTemplate, media } from "../../constants/styles";

import { ReactComponent as Menu } from "../../svgs/menu.svg";

interface MenuButtonProps {
  onClick: () => void;
  menuVisible: boolean;
}

const MenuButton: React.FC<MenuButtonProps> = (props) => {
  return (
    <StyledButton onClick={props.onClick}>
      <StyledMenu theme={{ menuVisible: props.menuVisible }} />
    </StyledButton>
  );
};

export default MenuButton;

const StyledButton = styled.button`
  padding: 0;

  color: #ffffff;
  background: none;
  border: none;

  cursor: pointer;
`;

const StyledMenu = styled(Menu)`
  width: 27px;
  height: 27px;

  ${media.MOBILE} {
    width: 22px;
    height: 22px;
  }

  .hamburger__bar--top {
    transform: translateY(-35%);
  }

  .hamburger__bar--bot {
    transform: translateY(35%);
  }

  .hamburger__bar {
    transition-property: transform;
    transition-duration: 0.3s;
    transform-origin: center;
    stroke: white;
    stroke-width: 10%;
  }

  ${({ theme }) => {
    if (theme.menuVisible) {
      return css`
        .hamburger__bar--top {
          transform: rotate(45deg);
        }

        .hamburger__bar--mid {
          transform: scaleX(0.1);
        }

        .hamburger__bar--bot {
          transform: rotate(-45deg);
        }
      `;
    }
  }}
`;
