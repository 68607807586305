import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { DEFAULT_INTERVAL } from "../../../../../constants/animationValue";
import {
  cssTemplate,
  media,
  mobileContainerGap,
  mobilePaddingTopBottom,
  pcContainerGap,
  pcPaddingTopBottom,
  tabletContainerGap,
  tabletPaddingTopBottom,
} from "../../../../../constants/styles";
import { useIntersecting } from "../../../../../hooks/useIntersecting";
import useInterval from "../../../../../hooks/useInterval";
import Button from "../../../../Button";
import Responsable from "../../../../Responsable";
import BaseSection from "../../../BaseSection";
import { maxWidth } from "../../../shared/styled";
import {
  Desc,
  DescWrapper,
  InfoWrapper,
  SectionLayout,
  TextH1,
  TextSpan,
  TextWrapper,
  TitleWrapper,
} from "../styled";

interface SectionProps {
  titleSpan: React.ReactNode;
  titleH1: React.ReactNode;
  buttonLabel: string;
  buttionClickHandler: (e: React.MouseEvent) => void;
  bottom?: React.ReactNode;
  descs: string[];
  types?: string;
}

const TabletSection: React.FC<SectionProps> = ({
  titleH1,
  titleSpan,
  buttionClickHandler,
  buttonLabel,
  bottom,
  descs,
  types,
}) => {
  const { ref, isIntersecting } = useIntersecting();
  const [isInteracted, setIsInteracted] = useState(-1);
  useInterval(
    () => {
      setIsInteracted(isInteracted + 1);
    },
    isIntersecting
      ? isInteracted > descs.length
        ? null
        : DEFAULT_INTERVAL
      : null
  );
  return (
    <BaseSection fullWidth>
      <SectionLayout>
        <InfoWrapper>
          <TitleWrapper>
            <TextWrapper>
              <TextSpan>{titleSpan}</TextSpan>
              <TextH1>{titleH1}</TextH1>
            </TextWrapper>
          </TitleWrapper>
          {types === "valueup" && bottom}
          <DescWrapper ref={ref}>
            {descs.map((desc, index) => (
              <Desc key={desc}>{desc}</Desc>
            ))}
          </DescWrapper>
        </InfoWrapper>
        {(types === "outsourcing" || types === "tech") && bottom}
        <Button
          isAnimated={false}
          types="black"
          label={buttonLabel}
          onClick={buttionClickHandler}
        />
      </SectionLayout>
    </BaseSection>
  );
};

export default TabletSection;
