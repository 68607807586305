import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BUSINESS_CARD_INTERVAL } from "../../../../constants/animationValue";
import { navigationState } from "../../../../constants/ref";
import routes from "../../../../constants/routes";
import { animations, cssTemplate, media } from "../../../../constants/styles";
import { valueIcons } from "../../../../constants/valueUpServices";

import { useIntersecting } from "../../../../hooks/useIntersecting";
import useInterval from "../../../../hooks/useInterval";
import Responsable from "../../../Responsable";
import BaseSection from "../../BaseSection";
import { Title } from "../../shared/styled";
import MobileSection from "../shared/MobileSection";
import PCSection from "../shared/PCSection";
import TabletSection from "../shared/TabletSection";

interface BusinessSection02Props {}

const BusinessSection02: React.FC<BusinessSection02Props> = (props) => {
  const { ref, isIntersecting } = useIntersecting();

  // const mobileDescs = [
  //   "폭발적 성장 잠재력이 있는 서비스를 직접 세상에 선보입니다.",
  //   `최초의 앱 서비스 봄블링과 최초의 웹 서비스 쓱싹을\n모두 성공시켰고, 비급여 메디컬 시장에 자체서비스\n‘부스트리’가 새롭게 출시됩니다.`,
  //   `슬로그업은 안정적 사업 구축을 위한 JV(Joint\nVenture) 협력에도 열려 있습니다. 특정 영역에\n강하지만 IT기반이 부족해 어려움을 겪고 있다면\n저희가 든든한 사업 파트너가 되어 드리겠습니다.`,
  // ];
  // const tabletDescs = [
  //   "폭발적 성장 잠재력이 있는 서비스를 직접 세상에 선보입니다.",
  //   `최초의 앱 서비스 봄블링과 최초의 웹 서비스 쓱싹을 모두 성공시켰고,\n비급여 메디컬 시장에 자체서비스 ‘부스트리’가 새롭게 출시됩니다.`,
  //   `슬로그업은 안정적 사업 구축을 위한 JV(Joint Venture) 협력에도\n열려 있습니다. 특정 영역에 강하지만 IT기반이 부족해 어려움을 겪고\n있다면 저희가 든든한 사업 파트너가 되어 드리겠습니다.`,
  // ];
  // const descs = [
  //   `슬로그업은 안정적 사업 구축을 위한 JV(Joint Venture) 협력에도\n열려 있습니다. 특정 영역에 강하지만 IT기반이 부족해 어려움을 겪고\n있다면 저희가 든든한 사업 파트너가 되어 드리겠습니다.`,
  //   `최초의 앱 서비스 봄블링과 최초의 웹 서비스 쓱싹을 모두 성공시켰고,\n비급여 메디컬 시장에 자체서비스 ‘부스트리’가 새롭게 출시됩니다.`,
  //   "폭발적 성장 잠재력이 있는 서비스를 직접 세상에 선보입니다.",
  // ];
  const mobileDescs = [
    "폭발적 성장 잠재력이 있는 서비스를 직접 세상에 선보입니다.",
    `최초의 앱 서비스 봄블링과 최초의 웹 서비스 쓱싹을\n모두 성공시켰고, 새로운 시장에 도전하고 있습니다.`,
    `슬로그업은 안정적 사업 구축을 위한 JV(Joint\nVenture) 협력에도 열려 있습니다. 특정 영역에\n강하지만 IT기반이 부족해 어려움을 겪고 있다면\n저희가 든든한 사업 파트너가 되어 드리겠습니다.`,
  ];
  const tabletDescs = [
    "폭발적 성장 잠재력이 있는 서비스를 직접 세상에 선보입니다.",
    `최초의 앱 서비스 봄블링과 최초의 웹 서비스 쓱싹을 모두 성공시켰고,\n새로운 시장에 도전하고 있습니다.`,
    `슬로그업은 안정적 사업 구축을 위한 JV(Joint Venture) 협력에도\n열려 있습니다. 특정 영역에 강하지만 IT기반이 부족해 어려움을 겪고\n있다면 저희가 든든한 사업 파트너가 되어 드리겠습니다.`,
  ];
  const descs = [
    `슬로그업은 안정적 사업 구축을 위한 JV(Joint Venture) 협력에도\n열려 있습니다. 특정 영역에 강하지만 IT기반이 부족해 어려움을 겪고\n있다면 저희가 든든한 사업 파트너가 되어 드리겠습니다.`,
    `최초의 앱 서비스 봄블링과 최초의 웹 서비스 쓱싹을 모두 성공시켰고,\n새로운 시장에 도전하고 있습니다.`,
    "폭발적 성장 잠재력이 있는 서비스를 직접 세상에 선보입니다.",
  ];
  const nav = useNavigate();
  const handleClickButton = (e: React.MouseEvent) => {
    e.preventDefault();
    nav(routes.HOME, { state: navigationState.SCROLL_TO_VALUE_UP });
  };
  useEffect(() => {}, []);
  return (
    <BaseSection fullWidth ref={ref}>
      <Responsable
        pc={
          <PCSection
            titleH1={
              <>
                슬로그업이 직접
                <br />
                출시한 서비스.
              </>
            }
            titleSpan="Valueup Service"
            buttonLabel="보러가기"
            buttionClickHandler={handleClickButton}
            descs={descs}
            bottom={<Bottom />}
            types="valueup"
          />
        }
        tablet={
          <TabletSection
            titleH1={
              <>
                슬로그업이 직접
                <br />
                출시한 서비스.
              </>
            }
            titleSpan="Valueup Service"
            buttonLabel="보러가기"
            buttionClickHandler={handleClickButton}
            descs={tabletDescs}
            bottom={<Bottom />}
            types="valueup"
          />
        }
        mobile={
          <MobileSection
            titleH1={
              <>
                슬로그업이 직접
                <br />
                출시한 서비스.
              </>
            }
            titleSpan="Valueup Service"
            buttonLabel="보러가기"
            buttionClickHandler={handleClickButton}
            descs={mobileDescs}
            bottom={<Bottom />}
            types="valueup"
          />
        }
      />
    </BaseSection>
  );
};

export default BusinessSection02;

const Bottom: React.FC<{}> = ({}) => {
  const { ref, isIntersecting } = useIntersecting();
  const [isInteracted, setIsInteracted] = useState(-1);
  useInterval(
    () => {
      setIsInteracted(isInteracted + 1);
    },
    isIntersecting
      ? isInteracted > valueIcons.length
        ? null
        : BUSINESS_CARD_INTERVAL
      : null
  );
  return (
    <BottomWrapper ref={ref}>
      {valueIcons.map((value, index) => (
        <Icon
          key={value}
          src={"/image/webp/business/" + value}
          theme={{ isIntersecting: isInteracted >= index }}
        />
      ))}
    </BottomWrapper>
  );
};

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  ${media.MOBILE} {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
  }
`;
const Icon = styled.div<{ src: string }>`
  width: 120px;
  height: 120px;
  /* background: linear-gradient(180deg, #ffffff 0%, #e1e1e1 86.98%, #d1d1d1 100%); */
  /* background-image: url(${({ src }) => src}); */
  /* background-size: contain; */
  background: no-repeat center/contain url(${({ src }) => src});

  /* box-shadow: inset 2px 2px 2px #e9e9e9, inset -2px -2px 2px rgba(0, 0, 0, 0.25); */
  /* border-radius: 32px; */
  ${(props) => cssTemplate.fadeInOut(props)}
  ${media.MOBILE} {
    width: 68px;
    height: 68px;
    /* border-radius: 18.1333px; */
  }
`;
