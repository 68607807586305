import React, { useState } from "react";
import styled from "styled-components";
import { BUSINESS_CARD_INTERVAL } from "../../../../constants/animationValue";
import { consultingServices } from "../../../../constants/consultingServices";
import outSourcingServices, {
  outsourcingIcons,
} from "../../../../constants/outsourcingServices";
import { animations, cssTemplate, media } from "../../../../constants/styles";
import { valueIcons } from "../../../../constants/valueUpServices";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import useInterval from "../../../../hooks/useInterval";
import Responsable from "../../../Responsable";
import { maxWidth, Title } from "../../shared/styled";
import MobileSection from "../shared/MobileSection";
import PCSection from "../shared/PCSection";
import TabletSection from "../shared/TabletSection";

interface LastSectionProps {}

const LastSection: React.FC<LastSectionProps> = (props) => {
  const baseImagePath = "/image/webp/business/";
  const mobileDescs = [
    `세상을 놀라게 할 아이디어를 품고 계신가요? `,
    `슬로그업은 IT가 필요한 곳에 IT가 있게 하는 ‘테크 프로바이더’입니다. 반짝이는 아이디어가 세상에 나오지 못하고 사라지는 일이 없도록, 멋진 아이디어를 품은 초기 기업에 일정 지분을 받고 IT 기술력을 투자합니다.`,
    `세상을 크게 깨울 아이디어가 있지만 IT가 걱정이라면 슬로그업의 문을 두드려주세요.\n고민을 덜어드리겠습니다.`,
  ];
  const tabletDescs = [
    `세상을 놀라게 할 아이디어를 품고 계신가요? `,
    `슬로그업은 IT가 필요한 곳에 IT가 있게 하는 ‘테크 프로바이더’입니다.\n반짝이는 아이디어가 세상에 나오지 못하고 사라지는 일이 없도록, 멋진\n아이디어를 품은 초기 기업에 일정 지분을 받고 IT 기술력을 투자합니다.`,
    `세상을 크게 깨울 아이디어가 있지만 IT가 걱정이라면\n슬로그업의 문을 두드려주세요. 고민을 덜어드리겠습니다.`,
  ];
  const descs = [
    `세상을 놀라게 할 아이디어를 품고 계신가요? `,
    `슬로그업은 IT가 필요한 곳에 IT가 있게 하는 ‘테크 프로바이더’입니다.\n반짝이는 아이디어가 세상에 나오지 못하고 사라지는 일이 없도록, 멋진\n아이디어를 품은 초기 기업에 일정 지분을 받고 IT 기술력을 투자합니다.`,
    `세상을 크게 깨울 아이디어가 있지만 IT가 걱정이라면\n슬로그업의 문을 두드려주세요. 고민을 덜어드리겠습니다.`,
  ];

  const handleClickButton = (e: React.MouseEvent) => {
    // e.preventDefault();
    // nav(routes.RECRUIT);
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSdA6fYl-ZEyfe0XE-rE7IfDLp_BtPOmig0fbTiSwY9J-24DiA/viewform",
      "_blank"
    );
  };
  return (
    <Responsable
      pc={
        <Section4>
          <PCSection
            titleH1={
              <>
                빛나는 아이디어에
                <br />
                IT 기술력 투자.
              </>
            }
            titleSpan="Tech Investment"
            buttonLabel="투자 문의하기"
            buttionClickHandler={handleClickButton}
            descs={descs}
            bottom={<Bottom src={baseImagePath + "BusiLast-Pc.webp"} />}
            types="tech"
          />
        </Section4>
      }
      tablet={
        <Section4>
          <TabletSection
            titleH1={
              <>
                빛나는 아이디어에
                <br />
                IT 기술력 투자.
              </>
            }
            titleSpan="Tech Investment"
            buttonLabel="투자 문의하기"
            buttionClickHandler={handleClickButton}
            descs={tabletDescs}
            bottom={<Bottom src={baseImagePath + "BusiLast-Tablet.webp"} />}
            types="tech"
          />
        </Section4>
      }
      mobile={
        <Section4>
          <MobileSection
            titleH1={
              <>
                빛나는 아이디어에
                <br />
                IT 기술력 투자.
              </>
            }
            titleSpan="Tech Investment"
            buttonLabel="투자 문의하기"
            buttionClickHandler={handleClickButton}
            descs={mobileDescs}
            bottom={<Bottom src={baseImagePath + "BusiLast-Mobile.webp"} />}
            types="tech"
          />
        </Section4>
      }
    />
  );
};

export default LastSection;

const Section4 = styled.div`
  width: 100%;
  background-color: #f8f8f8;
  display: flex;
  flex-wrap: wrap;
`;

const Bottom: React.FC<{ src: string }> = ({ src }) => {
  const { ref, isIntersecting } = useIntersecting();
  const [isInteracted, setIsInteracted] = useState(-1);
  useInterval(
    () => {
      setIsInteracted(isInteracted + 1);
    },
    isIntersecting ? (isInteracted > 0 ? null : BUSINESS_CARD_INTERVAL) : null
  );
  return (
    <BottomWrapper
      src={src}
      ref={ref}
      theme={{ isIntersecting: isInteracted >= 0 }}
    ></BottomWrapper>
  );
};
const BottomWrapper = styled.div<{ src: string }>`
  border-radius: 24px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  gap: 20px;
  width: 100%;
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  ${maxWidth}
  ${(props) => cssTemplate.fadeInOut(props)}
  ${media.MOBILE} {
    border-radius: 12px;
    background-size: contain;

    height: 200px;
  }
`;
