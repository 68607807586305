import React, { useMemo } from "react";
import styled from "styled-components";
import { cultures } from "../../../../constants/cultures";
import { welfare } from "../../../../constants/ref";
import {
  cssTemplate,
  grid,
  media,
  mobileContainerGap,
  mobileContainerSmallGap,
  mobilePaddingTopBottom,
  pcPaddingTopBottom,
  tabletContainerGap,
  tabletPaddingTopBottom,
} from "../../../../constants/styles";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import Responsable from "../../../Responsable";
import BaseSection from "../../BaseSection";
import { StyledTitle, Title, TitleWrapper } from "../../shared/styled";
import CultureCard from "./CultureCard";

interface TeamSection04Props {}

const TeamSection04: React.FC<TeamSection04Props> = (props) => {
  // 768 밑으로 가면
  // const viewerDeviceCheck = () => {
  //   if()
  // }
  const data = useMemo(() => {
    return cultures;
  }, []);
  const { ref, isIntersecting } = useIntersecting();
  return (
    <BaseSection fullWidth>
      <SectionLayout>
        <TitleWrapper ref={ref} theme={{ isIntersecting }}>
          <StyledTitle>
            <span>Backed Culture</span>
            <h1>
              슬로그업은
              <br />
              이런 문화에서 일합니다.
            </h1>
          </StyledTitle>
        </TitleWrapper>
        {data.map((culture) => (
          <CultureCard key={culture.title} {...culture} />
        ))}
      </SectionLayout>
    </BaseSection>
  );
};

export default TeamSection04;

const SectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 120px;

  padding: ${() => `${pcPaddingTopBottom} 48px`};

  ${media.TABLET} {
    gap: ${tabletContainerGap};
    padding: ${() => `${tabletPaddingTopBottom} 48px`};
  }

  ${media.MOBILE} {
    justify-content: center;
    align-items: center;
    gap: ${mobileContainerSmallGap};
    padding: ${() => `${mobilePaddingTopBottom} 24px`};
  }
`;
