import { ValueCardProps } from "../../components/sections/teamSections/scrollOverlays/ScrollOverlayValueCardsFadeIn/ValueCard";

const valueCards: ValueCardProps[][] = [
  [
    {
      arrayKey: "professionalism",
      title: "전문성",
      // TODO 태블릿, 모바일 텍스트 따로
      pcDesc: (
        <>
          각자의 장점으로 일을 완성합니다. 재차 돌아보고 완성의 순도에 관해
          고민합니다. 자만하지 않고 공부합니다.
        </>
      ),
    },
    {
      arrayKey: "senseOfPurpose",
      title: "목표의식",
      pcDesc: (
        <>
          출발선에서 우선순위와 목표를 설정합니다. 그게 올바른 길로 가는
          이정표가 되어준다는 걸 이해합니다.
        </>
      ),
    },
  ],
  [
    {
      arrayKey: "absorbed",
      title: "몰입",
      pcDesc: (
        <>
          몰입이 제한된 리소스를 초월하는 결과를 만든다고 여깁니다. 그 힘을 적극
          활용하고 그러기 위한 환경을 만듭니다.
        </>
      ),
    },
    {
      arrayKey: "problemSolving",
      title: "문제해결",
      pcDesc: (
        <>
          우리 일은 어려운 문제를 해결하는 복잡한 일입니다. 문제가 생기는 건
          당연하고, 면역세포가 생기는 과정으로 여깁니다.
        </>
      ),
    },
  ],
  [
    {
      arrayKey: "influence",
      title: "영향력",
      pcDesc: (
        <>
          서로가 서로에게 좋은 영향을 주고 받습니다. 내부 뿐 아니라 비즈니스
          파트너의 삶에도 도움이 되도록 노력합니다.
        </>
      ),
    },
    {
      arrayKey: "honesty",
      title: "정직",
      pcDesc: (
        <>
          스스로와 동료에게 떳떳할 정직함을 지킵니다. 정직을 기준으로 한 결정과
          행동이 좋은 나비효과를 가져온다고 믿습니다.
        </>
      ),
    },
  ],
];

export default valueCards;
