import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { animations, cssTemplate, media } from "../../../../constants/styles";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import Responsable from "../../../Responsable";
import BaseSection from "../../BaseSection";
import { maxWidth, MovingArrow, Title } from "../../shared/styled";
import Section1Content from "./Content/SectionContent";

interface BusinessSection01Props {}

const BusinessSection01: React.FC<BusinessSection01Props> = (props) => {
  // 이게 보이는 화면에 들어와 있는지 체크 하는 함수
  const { ref, isIntersecting } = useIntersecting();
  const [interacted, setInteracted] = useState<boolean>(false);
  useEffect(() => {
    if (isIntersecting) setInteracted(true);
  }, [isIntersecting]);
  return (
    <Base ref={ref} fullWidth fullHeight>
      <Responsable
        pc={<Section1Content screenType="pc" />}
        tablet={<Section1Content screenType="tablet" />}
        mobile={<Section1Content screenType="tablet" />}
      />
    </Base>
  );
};

export default BusinessSection01;

const Base = styled(BaseSection)`
  position: relative;
  min-height: 600px;
`;
