import _ from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { interviews } from "../../constants/ref";
import { cssTemplate, media } from "../../constants/styles";
import { maxWidth } from "../sections/shared/styled";
import { ScreenType } from "../sections/shared/types";

enum LOCATION {
  START,
  MIDDLE,
  END,
}
interface Props {
  screenType: ScreenType;
}
const MobileCarousel: React.FC<Props> = ({ screenType }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const [index, setIndex] = useState(0);

  const [location, setLocation] = useState(LOCATION.START);
  const [isMoving, setIsMoving] = useState<boolean>(false);
  const width = screenType === "PC" ? 400 : screenType === "TABLET" ? 386 : 300;
  const gap = 20;
  const scrollAmount = width + gap;
  const data = useMemo(() => {
    switch (screenType) {
      case "PC":
      case "TABLET":
      case "MOBILE":
        const newInterviews = [...interviews];
        newInterviews.pop();
        newInterviews.shift();
        return newInterviews;

      default:
        return interviews;
    }
  }, [screenType]);
  const maxIndex =
    Math.floor(
      ((scrollRef.current?.scrollWidth || 0) -
        (scrollRef.current?.clientWidth || 0)) /
        scrollAmount
    ) || 1;
  const handleClick = useCallback(
    (direction: string) => {
      if (scrollRef.current) {
        const { scrollLeft, scrollWidth } = scrollRef.current;

        let scrollTo = scrollLeft;
        if (direction === "left") {
          if (index === 0) scrollTo = 0;
          else if (index === 1) {
            scrollTo = 0;
          } else if (index > 0) {
            scrollTo = (index - 1) * scrollAmount;
            setIndex(index - 1);
          }
        } else {
          if (index >= maxIndex - 1) {
            scrollTo = scrollWidth;
          } else if (index < maxIndex - 1) {
            scrollTo = (index + 1) * scrollAmount;
            setIndex(index + 1);
          }
        }

        scrollRef.current.scrollTo({ left: scrollTo, behavior: "smooth" });
      }
    },
    [index, maxIndex, scrollRef.current]
  );
  useEffect(() => {
    if (!isMoving) {
      if (index === 0) setLocation(LOCATION.START);
      else if (index === maxIndex) setLocation(LOCATION.END);
      else setLocation(LOCATION.MIDDLE);
    }
  }, [index]);
  useEffect(() => {
    scrollRef.current?.addEventListener(
      "scroll",
      _.debounce((e) => {
        setIsMoving(false);
        setIndex(
          Math.floor((scrollRef.current?.scrollLeft || 0) / scrollAmount)
        );
      }, 50)
    );
  }, []);

  return (
    <ListWrapper>
      <CardButtonWrapper>
        <CardListNavButton
          isActive={location !== LOCATION.START}
          onClick={() => handleClick("left")}
        >
          <ArrowIcon style={{ transform: `rotate(180deg)` }}></ArrowIcon>
          <span> PREV</span>
        </CardListNavButton>
        <CardListNavButton
          isActive={location !== LOCATION.END}
          onClick={() => handleClick("right")}
        >
          <span>NEXT</span>
          <ArrowIcon></ArrowIcon>
        </CardListNavButton>
      </CardButtonWrapper>
      <StyledRelativeDiv>
        <ScrollContainer gap={20} ref={scrollRef}>
          {Array.from(data).map((item, index) =>
            item.blank ? (
              <BlankCard key={"blank" + index} />
            ) : (
              <Card key={(item.name || "") + index}>
                <CardInterview>{item.interview}</CardInterview>
                <CardProfile>
                  <div>
                    <h4>{item.name}</h4>
                    <span>{item.team}</span>
                  </div>
                  <img src={item.profileUrl} alt={item.name} />
                </CardProfile>
              </Card>
            )
          )}
        </ScrollContainer>
      </StyledRelativeDiv>
    </ListWrapper>
  );
};

export default MobileCarousel;
const StyledRelativeDiv = styled.div`
  position: relative;
  width: 100vw;
`;

const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 40px;
  ${media.TABLET} {
    gap: 20px;
  }
`;
const CardButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  ${maxWidth};
  width: 100%;
  ${media.TABLET} {
    // padding: 0 48px;
  }
  ${media.MOBILE} {
    max-width: 100%;
  }
`;
const CardListNavButton = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  /* identical to box height, or 120% */

  /* Gray/Gray05, White */
  color: #ffffff;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  cursor: ${({ isActive }) => (isActive ? "pointer" : "normal")};
  & > span {
    // font-family: "Fivo Sans Modern";
    // font-style: italic;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-top: 4px;
    height: 24px;
  }
  ${media.TABLET} {
    & > span {
      font-size: 20px;
      line-height: 24px;
    }
  }
  ${media.MOBILE} {
    & > span {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

const ArrowIcon = styled.div`
  width: 32px;
  height: 32px;
  background-image: url("/image/team/ArrowWhite.png");
  background-size: cover;
`;

interface FlexProps {
  gap?: number;
}

const StyledFlexDiv = styled.div<FlexProps>`
  display: flex;
  align-items: center;
  ${({ gap }) =>
    css`
      gap: ${gap}px;
    `}
`;

const ScrollContainer = styled(StyledFlexDiv)`
  overflow: scroll;
  padding: 0px 48px;
  ${media.TABLET} {
    padding: 0px 48px;
    // padding: 0px calc((100vw - 672px) / 2);
  }
  ${media.MOBILE} {
    padding: 0px 24px;
    // padding: 0px calc((100vw - 327px) / 2);
  }
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Card = styled.div`
  border-radius: 24px;
  width: 400px;
  min-width: 400px;
  height: 498px;
  color: #ffffff;
  padding: ${() => `40px`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #1e1e1e;
  ${media.TABLET} {
    width: 386px;
    min-width: 386px;
    height: 560px;
    padding: ${() => `40px`};
  }
  ${media.MOBILE} {
    border-radius: 16px;
    width: 300px;
    min-width: 300px;
    height: 400px;
    padding: ${() => `20px`};
  }
`;
const BlankCard = styled.div`
  width: 400px;
  min-width: 400px;
  height: 498px;
  background-color: transparent;
`;
const CardInterview = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  word-break: keep-all;
  ${media.TABLET} {
    font-size: 20px;
    line-height: 34px;
  }
  ${media.MOBILE} {
    font-size: 15px;
    line-height: 24px;
  }
`;

const CardProfile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  > div {
    display: flex;
    flex-direction: column;
    h4 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 8px;
    }
    > span {
      font-size: 18px;
      font-weight: 400;
      color: #949494;
    }
  }
  > img {
    width: 98px;
    height: 98px;
  }

  ${media.TABLET} {
    > div {
      h4 {
        font-size: 24px;
        line-height: 36px;
      }
      > span {
        font-size: 18px;
        line-height: 28px;
      }
    }
    > img {
      width: 98px;
      height: 98px;
    }
  }
  ${media.MOBILE} {
    > div {
      h4 {
        font-size: 22px;
        margin-bottom: 8px;
        line-height: 1.5;
      }
      > span {
        font-size: 14px;
      }
    }
    > img {
      width: 70px;
      height: 70px;
    }
  }
`;
