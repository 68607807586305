import styled from "styled-components";
import { cssTemplate, media } from "../../../../constants/styles";

export const SectionLayout = styled.div`
  /*  */
<<<<<<< HEAD
  height: ${cssTemplate.vwConvertPc(376)};
=======
  height: 376px;
>>>>>>> 051efa242682533097dc78e7dc5e26389096789e
  background: #fff;
  ${media.TABLET} {
    height: auto;
  }
  ${media.MOBILE} {
    height: auto;
  }
`;

export const ContentLayout = styled.div`
  /*  */
<<<<<<< HEAD
  width: ${cssTemplate.vwConvertPc(1040)};
  padding: ${cssTemplate.vwConvertPc(100)} 0;
=======
  width: 1040px;
  padding: 100px 0;
>>>>>>> 051efa242682533097dc78e7dc5e26389096789e
  margin: auto;

  > span {
    font-size: ${cssTemplate.vwConvertPc(18)};
    font-weight: 500;
    line-height: 1.6;
  }

  ${media.TABLET} {
<<<<<<< HEAD
    padding: ${cssTemplate.vwConvertTablet(60)}
      ${cssTemplate.vwConvertTablet(48)};
=======
    padding: 60px 48px;
>>>>>>> 051efa242682533097dc78e7dc5e26389096789e
    width: 100%;
    > span {
      font-size: ${cssTemplate.vwConvertTablet(16)};
      font-weight: 500;
      line-height: 1.5;
    }
  }
  ${media.MOBILE} {
<<<<<<< HEAD
    max-width: ${cssTemplate.vwConvertMobile(327)};
    height: auto;
    padding: ${cssTemplate.vwConvertMobile(60)} 0;
=======
    width: 327px;
    height: auto;
    padding: 60px 0;
>>>>>>> 051efa242682533097dc78e7dc5e26389096789e

    > span {
      font-size: ${cssTemplate.vwConvertMobile(16)};
      font-weight: 500;
      line-height: 1.5;
    }
  }
`;

export const Title = styled.p`
  /*  */
  font-size: ${cssTemplate.vwConvertPc(32)};
  font-weight: 700;
  line-height: 1.4;
<<<<<<< HEAD
  margin-bottom: ${cssTemplate.vwConvertPc(48)};

  ${media.TABLET} {
    font-size: ${cssTemplate.vwConvertTablet(22)};
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: ${cssTemplate.vwConvertTablet(24)};
  }
  ${media.MOBILE} {
    font-size: ${cssTemplate.vwConvertMobile(22)};
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: ${cssTemplate.vwConvertMobile(24)};
=======
  margin-bottom: 48px;

  ${media.TABLET} {
    font-size: ${cssTemplate.vwConvertTablet(24)};
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 24px;
  }
  ${media.MOBILE} {
    font-size: ${cssTemplate.vwConvertMobile(24)};
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 24px;
>>>>>>> 051efa242682533097dc78e7dc5e26389096789e
  }
`;

export const GridWrap = styled.ul`
  /*  */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
<<<<<<< HEAD
  row-gap: ${cssTemplate.vwConvertPc(48)};
  column-gap: ${cssTemplate.vwConvertPc(17)};
=======
  row-gap: 48px;
  column-gap: 17px;
>>>>>>> 051efa242682533097dc78e7dc5e26389096789e

  ${media.MOBILE} {
    display: flex;
    flex-direction: column;
  }
`;

export const GridItem = styled.li`
  /*  */
<<<<<<< HEAD
  width: ${cssTemplate.vwConvertPc(512)};
  height: ${cssTemplate.vwConvertPc(68)};
  display: flex;
  gap: ${cssTemplate.vwConvertPc(16)};
  ${media.TABLET} {
    width: auto;
    gap: ${cssTemplate.vwConvertTablet(16)};
=======
  width: 512px;
  height: 68px;
  display: flex;
  gap: 16px;
  ${media.TABLET} {
    width: auto;
    gap: 16px;
>>>>>>> 051efa242682533097dc78e7dc5e26389096789e
  }
`;

export const ItemContents = styled.div`
  /*  */
  > p {
    font-size: ${cssTemplate.vwConvertPc(20)};
    font-weight: 700;
    line-height: 1.7;
<<<<<<< HEAD
    margin-bottom: ${cssTemplate.vwConvertPc(6)};
=======
    margin-bottom: 6px;
>>>>>>> 051efa242682533097dc78e7dc5e26389096789e
  }

  > span {
    font-size: ${cssTemplate.vwConvertPc(18)};
    font-weight: 500;
    line-height: 1.7;
    color: #646464;
  }
  ${media.TABLET} {
    > p {
      font-size: ${cssTemplate.vwConvertTablet(17)};
<<<<<<< HEAD
      margin-bottom: ${cssTemplate.vwConvertTablet(6)};
=======
      margin-bottom: 6px;
>>>>>>> 051efa242682533097dc78e7dc5e26389096789e
    }

    > span {
      display: block;

      font-size: ${cssTemplate.vwConvertTablet(16)};
      line-height: 1.5;
      font-weight: 400;
    }
  }
  ${media.MOBILE} {
    > p {
      font-size: ${cssTemplate.vwConvertMobile(17)};
<<<<<<< HEAD
      margin-bottom: ${cssTemplate.vwConvertMobile(6)};
=======
      margin-bottom: 6px;
>>>>>>> 051efa242682533097dc78e7dc5e26389096789e
    }

    > span {
      display: block;
<<<<<<< HEAD
      /* width: ${cssTemplate.vwConvertMobile(200)}; */
      height: ${cssTemplate.vwConvertMobile(48)};
=======
      width: 200px;
      height: 48px;
>>>>>>> 051efa242682533097dc78e7dc5e26389096789e
      font-size: ${cssTemplate.vwConvertMobile(16)};
      line-height: 1.5;
      font-weight: 400;
    }
  }
`;

export const ImageLayout = styled.div`
  /*  */
  position: relative;
<<<<<<< HEAD
  height: ${cssTemplate.vwConvertPc(840)};
=======
  height: 840px;
>>>>>>> 051efa242682533097dc78e7dc5e26389096789e
  display: flex;
  gap: 8px;
`;

export const ImageWrap = styled.div`
  /*  */
  position: relative;
<<<<<<< HEAD
  width: ${cssTemplate.vwConvertPc(956)};
=======
  width: 956px;
>>>>>>> 051efa242682533097dc78e7dc5e26389096789e
  height: 100%;
  background: #f8f8f8;
  overflow: hidden;
  ${media.TABLET} {
    display: inline-block;
    width: 100%;
<<<<<<< HEAD
    height: ${cssTemplate.vwConvertTablet(540)};
  }
  ${media.MOBILE} {
    display: inline-block;
    width: ${cssTemplate.vwConvertMobile(375)};
    height: ${cssTemplate.vwConvertMobile(540)};
=======
    height: 540px;
  }
  ${media.MOBILE} {
    display: inline-block;
    width: 375px;
    height: 540px;
>>>>>>> 051efa242682533097dc78e7dc5e26389096789e
  }
`;
export const SmallView = styled.span`
  /*  */
  display: inline-block;
<<<<<<< HEAD
  width: ${cssTemplate.vwConvertPc(284)};
  height: ${cssTemplate.vwConvertPc(614)};
  box-shadow: 0px 32px 48px rgba(0, 0, 0, 0.08);

  ${media.TABLET} {
    width: ${cssTemplate.vwConvertTablet(284)};
    height: ${cssTemplate.vwConvertTablet(614)};
  }

  ${media.MOBILE} {
    width: ${cssTemplate.vwConvertMobile(154)};
    height: ${cssTemplate.vwConvertMobile(332)};
=======
  width: 284px;
  height: 614px;
  box-shadow: 0px 32px 48px rgba(0, 0, 0, 0.08);

  ${media.TABLET} {
    width: 284px;
    height: 614px;
  }

  ${media.MOBILE} {
    width: 154px;
    height: 332px;
>>>>>>> 051efa242682533097dc78e7dc5e26389096789e
  }
`;
