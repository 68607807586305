import React, { FC, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {
  animations,
  cssTemplate,
  media,
} from "../../../../../constants/styles";
import { useIntersecting } from "../../../../../hooks/useIntersecting";
import { throttle } from "lodash";

interface Props {
  animationHeight?: string;
  maxHeight?: string;
  picture: string;
  startScrollPoint?: number;
  text1?: React.ReactNode;
  text2?: React.ReactNode;
  text3?: React.ReactNode;
}
interface StyleProps
  extends Pick<Props, "animationHeight" | "picture" | "maxHeight"> {}
interface ContentRef {
  font1: HTMLParagraphElement | null;
  [index: string]: HTMLParagraphElement | null;
}
interface WrapperRef {
  mainWrapper: HTMLDivElement | null;
  subWrapper: HTMLDivElement | null;
}
const ScrollOverlayPicExtention: FC<Props> = (props) => {
  const { text1, text2, text3 } = props;
  const contentRef = useRef<ContentRef>({
    font1: null,
    font2: null,
    font3: null,
  });
  const wrapperRef = useRef<WrapperRef>({
    mainWrapper: null,
    subWrapper: null,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const picRef = useRef<HTMLImageElement>(null);

  const scrollHandleEvent = () => {
    if (
      containerRef.current &&
      picRef.current &&
      wrapperRef.current.mainWrapper &&
      wrapperRef.current.subWrapper &&
      contentRef.current.font1 &&
      contentRef.current.font2 &&
      contentRef.current.font3
    ) {
      const rect = containerRef.current?.getBoundingClientRect();
      const scrollY = -rect.top;
      const sectionHeight =
        (containerRef.current.scrollHeight - window.innerHeight) / 5;
      const ratio0 = scrollY / sectionHeight;
      const ratio1 = scrollY / sectionHeight;
      const ratio2 = (scrollY - sectionHeight * 2) / sectionHeight;
      const ratio3 = (scrollY - sectionHeight * 3) / sectionHeight;
      const ratio5 =
        (scrollY - sectionHeight * 1 - sectionHeight / 2) / sectionHeight;
      wrapperRef.current.mainWrapper.style.overflow = "hidden";
      if (rect.top <= 0) {
        if (rect.height + rect.top - window.innerHeight > 0) {
          //스크롤 이벤트 시작
          wrapperRef.current.mainWrapper.style.position = "fixed";
          wrapperRef.current.mainWrapper.style.top = "0";
          wrapperRef.current.mainWrapper.style.bottom = "unset";
          wrapperRef.current.mainWrapper.style.backgroundSize = "cover";
          wrapperRef.current.subWrapper.style.position = `fixed`;
          wrapperRef.current.mainWrapper.style.opacity = `1`;
          wrapperRef.current.subWrapper.style.top = `0`;
          wrapperRef.current.subWrapper.style.bottom = `unset`;
          picRef.current.style.translate = "none";
          picRef.current.style.scale = "none";
          picRef.current.style.rotate = "none";
          contentRef.current.font1.style.translate = `none`;
          contentRef.current.font1.style.scale = `none`;
          contentRef.current.font1.style.rotate = `none`;
          const pictureScaleRatio = ratio1;
          const pictureOpacityRatio = 1 - ratio5;
          const font1OpacityAndTransFormRatio = 1 - ratio0;
          const font2OpacityAndTransFormRatio = 1 - ratio2;
          const font3OpacityAndTransFormRatio = 1 - ratio3;

          if (font1OpacityAndTransFormRatio > 0.8) {
            contentRef.current.font1.style.position = "fixed";
            contentRef.current.font1.style.opacity = `1`;
          } else if (font1OpacityAndTransFormRatio < 0) {
            contentRef.current.font1.style.opacity = `0`;
          } else {
            contentRef.current.font1.style.opacity = `${
              1 - (0.8 - font1OpacityAndTransFormRatio)
            }`;
          }

          if (pictureScaleRatio < 0) {
            picRef.current.style.transform = `translate3d(0px, 0px, 0px) scale(${1},${1})`;
          } else if (pictureScaleRatio > 1) {
            picRef.current.style.transform = `translate3d(0px, 0px, 0px) scale(${1.5},${1.5})`;
          } else {
            picRef.current.style.transform = `translate3d(0px, 0px, 0px) scale(${
              1 + pictureScaleRatio * 0.5
            },${1 + pictureScaleRatio * 0.5})`;
          }
          if (pictureOpacityRatio > 1) {
            picRef.current.style.opacity = `1`;
          } else if (pictureOpacityRatio < 0) {
            picRef.current.style.opacity = `0`;
          } else {
            picRef.current.style.opacity = `${pictureOpacityRatio}`;
          }

          if (font2OpacityAndTransFormRatio > 1) {
            contentRef.current.font2.style.opacity = `${0}`;
            contentRef.current.font2.style.transform = `matrix(1,0,0,1,0,${40})`;
          } else if (font2OpacityAndTransFormRatio < 0) {
            contentRef.current.font2.style.opacity = `1`;
            contentRef.current.font2.style.transform = `matrix(1,0,0,1,0,${0})`;
          } else {
            contentRef.current.font2.style.opacity = `${
              1 - font2OpacityAndTransFormRatio
            }`;
            contentRef.current.font2.style.transform = `matrix(1,0,0,1,0,${
              font2OpacityAndTransFormRatio * 40
            })`;
          }
          if (font3OpacityAndTransFormRatio > 1) {
            contentRef.current.font3.style.opacity = `${0}`;
            contentRef.current.font3.style.transform = `matrix(1,0,0,1,0,${40})`;
          } else if (font3OpacityAndTransFormRatio < 0) {
            contentRef.current.font3.style.opacity = `1`;
            contentRef.current.font3.style.transform = `matrix(1,0,0,1,0,${0})`;
          } else {
            contentRef.current.font3.style.opacity = `${
              1 - font3OpacityAndTransFormRatio
            }`;
            contentRef.current.font3.style.transform = `matrix(1,0,0,1,0,${
              font3OpacityAndTransFormRatio * 40
            })`;
          }
        } else {
          //스크롤 이벤트 종료
          wrapperRef.current.mainWrapper.style.position = `absolute`;
          wrapperRef.current.mainWrapper.style.top = `unset`;
          wrapperRef.current.mainWrapper.style.opacity = `0`;
          wrapperRef.current.mainWrapper.style.bottom = `0`;
          wrapperRef.current.subWrapper.style.position = `absolute`;
          wrapperRef.current.subWrapper.style.top = `unset`;
          wrapperRef.current.subWrapper.style.bottom = `0`;
          wrapperRef.current.subWrapper.style.opacity = `1`;

          contentRef.current.font2.style.opacity = `1`;
          contentRef.current.font3.style.opacity = `1`;
          contentRef.current.font1.style.opacity = `0`;
          contentRef.current.font1.style.transform = ``;
        }
      }
    }
  };

  const { ref, isIntersecting } = useIntersecting();
  const [interacted, setInteracted] = useState<boolean>(false);
  useEffect(() => {
    if (isIntersecting) setInteracted(true);
  }, [isIntersecting]);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandleEvent);
    return () => window.removeEventListener("scroll", scrollHandleEvent);
  }, []);

  return (
    <Container ref={containerRef} {...props}>
      <ScrollWrapper {...props}>
        <MainTextWrapper
          ref={(ref) => (wrapperRef.current.mainWrapper = ref)}
          {...props}
        >
          <ImgWrapper ref={picRef} {...props}></ImgWrapper>
          <MobileFlexWrapper {...props}>
            <div
              className="main-text"
              ref={(ref) => (contentRef.current.font1 = ref)}
            >
              <MobileFlexFade ref={ref} theme={{ isIntersecting: interacted }}>
                {text1}
              </MobileFlexFade>
            </div>
          </MobileFlexWrapper>
        </MainTextWrapper>
        <SubTextWrapper
          ref={(ref) => (wrapperRef.current.subWrapper = ref)}
          {...props}
        >
          <div ref={(ref) => (contentRef.current.font2 = ref)}>{text2}</div>
          <div ref={(ref) => (contentRef.current.font3 = ref)}>{text3}</div>
        </SubTextWrapper>
      </ScrollWrapper>
    </Container>
  );
};
export default ScrollOverlayPicExtention;
const Container = styled.div<StyleProps>`
  width: 100%;
  pointer-events: none;
  position: relative;
  overflow: hidden;
  background: #111;
`;
const ScrollWrapper = styled.div<StyleProps>`
  min-height: ${({ animationHeight }) => animationHeight};
`;
const MobileFlexWrapper = styled.div<StyleProps>`
  top: 0;
  left: 0;
  position: absolute;

  min-height: ${({ maxHeight }) => maxHeight};
  height: ${({ maxHeight }) => maxHeight};
  max-height: ${({ maxHeight }) => maxHeight};
  width: 100vw;
  display: flex;
  z-index: 29;
  justify-content: center;
  align-items: center;
  & > div.main-text {
    width: 100vw;

    position: absolute;
  }
`;

const MobileFlexFade = styled.div`
  ${(props) => cssTemplate.fadeInOut(props)}
`;
const ImgWrapper = styled.div<StyleProps>`
  background: url(${({ picture }) => picture});
  animation: ${animations.fadeIn} ease-in 0.5s;
  transition: 0.1s;

  max-width: 100vw;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 29;
  position: absolute;

  left: 0;
  height: 100vh;
  min-height: ${({ maxHeight }) => maxHeight};
  max-height: ${({ maxHeight }) => maxHeight};

  width: 100vw;
  display: flex;
  z-index: 29;
  justify-content: center;
  align-items: center;

  ${media.TABLET} {
    background-size: cover;
  }
  ${media.MOBILE} {
    background-size: cover;
  }
`;
const MainTextWrapper = styled.div<StyleProps>`
  min-width: 100vw;
  position: relative;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${({ maxHeight }) => maxHeight};
  height: ${({ maxHeight }) => maxHeight};
  div.main-text {
    left: 0px;
    position: absolute;
    z-index: 30;
    width: 100%;
    margin: 0px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 150%;
    /* or 90px */

    text-align: center;
    color: rgb(255, 255, 255);
    text-align: center;
    > span {
      color: #b3ff01;
    }
  }
  ${media.TABLET} {
    div.main-text {
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 150%;
      /* or 90px */

      text-align: relative;
      color: #fff;
      & > span {
        color: #b3ff01;
      }
    }
  }
  ${media.MOBILE} {
    div.main-text {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
    }
  }
`;
const SubTextWrapper = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 60px;
  min-width: 100vw;
  min-width: 100vw;
  z-index: 27;

  min-height: ${({ maxHeight }) => maxHeight};
  height: ${({ maxHeight }) => maxHeight};
  & > div {
    opacity: 0;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    /* or 138% */

    text-align: center;
    color: #ffffff;
  }
  ${media.TABLET} {
    gap: 40px;
    & > div {
      font-size: 26px;
      line-height: 38px;
    }
  }
  ${media.MOBILE} {
    gap: 40px;
    & > div {
      font-size: 20px;
      line-height: 30px;
    }
  }
`;
