import React, { useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { color, cssTemplate, grid, media } from "../../../../constants/styles";
import BaseSection from "../../BaseSection";
import { ReactComponent as LogoBar } from "../../../../svgs/logo-bar.svg";
import Responsable from "../../../Responsable";
import { throttle } from "lodash";
import { ArrowIcon, maxWidth, MovingArrow } from "../../shared/styled";
import ScrollOverlayPictureAppear from "../scrollOverays/ScrollOverlayPictureAppear";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import Section1Content from "./Content/SectionContent";

interface HomeSection01Props {}
interface FontRef {
  font1: HTMLSpanElement | null;
  font2: HTMLSpanElement | null;
  font3: HTMLSpanElement | null;
  font4: HTMLSpanElement | null;
  font5: HTMLSpanElement | null;
}
const HomeSection01: React.FC<HomeSection01Props> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const windowHeight = useRef<number>(window.innerHeight);
  const animationHeight = windowHeight.current * 3;

  return (
    <Base ref={containerRef} fullWidth fullHeight>
      <Responsable
        backgroundColor="black"
        pc={
          <ScrollOverlayPictureAppear
            minHeight={`${animationHeight}px`}
            maxHeight={`${windowHeight}px`}
            screenType="PC"
          />
        }
        tablet={
          <ScrollOverlayPictureAppear
            minHeight={`${animationHeight}px`}
            maxHeight={`${windowHeight}px`}
            screenType="TABLET"
          />
        }
        mobile={
          <ScrollOverlayPictureAppear
            minHeight={`${animationHeight}px`}
            maxHeight={`${windowHeight}px`}
            screenType="MOBILE"
          />
        }
      />
    </Base>
  );
};

export default HomeSection01;
const Base = styled(BaseSection)`
  position: relative;
  min-height: 600px;
`;
const SectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 400vh;
  position: relative;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  ${media.TABLET} {
    display: flex;
    flex-direction: column;
    padding: 0px 48px;
    isolation: isolate;
  }
  ${media.MOBILE} {
    font-size: 70px;
    margin-top: 20px;
    margin-bottom: 32px;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-size: cover;
  opacity: 1;
  min-height: 600px;
  max-width: 100vw;
  font-family: "Fivo Sans Modern";
  overflow: hidden;
  ${media.TABLET} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    opacity: 1;
    ${maxWidth};
    min-height: 600px;
    justify-content: flex-start;
    background-size: cover;
    margin-top: calc((100% - 552px) / 2);
  }
`;
const HiddenSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  background-size: cover;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  min-height: 300px;
  font-family: "Fivo Sans Modern";
  background-image: url("/image/webp/home/Lobby.webp");
  /* position: absolute; */
  overflow: hidden;
`;
const Dim = styled.div`
  width: 100%;
  height: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;
const Text2 = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  ${media.TABLET} {
    font-family: "Fivo Sans Modern";
    font-style: normal;
    font-weight: 900;
    font-size: 120px;
    line-height: 144px;
    /* identical to box height, or 120% */
    display: flex;
    align-items: center;
    /* Gray/Gray-08 (White) */
    color: #ffffff;
  }
`;
const Title = styled.h2`
  color: #ffffff;
  font-size: 150px;
  line-height: 192px;
  font-weight: 900;
  letter-spacing: 0.9;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  ${media.TABLET} {
    font-family: "Fivo Sans Modern";
    font-style: normal;
    font-weight: 900;
    font-size: 120px;
    line-height: 144px;
    /* identical to box height, or 120% */
    display: flex;
    flex-wrap: wrap;
    /* Gray/Gray-08 (White) */
    color: #ffffff;
  }
  ${media.MOBILE} {
    font-size: 70px;
    margin-top: 20px;
    margin-bottom: 32px;
  }
`;

const Logo = styled.div`
  display: inline-block;
  position: relative;
  min-width: 90.51px;
  height: 108px;
  z-index: 200;
  margin-top: 32px;
  margin-left: 32px;
  > svg {
    position: absolute;
    width: 81.27px;
    height: 65.01px;
    &:nth-child(1) {
      top: 0;
      left: 0;
    }
    &:nth-child(2) {
      bottom: 0;
      right: 0;
    }
  }
`;

const Paragragh = styled.p`
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: ${color.GREEN};
  text-align: left;
  max-width: 1200px;
  width: 100%;
  ${media.TABLET} {
    font-family: "Fivo Sans Modern";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  ${media.MOBILE} {
    font-size: ${cssTemplate.vwConvertMobile(20)};
    margin-bottom: 28px;
  }
`;

const PictureParagraph = styled.div`
  font-family: Pretendard;
  font-size: 60px;
  font-weight: 700;
  line-height: 90px;
  letter-spacing: 0em;
  text-align: center;
  vertical-align: middle;
  color: white;
  & > span {
    color: #b3ff01;
  }
`;

// const HomeSection01: React.FC<HomeSection01Props> = (props) => {
//   const containerRef = useRef<HTMLDivElement>(null);
//   const windowHeight = useRef<number>(window.innerHeight);
//   const animationHeight = windowHeight.current * 3;

//   return (
//     <Base ref={containerRef} fullWidth fullHeight>
//       <Responsable
//         pc={<Section1Content screenType="pc" />}
//         tablet={<Section1Content screenType="tablet" />}
//         mobile={<Section1Content screenType="tablet" />}
//       />
//     </Base>
//   );
// };

// export default HomeSection01;

// const Base = styled(BaseSection)`
//   position: relative;
//   min-height: 600px;
// `;
