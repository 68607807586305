import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import routes from "./constants/routes";
import { GlobalStyle } from "./constants/styles";

import { RecoilRoot } from "recoil";
import DetailModal from "./components/Modal/DetailModal";

import Home from "./pages/Home";
import Recruit from "./pages/Recruit";
import NotFound from "./pages/NotFound";
import Team from "./pages/Team";
import Business from "./pages/Business";

interface AppProps {}
// console.log(ddd.SGSG);

// console.log(
//   Object.keys(ddd).filter((list) => {
//     console.log("ad >>>>>", list);
//   })
// );

const App: React.FC<AppProps> = () => {
  return (
    <RecoilRoot>
      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route path={routes.HOME} element={<Home />} />
          <Route path={routes.TEAM} element={<Team />} />
          <Route path={routes.RECRUIT} element={<Recruit />} />
          <Route path={routes.BUSINESS} element={<Business />} />

          <Route path="*" element={<NotFound />} />
        </Routes>

        <DetailModal />
      </BrowserRouter>
    </RecoilRoot>
  );
};

export default App;
