import React from "react";
import styled from "styled-components";
import {
  media,
  mobilePaddingTopBottom,
  pcPaddingTopBottom,
  tabletPaddingTopBottom,
} from "../../../../constants/styles";
import Board from "../../../Board";
import BaseSection from "../../BaseSection";
import { StyledTitle, TitleWrapper } from "../../shared/styled";

const RecruitSection05 = () => {
  return (
    <Base>
      <LayoutSection>
        <TitleWrapper>
          <StyledTitle>
            <span>FAQ</span>
            <h1>자주묻는 질문.</h1>
          </StyledTitle>
        </TitleWrapper>
        <Board />
      </LayoutSection>
    </Base>
  );
};
export default RecruitSection05;
const Base = styled.div`
  display: block;
  width: 100%;
`;

const LayoutSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${pcPaddingTopBottom} 48px;
  gap: 80px;
  ${media.TABLET} {
    gap: 60px;
    padding: ${tabletPaddingTopBottom} 48px;
    width: 100%;
    ${StyledTitle} {
      // display: flex;
      // justify-content: center;
      // align-items: center;
    }
  }
  ${media.MOBILE} {
    gap: 40px;
    padding: ${mobilePaddingTopBottom} 24px;
  }
`;
