import React from "react";
import styled from "styled-components";
import {
  cssTemplate,
  media,
  mobileContainerGap,
  mobileContainerSmallGap,
  pcPaddingRightLeft,
  pcPaddingTopBottom,
  tabletContainerGap,
} from "../../../../../constants/styles";
import { valueUpServices } from "../../../../../constants/valueUpServices";
import { useIntersecting } from "../../../../../hooks/useIntersecting";
import { Wrapper } from "../../../shared/styled";
import { SectionProps } from "../../../shared/types";
import ValueUpCard from "./VaueUpCard";

const ValueUpCardList: React.FC<SectionProps> = ({ screenType }) => {
  const services = valueUpServices;
  return (
    <Container>
      <CardSectionWrapper>
        {services.map((service, idx) => {
          return (
            <ValueUpCard
              idx={idx}
              screenType={screenType}
              service={service}
              key={service.title}
            />
          );
        })}
      </CardSectionWrapper>
    </Container>
  );
};
export default ValueUpCardList;

const SectionWrapper = styled(Wrapper)`
  // padding-left: calc((100vw - 1200px) / 2);
  // padding-right: calc((100vw - 1200px) / 2);
`;

const CardSectionWrapper = styled(SectionWrapper)`
  gap: 200px;
  margin: 0 auto;
  ${media.TABLET} {
    gap: 80px;
    align-items: center;
  }

  ${media.MOBILE} {
    gap: 60px;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${pcPaddingTopBottom} 0px 0px 0px;
  gap: 200px;
  isolation: isolate;

  overflow: hidden;
  ${media.TABLET} {
    padding: 0;
  }
  ${media.MOBILE} {
    width: 100%;
    padding: 0;
  }
`;
