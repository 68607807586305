import styled from "styled-components";
import {
  pcPaddingTopBottom,
  pcContainerGap,
  media,
  tabletPaddingTopBottom,
  tabletContainerGap,
  mobilePaddingTopBottom,
  mobileContainerGap,
  cssTemplate,
  mobileContainerSmallGap,
} from "../../../../../constants/styles";
import { maxWidth } from "../../../shared/styled";

export const SectionLayout = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${pcPaddingTopBottom} 48px;
  gap: ${120}px;
  ${media.TABLET} {
    padding: ${tabletPaddingTopBottom} 48px;
    gap: ${tabletContainerGap};
  }
  ${media.MOBILE} {
    padding: ${mobilePaddingTopBottom} 24px;
    gap: ${({ gap }) => (gap ? gap + "px" : mobileContainerSmallGap)};
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
  gap: 40px;
  ${maxWidth};
  width: 100%;
  ${media.TABLET} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;
  }
  ${media.MOBILE} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
  }
`;
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  flex: 1;
  ${media.TABLET} {
    gap: 12px;
  }
  ${media.MOBILE} {
    gap: 4px;
  }
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 0px;
  gap: 12px;
  width: 100%;

  ${media.TABLET} {
    gap: 12px;
  }
  ${media.MOBILE} {
    gap: 8px;
  }
`;
export const TextSpan = styled.span`
  /* font-family: "Fivo Sans Modern"; */
  // font-style: italic;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height, or 142% */

  /* Primary/Neon Yellow Green 05 ⭐️ */
  width: 100%;
  color: #77d500;
  ${media.TABLET} {
    font-size: 24px;
    line-height: 28px;
  }
  ${media.MOBILE} {
    font-size: 14px;
    line-height: 16px;
  }
`;
export const TextH1 = styled.h1`
  width: 100%;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 150%;
  /* or 90px */

  /* Gray/Gray-08 (White) */

  color: #1e1e1e;
  ${media.TABLET} {
    font-size: 48px;
    line-height: 68px;
  }
  ${media.MOBILE} {
    font-size: 26px;
    line-height: 38px;
  }
`;
export const DescWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  flex: 1;
  ${media.TABLET} {
    margin-top: 0;
    gap: 20px;
  }
  ${media.MOBILE} {
    margin-top: 0;
    gap: 12px;
  }
`;
export const Desc = styled.span`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* or 150% */
  color: #646464;

  ${media.TABLET} {
    font-size: 20px;
    line-height: 30px;
    /* or 150% */

    /* Gray/Gray-05 */
  }
  ${media.MOBILE} {
    font-size: 16px;
    line-height: 24px;
    word-break: keep-all;
  }
`;
