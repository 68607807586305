import { atom } from "recoil";

export const observerState = atom<IntersectionObserver>({
  key: "observerState",
  default: undefined,
});

export const observerEntriesState = atom<Array<IntersectionObserverEntry>>({
  key: "observerEntriesState",
  default: undefined,
});

export const backgroundColorState = atom<string>({
  key: "backgroundColorState",
  default: "rgba(0,0,0,1)",
});

export const detailKeyValueState = atom<{
  isModalOpen: boolean;
  isAnimation: boolean;
  serviceDetailKey: string;
}>({
  key: "detailKeyValue",
  default: {
    isModalOpen: false,
    isAnimation: false,
    serviceDetailKey: "SGSG",
  },
});
