import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Faq } from "../../constants/faqs";
import { cssTemplate, media } from "../../constants/styles";
import { useIntersecting } from "../../hooks/useIntersecting";
import { maxWidth } from "../sections/shared/styled";
interface BoardBodyProps {
  menuList: Faq[];
  nowIndex: number;
  handleClickBoardRow: (index: number) => void;
}
interface StyledProps {
  isNowIndex?: boolean;
}
const BoardBody: React.FC<BoardBodyProps> = ({
  menuList,
  nowIndex,
  handleClickBoardRow,
}) => {
  const { ref, isIntersecting } = useIntersecting();
  const [isInteracted, setIsInteracted] = useState(false);
  useEffect(() => {
    if (isIntersecting) setIsInteracted(true);
  }, [isIntersecting]);

  // FIXME CELL 안붙이면, 없어도됨.
  const categoryCustom = (category: string) => {
    return category.replace(/ CELL/, "");
  };

  return (
    <BoardBodyWrapper ref={ref} theme={{ isIntersecting: isInteracted }}>
      {menuList.map((menu, index) => (
        <BoardBodyRow
          key={menu.title + index}
          onClick={() => {
            handleClickBoardRow(index);
          }}
        >
          <BoardBodyTitleRow isNowIndex={nowIndex === index}>
            {/* <div className="category-name">{categoryCustom(menu.category)}</div> */}
            <div className="category-name">Q</div>
            <div className="title">{menu.title}</div>
            <img
              src={
                nowIndex === index
                  ? "/image/webp/icons/ArrowUp-black.webp"
                  : "/image/webp/icons/ArrowDown-black.webp"
              }
            />
          </BoardBodyTitleRow>
          <BoardContent menu={menu} isNowIndex={nowIndex === index} />
        </BoardBodyRow>
      ))}
    </BoardBodyWrapper>
  );
};
interface ContentProps extends StyledProps {
  menu: Faq;
}
const BoardContent: React.FC<ContentProps> = ({ menu, isNowIndex }) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (bodyRef.current && contentRef.current)
      if (isNowIndex) {
        bodyRef.current.style.height = contentRef.current.scrollHeight + "px";
      } else {
        bodyRef.current.style.height = "0";
      }
  }, [isNowIndex]);

  return (
    <BoardDescWrapper ref={bodyRef}>
      <BoardDesc ref={contentRef} isNowIndex={isNowIndex}>
        <span> {menu.desc}</span>
      </BoardDesc>
    </BoardDescWrapper>
  );
};
export default BoardBody;
const BoardBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  ${maxWidth} /* ${(props) => cssTemplate.fadeInOut(props)}; */

  ${media.MOBILE} {
    max-width: 100%;
  }
`;
const BoardBodyRow = styled.div`
  // border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  cursor: pointer;
  position: relative;
  overflow-y: hidden;
`;

const BoardBodyTitleRow = styled.div<StyledProps>`
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 32px 24px 10px;
  gap: 16px;
  color: #dcdcdc;
  width: 100%;
  z-index: 15;
  position: relative;
  &:hover {
  }
  & > div.category-name {
    margin-right: 16px;
    /* flex: 0 0 100px; */
    height: 24px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #1e1e1e;
  }
  & > div.title {
    flex: 1;
    min-height: 24px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #1e1e1e;
    word-break: keep-all;
  }
  // background-color: ${({ isNowIndex }) =>
    isNowIndex ? "#f8f8f8" : "unset"};
  transition: all 0.3s;
  ${media.TABLET} {
    & > div.category-name {
      font-size: 17px;
      line-height: 24px;
    }
    & > div.title {
      font-size: 17px;
      line-height: 24px;
    }
  }
  ${media.MOBILE} {
    align-items: flex-start;
    padding: 20px 0;
    // padding: 16px 20px;
    gap: 10px;
    & > div.category-name {
      font-size: 17px;
      line-height: 24px;
    }
    & > div.title {
      font-size: 17px;
      line-height: 24px;
      // color: #ffffff;
      flex: 1;
    }
  }
`;
const BoardDescWrapper = styled.div`
  height: 0;
  width: 100%;
  transition: height 0.5s;
  overflow: hidden;
`;
const BoardDesc = styled.div<StyledProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px 66px;
  /* padding: 20px 32px 32px 156px; */
  gap: 24px;
  word-break: keep-all;
  width: 100%;
  /* background-color: #1a1a1a; */
  background-color: #f8f8f8;
  overflow-y: hidden;
  color: #1;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  color: #505050;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  z-index: 14;
  position: relative;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* 인터넷익스플로러 */
  user-select: none;

  & > span {
  }

  ${media.MOBILE} {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 32px;
    gap: 24px;
  }
`;
