const routes = {
  HOME: "/",
  ABOUT: "/about",
  TEAM: "/team",
  BUSINESS: "/business",
  RECRUIT: "/recruit",

  // FIXME modal로 만들어야 되니 url 삭제
  services: {
    SGSG: "/sgsg",
    MINTIT: "/mintit",
    CARPET: "/carpet",
    // EVERON: "/everon",
    PLUSPOT: "/pluspot",
    TIREPICK: "/tirepick",
    OPENMILE: "/open-mile",
    HIGHEND: "/high-end",
    SKRANTERCAR: "/sk-ranter-car",
  },
};

export default routes;
