import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { ReactComponent as LogoBar } from "../../../../../svgs/logo-bar.svg";
import { throttle } from "lodash";
import BaseSection from "../../../BaseSection";
import {
  animations,
  color,
  cssTemplate,
  media,
} from "../../../../../constants/styles";
import { maxWidth } from "../../../shared/styled";
import { ScreenType, SectionProps } from "../../../shared/types";
import { useIntersecting } from "../../../../../hooks/useIntersecting";

interface ScrollOverlayPictureAppearProps extends SectionProps {
  maxHeight: string;
  minHeight: string;
}
interface FontRef {
  font1: HTMLSpanElement | null;
  font2: HTMLSpanElement | null;
  font3: HTMLSpanElement | null;
  font4: HTMLSpanElement | null;
  font5: HTMLSpanElement | null;
}
const ScrollOverlayPictureAppear: React.FC<ScrollOverlayPictureAppearProps> = ({
  maxHeight,
  minHeight,
  screenType,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const fontRef = useRef<FontRef>({
    font1: null,
    font2: null,
    font3: null,
    font4: null,
    font5: null,
  });
  const contentRef = useRef<HTMLDivElement>(null);
  const gradiationRef = useRef<HTMLDivElement>(null);
  const logoRef = useRef<HTMLDivElement>(null);
  const [translatePoint, setTranslatePoint] = useState<number>(100);

  const opacityRange = [0, 1];
  const scrollEventHandler = () => {
    if (
      containerRef.current &&
      logoRef.current &&
      gradiationRef.current &&
      fontRef.current.font1 &&
      fontRef.current.font2 &&
      fontRef.current.font3 &&
      fontRef.current.font4 &&
      fontRef.current.font5 &&
      contentRef.current
    ) {
      const rect = containerRef.current.getBoundingClientRect();
      // 뷰포트 안에 들어왔을때
      if (rect.top <= 0) {
        // 스크롤 인터렉션 시작
        if (rect.height + rect.top - window.innerHeight > 0) {
          gradiationRef.current.style.position = "fixed";
          gradiationRef.current.style.top = "0";
          gradiationRef.current.style.bottom = "unset";
          const fullSectionHeight =
            (containerRef.current.scrollHeight - window.innerHeight) / 6;
          const textDisappearEventRatio = -rect.top / fullSectionHeight;
          const pictureAppearEventRatio =
            (-rect.top - fullSectionHeight * 1) / fullSectionHeight / 2;
          const textTransformEventRatio =
            (-rect.top - fullSectionHeight * 3) / fullSectionHeight;
          logoRef.current.style.opacity = `${1 - textDisappearEventRatio}`;
          fontRef.current.font1.style.opacity = `${
            1 - textDisappearEventRatio
          }`;
          fontRef.current.font2.style.opacity = `${
            1 - textDisappearEventRatio
          }`;
          fontRef.current.font3.style.opacity = `${
            1 - textDisappearEventRatio
          }`;
          fontRef.current.font4.style.opacity = `${
            1 - textDisappearEventRatio
          }`;
          gradiationRef.current.style.opacity = `${pictureAppearEventRatio}`;
          const translatePoint = (1 - textTransformEventRatio) * 100;
          setTranslatePoint(
            translatePoint <= 0 && window.innerHeight > 270 ? 0 : translatePoint
          );

          fontRef.current.font5.style.opacity = `${
            textTransformEventRatio + 0.5
          }`;
        } else {
          gradiationRef.current.style.position = "absolute";
          gradiationRef.current.style.top = "unset";
          gradiationRef.current.style.bottom = "0";
          gradiationRef.current.style.opacity = "1";
          setTranslatePoint(0);

          // 스크롤 인터렉션 종료
        }
        // 뷰포트 들어오기 전
      } else {
        gradiationRef.current.style.position = "relative";
        gradiationRef.current.style.top = "unset";
        gradiationRef.current.style.bottom = "unset";
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollEventHandler);
    return () => window.removeEventListener("scroll", scrollEventHandler);
  }, []);

  const { ref, isIntersecting } = useIntersecting();
  const [interacted, setInteracted] = useState<boolean>(false);
  useEffect(() => {
    if (isIntersecting) {
      setInteracted(true);
    }
  }, [isIntersecting]);

  return (
    <Base ref={containerRef}>
      <SectionLayout
        className="homeSection1Container"
        minHeight={minHeight}
        maxHeight={maxHeight}
      >
        {screenType === "PC" && (
          <TitleWrapper
            maxHeight={maxHeight}
            minHeight={minHeight}
            ref={contentRef}
          >
            <TitleFade ref={ref} theme={{ isIntersecting: interacted }}>
              <Title>
                <span ref={(ref) => (fontRef.current.font1 = ref)}>
                  WHAT’S UP?
                </span>
                <Text2>
                  <span ref={(ref) => (fontRef.current.font2 = ref)}>
                    WE’RE
                  </span>
                  {/* <EmptyLogo ref={logoWrapRef} /> */}
                  <Logo
                    style={{
                      position: "relative",
                    }}
                    ref={logoRef}
                  >
                    <LogoBar />
                    <LogoBar />
                  </Logo>
                  <span ref={(ref) => (fontRef.current.font3 = ref)}>
                    LOGUP!
                  </span>
                </Text2>
              </Title>
              <Paragragh ref={(ref) => (fontRef.current.font4 = ref)}>
                We create digital solutions that thunder-surprise the world
              </Paragragh>
            </TitleFade>
          </TitleWrapper>
        )}

        {screenType === "TABLET" && (
          <TitleWrapper
            maxHeight={maxHeight}
            minHeight={minHeight}
            ref={contentRef}
          >
            <TitleFade ref={ref} theme={{ isIntersecting: interacted }}>
              <Title>
                <span ref={(ref) => (fontRef.current.font1 = ref)}>
                  WHAT’S <br />
                  UP?
                </span>
                <Text2>
                  <span ref={(ref) => (fontRef.current.font2 = ref)}>
                    WE’RE
                  </span>
                  {/* <EmptyLogo ref={logoWrapRef} /> */}
                  <div>
                    <Logo
                      style={{
                        position: "relative",
                      }}
                      ref={logoRef}
                    >
                      <LogoBar />
                      <LogoBar />
                    </Logo>
                    <span ref={(ref) => (fontRef.current.font3 = ref)}>
                      LOGUP!
                    </span>
                  </div>
                </Text2>
              </Title>
              <Paragragh ref={(ref) => (fontRef.current.font4 = ref)}>
                We create digital solutions that thunder-surprise the world
              </Paragragh>
            </TitleFade>
          </TitleWrapper>
        )}

        {screenType === "MOBILE" && (
          <TitleWrapper
            maxHeight={maxHeight}
            minHeight={minHeight}
            ref={contentRef}
          >
            <TitleFade ref={ref} theme={{ isIntersecting: interacted }}>
              <Title>
                <span ref={(ref) => (fontRef.current.font1 = ref)}>
                  WHAT’S
                  <br />
                  UP?
                </span>
                <Text2>
                  <span ref={(ref) => (fontRef.current.font2 = ref)}>
                    WE’RE
                  </span>

                  {/* <EmptyLogo ref={logoWrapRef} /> */}
                  <div>
                    <Logo
                      style={{
                        position: "relative",
                      }}
                      ref={logoRef}
                    >
                      <LogoBar />
                      <LogoBar />
                    </Logo>
                    <span ref={(ref) => (fontRef.current.font3 = ref)}>
                      LOGUP!
                    </span>
                  </div>
                </Text2>
              </Title>
              <Paragragh ref={(ref) => (fontRef.current.font4 = ref)}>
                We create digital solutions that thunder-surprise the world
              </Paragragh>
            </TitleFade>
          </TitleWrapper>
        )}
        <HiddenSection
          ref={gradiationRef}
          minHeight={minHeight}
          maxHeight={maxHeight}
        >
          <Dim>
            <div ref={(ref) => (fontRef.current.font5 = ref)}>
              <PictureParagraph
                style={{ transform: `translateY(${translatePoint}px)` }}
              >
                IT가 필요한 곳에 IT가 있게 하는
                <br />
                테크 프로바이더
              </PictureParagraph>
            </div>
          </Dim>
        </HiddenSection>
      </SectionLayout>
    </Base>
  );
};

export default ScrollOverlayPictureAppear;
const Base = styled(BaseSection)`
  position: relative;
  min-height: 600px;
  background: #111;
`;
const SectionLayout = styled.div<
  Pick<ScrollOverlayPictureAppearProps, "maxHeight" | "minHeight">
>`
  background: #111;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: ${({ minHeight }) => minHeight};
  position: relative;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  ${media.TABLET} {
    padding: 0px 48px;
  }

  ${media.MOBILE} {
    padding: 0px 24px;
  }
  isolation: isolate;
`;
const TitleWrapper = styled.div<
  Pick<ScrollOverlayPictureAppearProps, "maxHeight" | "minHeight">
>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  opacity: 1;
  min-height: 600px;
  max-width: 100vw;
  max-height: 100vh;
  font-family: "Fivo Sans Modern";
  ${maxWidth};
  overflow: hidden;
  ${media.TABLET} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    opacity: 1;
    min-height: 600px;
    justify-content: center;
    background-size: cover;
  }
  ${media.MOBILE} {
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    opacity: 1;
    min-height: 600px;
    justify-content: center;
    background-size: cover;
  }
`;

const TitleFade = styled.div`
  ${(props) => cssTemplate.fadeInOut(props)}
`;
const HiddenSection = styled.div<
  Pick<ScrollOverlayPictureAppearProps, "maxHeight" | "minHeight">
>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-size: cover;
  opacity: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: 100vh;
  font-family: "Fivo Sans Modern";
  background-image: url("/image/webp/home/Section01-Bg-Pc.webp");
  overflow: hidden;
  position: absolute;
  bottom: 0;
  top: unset;
  ${media.TABLET} {
    background-image: url("/image/webp/home/Section01-Bg-Tablet.webp");
  }
  ${media.MOBILE} {
    background-image: url("/image/webp/home/Section01-Bg-Mobile.webp");
  }
`;
const Dim = styled.div`
  width: 100%;
  height: 100%;

  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;
const Text2 = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  ${media.TABLET} {
    flex-wrap: wrap;
  }
  ${media.MOBILE} {
    flex-wrap: wrap;
  }
`;
const Title = styled.h2`
  color: #ffffff;
  font-size: 150px;
  line-height: 192px;
  font-weight: 900;

  letter-spacing: 0.9;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  @media (max-width: 1289px) {
    font-size: 120px;
    line-height: 144px;
  }

  ${media.TABLET} {
    font-family: "Fivo Sans Modern";
    font-style: normal;
    font-weight: 900;
    font-size: 120px;
    line-height: 144px;
    /* identical to box height, or 120% */
    display: flex;

    flex-wrap: wrap;
    /* Gray/Gray-08 (White) */

    color: #ffffff;
  }

  ${media.MOBILE} {
    font-family: "Fivo Sans Modern";
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    line-height: 72px;
    /* identical to box height, or 120% */

    display: flex;
    align-items: flex-start;

    /* Gray/Gray-08 (White) */

    color: #ffffff;
    padding: 0 24px;
  }
`;

const Logo = styled.div`
  display: inline-block;
  position: relative;
  min-width: 90.51px;
  height: 108px;
  z-index: 200;
  margin-top: 32px;
  margin-left: 32px;

  > svg {
    position: absolute;
    width: 81.27px;
    height: 65.01px;
    &:nth-child(1) {
      top: 0;
      left: 0;
      animation: ${animations.firstArrow} 0.8s ease-in-out;
    }

    &:nth-child(2) {
      bottom: 0;
      right: 0;
      animation: ${animations.secondArrow} 0.8s ease-in-out;
    }
  }

  @media (max-width: 1289px) {
    margin-top: 20px;
    margin-left: 16px;

    & > svg {
      position: absolute;
      width: 71.27px;
      height: 55.01px;
      &:nth-child(1) {
        top: 0;
        left: 0;
        animation: ${animations.firstArrow} 0.8s ease-in-out;
      }

      &:nth-child(2) {
        bottom: 20px;
        right: 8px;
        animation: ${animations.secondArrow} 0.8s ease-in-out;
      }
    }
  }

  ${media.TABLET} {
    margin-top: 0px;
    margin-left: 0px;
    min-width: 71px;
    height: 84px;
    position: relative;
    > svg {
      position: absolute;

      width: 64px;
      height: 51px;
      &:nth-child(1) {
        top: 0;
        left: 0;
      }

      &:nth-child(2) {
        bottom: 0;
        right: 0;
      }
    }
  }
  ${media.MOBILE} {
    margin-top: 0px;
    margin-left: 0px;
    min-width: 35px;
    height: 42px;
    position: relative;
    > svg {
      position: absolute;
      width: 31.41px;
      height: 25.38px;
      &:nth-child(1) {
        top: 0;
        left: 0;
      }

      &:nth-child(2) {
        bottom: 0;
        right: 0;
      }
    }
  }
`;

const Paragragh = styled.p`
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: ${color.GREEN};
  text-align: left;
  max-width: 1200px;
  width: 100%;
  ${media.TABLET} {
    font-family: "Fivo Sans Modern";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  ${media.MOBILE} {
    font-family: "Fivo Sans Modern";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* or 122% */

    /* Primary/Neon Yellow Green 05 ⭐️ */

    color: #b3ff01;
    padding: 0 24px;
  }
`;

const PictureParagraph = styled.div`
  font-family: Pretendard;

  font-size: 60px;
  font-weight: 700;
  line-height: 90px;
  letter-spacing: 0em;
  text-align: center;
  vertical-align: middle;
  color: white;
  & > span {
    color: #b3ff01;
  }
  ${media.TABLET} {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 68px;
    /* or 142% */
    margin-top: 40px;
    text-align: center;

    /* Gray/Gray-08 (White) */

    color: #ffffff;
  }
  ${media.MOBILE} {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 38px;
    /* or 146% */
    margin-top: 60px;

    text-align: center;

    /* Gray/Gray-08 (White) */

    color: #ffffff;
  }
`;
