import React from "react";
import styled from "styled-components";
import { Section02 } from "../../../../constants/detail/types";
import { cssTemplate, media } from "../../../../constants/styles";
import BaseSection from "../../BaseSection";
import {
  ContentLayout,
  SectionLayout,
  Title,
} from "../../shared/DetailSectionStyled";

interface DetailSection02Props {
  data: Section02;
}

const DetailSection02: React.FC<DetailSection02Props> = ({
  data: { overviewDescription },
}) => {
  return (
    <BaseSection fullWidth autoHeight>
      <SectionLayout>
        <ContentLayout>
          <Title>Overview</Title>
          <span>{overviewDescription}</span>
        </ContentLayout>
      </SectionLayout>
    </BaseSection>
  );
};

export default DetailSection02;
