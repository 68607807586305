import { createGlobalStyle, css, keyframes } from "styled-components";

export const grid = {
  MAX: 1920,
  MAX_TABLET: 1000,
  MAX_MOBILE: 672,
  MIN: 375,

  MARGIN: 120,
  MARGIN_LARGE: 360,
  MARGIN_TABLET: 48,
  MARGIN_MOBILE: 24,
};

export const zIndex = {
  HEADER: 1000,
};

export const media = {
  TABLET: `@media(max-width: ${grid.MAX_TABLET - 1}px)`,
  MOBILE: `@media(max-width: ${grid.MAX_MOBILE - 1}px)`,
};

const reset = css`
  /* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const initialStyles = css`
  ${reset}

  @font-face {
    font-family: "Fivo Sans Modern";
    src: url("/font/FivoSansModern/FivoSansModern-Bold.woff2") format("woff2");
    font-display: block;
    /* font-weight: normal; */
    /* font-style: normal; */
  }

  html {
    font-family: "Pretendard", "Fivo Sans Modern";
  }

  html,
  body,
  #root {
    background: #111111;
  }
  body {
    overflow-x: hidden;
  }

  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  *::-webkit-scrollbar-thumb {
    height: 30%; /* 스크롤바의 길이 */
    background: rgba(200, 200, 200, 1); /* 스크롤바의 색상 */

    border-radius: 10px;
  }
  *::-webkit-scrollbar-track {
    background: rgba(199, 199, 200, 0.1);
  }

  a {
    display: inline-block;
  }
  span {
    white-space: pre-line;
  }
  :root {
    --vh: 100%;
  }
  height: calc(var(--vh, 1vh) * 100);
`;

export const tabletPaddingTopBottom = "160px";
export const pcPaddingTopBottom = "200px";
export const pcPaddingRightLeft = "720px";
export const mobilePaddingTopBottom = "100px";
export const tabletContainerGap = "80px";
export const pcContainerGap = "160px";
export const mobileContainerGap = "60px";
export const mobileContainerSmallGap = "60px";
export const GlobalStyle = createGlobalStyle`
  
  ${initialStyles};
`;

export const StoryBookGlobalStyle = createGlobalStyle`
  ${reset}

  html {
    font-family: "Pretendard";
  }
`;

export const typography = {
  HEADING_1_B: css`
    font-weight: 700;
    font-size: 48px;
    line-height: 57px;
  `,
  HEADING_2_B: css`
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
  `,
  HEADING_3_B: css`
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  `,

  BODY_1_M: css`
    font-weight: 500;
    font-size: 48px;
    line-height: 57px;
  `,
  BODY_2_M: css`
    font-weight: 500;
    font-size: 32px;
    line-height: 50px;
  `,
  BODY_3_M: css`
    font-weight: 500;
    font-size: 24px;
    line-height: 50px;
  `,
  BODY_4_M: css`
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  `,
  BODY_1_R: css`
    font-weight: 400;
    font-size: 48px;
    line-height: 57px;
  `,
  BODY_2_R: css`
    font-weight: 400;
    font-size: 32px;
    line-height: 50px;
  `,
  BODY_3_R: css`
    font-weight: 400;
    font-size: 24px;
    line-height: 50px;
  `,
  BODY_4_R: css`
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  `,
};

export const color = {
  PRIMARY_01: "#151515",
  PRIMARY_02: "#FFFFFF",

  SECONDARY_01: "#8FFF7C",
  SECONDARY_02: "#6FF159",

  GREEN: "#B3FF01",

  GRAY_1: "#646464",
  GRAY_2: "#949494",
  GRAY_3: "#DCDCDC",
  GRAY_4: "#EFEFEF",
};

export const cssTemplate = {
  flexCenter: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  hideOnMobile: css`
    ${media.MOBILE} {
      display: none;
    }
  `,
  showOnMobile: css`
    display: none;
    ${media.MOBILE} {
      display: flex;
    }
  `,
  ellipsis: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  hideScroll: css`
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  `,
  textDragDisable: css`
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  `,
  imageDragDisable: css`
    img {
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
    }
  `,
  fullScreen: css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  `,
  vwConvertPc: (px: number) => {
    return `${(px * 100) / grid.MAX}vw`;
  },
  vwConvertTablet: (px: number) => {
    return `${(px * 100) / grid.MAX_MOBILE}vw`;
  },
  vwConvertMobile: (px: number) => {
    return `${(px * 100) / grid.MIN}vw`;
  },
  fadeInOut: ({ theme }: any) => {
    if (theme.isIntersecting) {
      return css`
        opacity: 1;
        animation: ${animations.fadeInUp} ${theme.duration || "0.5s"} forwards;
        animation-delay: ${theme.delay || "0s"};
        ${media.MOBILE} {
          animation: from {
            transform: translateY(100%);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
          ${theme.duration || "0.5s"} forwards;
          animation-delay: ${theme.delay || "0s"};
        }
      `;
    } else {
      return css`
        opacity: 0;

        transition: 0.4s;
      `;
    }
  },
  fadeInDown: ({ theme }: any) => {
    if (theme.isIntersecting) {
      return css`
        opacity: 1;
        animation: ${animations.fadeInDown} ${theme.duration || "0.5s"} forwards;
        animation-delay: ${theme.delay || "0s"};
      `;
    } else {
      return css`
        opacity: 0;

        transition: 0.4s;
      `;
    }
  },
};

export const animations = {
  expandDown: keyframes`
    from {
      height: 0vh;
    }
    to {
      height: calc(var(--vh, 1vh) * 100);;
    }
  `,
  expandUp: keyframes`
    from {
      height: calc(var(--vh, 1vh) * 100);;
    }
    to {
      height: 0vh;
    }
  `,
  fadeInUp: keyframes`
    from {
      transform: translateY(5%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  `,
  mobileFadeInUp: keyframes`
  from {
      transform: translateY(30%);
      opacity: 1;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  `,
  fadeInDown: keyframes`
    from {
      transform: translateY(-30%);
      /* opacity: 0; */
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  `,
  fadeIn: keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `,
  fadeOut: keyframes`
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  `,
  firstArrow: keyframes`
    from {
      transform: translate(20%, -40%);
      opacity: 0;
    }
    to {
      transform: translate(0%, 0%);
      opacity: 1;
    }`,
  secondArrow: keyframes`
    from {
      transform: translate(-20%, 40%);
      opacity: 0;
    }
    to {
      transform: translate(0%, 0%);
      opacity: 1;
    }`,
};
