import React, { forwardRef } from "react";
import BaseSection from "../../BaseSection";

import Responsable from "../../../Responsable";

import ScrollOverlayValueUpService from "../scrollOverays/ScrollOverlayValueUpService";

interface HomeSection03Props {}

const HomeSection03 = forwardRef<HTMLDivElement, HomeSection03Props>(
  (props, ref) => {
    return (
      <BaseSection ref={ref} fullWidth>
        <Responsable
          pc={<ScrollOverlayValueUpService screenType="PC" />}
          tablet={<ScrollOverlayValueUpService screenType="TABLET" />}
          mobile={<ScrollOverlayValueUpService screenType="MOBILE" />}
        />
      </BaseSection>
    );
  }
);

export default HomeSection03;
