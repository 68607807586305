import React from "react";
import BaseSection from "../../BaseSection";

import Responsable from "../../../Responsable";

import ScrollOverlayOutsourcing from "../scrollOverays/ScrollOverlayOutsourcing";

interface HomeSection04Props {}

const HomeSection04: React.FC<HomeSection04Props> = (props) => {
  return (
    <BaseSection fullWidth fullHeight>
      <Responsable
        pc={<ScrollOverlayOutsourcing screenType="PC" />}
        tablet={<ScrollOverlayOutsourcing screenType="TABLET" />}
        mobile={<ScrollOverlayOutsourcing screenType="MOBILE" />}
      />
    </BaseSection>
  );
};

export default HomeSection04;
