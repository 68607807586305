import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { SlogupEnvironment } from "../../../../constants/slogupEnvironments";
import { cssTemplate, media } from "../../../../constants/styles";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import Responsable from "../../../Responsable";
import { Suspense } from "../../../Suspense";
import { maxWidth } from "../../shared/styled";

const SlogupEnvironmentCard: React.FC<SlogupEnvironment> = ({
  mainText,
  pcSubText,
  mobileSubText,
  pcImgUrl,
  tabletImgUrl,
  mobileImgUrl,
  explains1,
  explains2,
  arrayKey,
}) => {
  const { ref, isIntersecting } = useIntersecting();
  const [isInteracted, setIsInteracted] = useState(false);
  useEffect(() => {
    if (isIntersecting) setIsInteracted(true);
  }, [isIntersecting]);
  return (
    <StyledCardWrapper>
      <Suspense>
        <Responsable
          pc={<Image src={pcImgUrl} />}
          tablet={<Image src={tabletImgUrl} />}
          mobile={<Image src={mobileImgUrl} />}
        />
      </Suspense>

      <InfoWrapper theme={{ isIntersecting: isInteracted }} ref={ref}>
        <Responsable
          pc={
            <div className="info-wrapper">
              <div className="text-wrapper">
                <span className="main-text">{mainText}</span>
                <span className="sub-text">{pcSubText}</span>
              </div>
              <ul>
                {explains1.map((val, index) => (
                  <li key={index + arrayKey + "explain1"}>{val}</li>
                ))}
              </ul>
              <ul>
                {explains2.map((val, index) => (
                  <li key={index + arrayKey + "explain2"}>{val}</li>
                ))}
              </ul>
            </div>
          }
          tablet={
            <>
              <div className="text-wrapper">
                <span className="main-text">{mainText}</span>
                <span className="sub-text">{mobileSubText}</span>
              </div>
              <div className="ul-wrapper">
                <ul>
                  {explains1.map((val, index) => (
                    <li key={index + arrayKey + "explain1T"}>{val}</li>
                  ))}
                </ul>
                <ul>
                  {explains2.map((val, index) => (
                    <li key={index + arrayKey + "explain2T"}>{val}</li>
                  ))}
                </ul>
              </div>
            </>
          }
          mobile={
            <>
              <div className="text-wrapper">
                <span className="main-text">{mainText}</span>
                <span className="sub-text">{mobileSubText}</span>
              </div>
              <div className="ul-wrapper">
                <ul>
                  {explains1.map((val, index) => (
                    <li key={index + arrayKey + "explain1M"}>{val}</li>
                  ))}
                  {explains2.map((val, index) => (
                    <li key={index + arrayKey + "explain2M"}>{val}</li>
                  ))}
                </ul>
              </div>
            </>
          }
        />
      </InfoWrapper>
    </StyledCardWrapper>
  );
};
interface ImgProps {
  src: string;
}
const Image: React.FC<ImgProps> = React.memo(({ src }) => {
  const { ref, isIntersecting } = useIntersecting();
  const [isInteracted, setIsInteracted] = useState(false);
  useEffect(() => {
    if (isIntersecting) setIsInteracted(true);
  }, [isIntersecting]);
  const imgSrc = useMemo(() => {
    return src;
  }, [src]);
  return (
    <StyledImage
      src={imgSrc}
      theme={{ isIntersecting: isInteracted }}
      ref={ref}
    />
  );
});
export default SlogupEnvironmentCard;
const StyledImage = styled.div<{ src: string }>`
  border-radius: 24px;
  width: 100%;
  height: 494px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  ${(props) => cssTemplate.fadeInOut(props)};
  background-repeat: no-repeat;
  overflow: hidden;
  ${media.TABLET} {
    height: 400px;

    background-position: center;
  }
  ${media.MOBILE} {
    height: 200px;
    border-radius: 12px;
    background-position: center;
  }
`;

const InfoWrapper = styled.div`
  ${(props) => cssTemplate.fadeInOut(props)};
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
  padding: 0px;
  gap: 40px;

  width: 100%;
`;
const tabletCardWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  ${maxWidth}
  width: 100%;

  & > div > div {
    width: 100%;
    ${media.TABLET} {
      flex-wrap: wrap;
    }

    & > div.text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 4px;

      & > span.main-text {
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        /* identical to box height, or 138% */

        /* Gray/Gray-08 (White) */

        color: #1e1e1e;
      }
      & > span.sub-text {
        /* Paragraph/Paragraph 1-20R */

        font-family: "Pretendard";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        /* or 170% */

        /* Gray/Gray-04 */

        color: #505050;
      }
    }
    & > div.ul-wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 40px;
      width: 100%;
      margin-top: 20px;
      flex-wrap: nowrap;
    }
    & > div > ul {
      list-style: disc;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      flex: 0 0 50%;

      & > li {
        margin-left: 16px;
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 170% */

        /* Gray/Gray-08 (White) */

        color: #1e1e1e;
      }
    }
  }
`;
const mobileCardWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  ${maxWidth}
  width: 100%;

  & > div > div {
    width: 100%;
    & > div.text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 4px;

      & > span.main-text {
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
        /* identical to box height, or 138% */

        /* Gray/Gray-08 (White) */

        color: #1e1e1e;
      }
      & > span.sub-text {
        /* Paragraph/Paragraph 1-20R */

        font-family: "Pretendard";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* or 170% */

        /* Gray/Gray-04 */

        color: #505050;

        word-break: keep-all;
      }
    }
    & > div.ul-wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 40px;
      width: 100%;
      margin-top: 32px;
      flex-wrap: nowrap;
    }
    & > div > ul {
      list-style: disc;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      flex: 0 0 100%;

      & > li {
        margin-left: 16px;
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 170% */

        /* Gray/Gray-08 (White) */

        color: #1e1e1e;
      }
    }
  }
`;
const StyledCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 48px;
  ${maxWidth}
  width: 100%;
  div.info-wrapper {
    display: flex;
    flex-direction: row;
    align-items: space-between;
    justify-content: space-between;
    padding: 0px;
    gap: 40px;
    max-width: 1200px;
    width: 100%;
  }
  & > div {
    width: 100%;
  }
  & > div > div {
    width: 100%;
  }
  & > div > div > div {
    width: 100%;

    & > div.text-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;

      & > span.main-text {
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
        /* identical to box height, or 138% */

        /* Gray/Gray-08 (White) */

        color: #1e1e1e;
      }
      & > span.sub-text {
        /* Paragraph/Paragraph 1-20R */

        font-family: "Pretendard";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        /* or 170% */

        /* Gray/Gray-04 */

        color: #505050;
      }
    }
    & > ul {
      list-style: disc;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 12px;

      & > li {
        font-family: "Pretendard";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 34px;
        /* identical to box height, or 170% */

        /* Gray/Gray-08 (White) */

        color: #1e1e1e;
        min-width: 290px;
      }
    }
  }
  ${media.TABLET} {
    ${tabletCardWrapper}
  }
  ${media.MOBILE} {
    ${mobileCardWrapper}
    max-width: 100% !important;
  }
`;
