import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { cssTemplate, media } from "../../../../constants/styles";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import Responsable from "../../../Responsable";
import BaseSection from "../../BaseSection";
import { MovingArrow, Title } from "../../shared/styled";
import ScrollOverlayPicExtention from "../scrollOverlays/ScrollOverlayPicExtention";
import Section1Content from "./Content/SectionContent";

interface RecruitSection01Props {}

const RecruitSection01: React.FC<RecruitSection01Props> = (props) => {
  const windowHieght = useRef<number>(window.screen.height);
  const animationHeight = window.screen.height;
  return (
    <BaseSection fullWidth>
      <Responsable
        pc={
          <ScrollOverlayPicExtention
            picture={"/image/webp/recruit/RecruitMain-Pc.webp"}
            animationHeight={`${animationHeight * 4}px`}
            maxHeight={"100vh"}
            text1={
              <>
                하루를 도전과 창조로 채우는 <br />
                일상 속 혁신가들
              </>
            }
            text2={
              <>
                생각해보니 늘 무언가 가치있는
                <br />
                서비스를 만들며 하루를 보내왔네요.
              </>
            }
            text3={
              <>
                일상을 도전과 창조로 가득 채우는
                <br />그 설렘과 뿌듯함을 함께 하고 싶습니다.
              </>
            }
            text4={
              <>
                {/* 슬로그업과 함께 성장하며 번쩍이는 한 방을 만들어 갈<br />
                테크 프로바이더를 찾습니다. */}
              </>
            }
          />
        }
        tablet={
          <ScrollOverlayPicExtention
            picture={"/image/webp/recruit/RecruitMain-Tablet.webp"}
            animationHeight={`${animationHeight * 3}px`}
            maxHeight={"100vh"}
            text1={
              <>
                하루를 도전과 창조로 채우는 <br />
                일상 속 혁신가들
              </>
            }
            text2={
              <>
                생각해보니 늘 무언가 가치있는
                <br />
                서비스를 만들며 하루를 보내왔네요.
              </>
            }
            text3={
              <>
                일상을 도전과 창조로 가득 채우는
                <br />그 설렘과 뿌듯함을 함께 하고 싶습니다.
              </>
            }
            text4={
              <>
                {/* 슬로그업과 함께 성장하며
                <br /> 번쩍이는 한 방을 만들어 갈<br />
                테크 프로바이더를 찾습니다. */}
              </>
            }
          />
        }
        mobile={
          <ScrollOverlayPicExtention
            picture={"/image/webp/recruit/RecruitMain-Mobile.webp"}
            animationHeight={`${animationHeight * 3}px`}
            maxHeight={"100vh"}
            text1={
              <>
                하루를 도전과 <br />
                창조로 채우는 <br />
                일상 속 혁신가들
              </>
            }
            text2={
              <>
                생각해보니 늘 무언가 가치있는
                <br />
                서비스를 만들며 하루를 보내왔네요.
              </>
            }
            text3={
              <>
                일상을 도전과 창조로 가득 채우는
                <br />그 설렘과 뿌듯함을 함께 하고 싶습니다.
              </>
            }
            text4={
              <>
                {/* 슬로그업과 함께 성장하며
                <br /> 번쩍이는 한 방을 만들어 갈<br />
                테크 프로바이더를 찾습니다. */}
              </>
            }
          />
        }
      />
    </BaseSection>
  );
};

export default RecruitSection01;
// const RecruitSection01: React.FC<RecruitSection01Props> = (props) => {
//   const windowHieght = useRef<number>(window.screen.height);
//   const animationHeight = window.screen.height;

//   const { ref, isIntersecting } = useIntersecting();
//   const [interacted, setInteracted] = useState<boolean>(false);
//   useEffect(() => {
//     if (isIntersecting) setInteracted(true);
//   }, [isIntersecting]);
//   return (
//     <Base ref={ref} fullWidth fullHeight>
//       <Responsable
//         pc={<Section1Content screenType="pc" />}
//         tablet={<Section1Content screenType="tablet" />}
//         mobile={<Section1Content screenType="tablet" />}
//       />
//     </Base>
//   );
// };

// export default RecruitSection01;

// const Base = styled(BaseSection)`
//   position: relative;
//   min-height: 600px;
// `;
