import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { DEFAULT_INTERVAL } from "../../../../../constants/animationValue";
import {
  cssTemplate,
  media,
  mobileContainerGap,
  mobilePaddingTopBottom,
  pcContainerGap,
  pcPaddingTopBottom,
  tabletContainerGap,
  tabletPaddingTopBottom,
} from "../../../../../constants/styles";
import { useIntersecting } from "../../../../../hooks/useIntersecting";
import useInterval from "../../../../../hooks/useInterval";
import Button from "../../../../Button";
import Responsable from "../../../../Responsable";
import BaseSection from "../../../BaseSection";
import { maxWidth } from "../../../shared/styled";
import {
  Desc,
  DescWrapper,
  InfoWrapper,
  SectionLayout,
  TextH1,
  TextSpan,
  TextWrapper,
  TitleWrapper,
} from "../styled";

interface SectionProps {
  titleSpan: React.ReactNode;
  titleH1: React.ReactNode;
  buttonLabel: string;
  buttionClickHandler: (e: React.MouseEvent) => void;
  bottom?: React.ReactNode;
  descs: string[];
  gap?: number;
  types?: string;
}

const MobileSection: React.FC<SectionProps> = ({
  titleH1,
  titleSpan,
  buttionClickHandler,
  buttonLabel,
  bottom,
  descs,
  gap,
  types,
}) => {
  return (
    <BaseSection fullWidth>
      <SectionLayout>
        <InfoWrapper>
          <TitleWrapper>
            <TextWrapper>
              <TextSpan>{titleSpan}</TextSpan>
              <TextH1>{titleH1}</TextH1>
            </TextWrapper>
          </TitleWrapper>
          {types === "valueup" && bottom}
          <DescWrapper>
            {descs.map((desc, index) => (
              <Desc key={desc}>{desc}</Desc>
            ))}
          </DescWrapper>
        </InfoWrapper>

        {(types === "outsourcing" || types === "tech") && bottom}
        <Button
          types="black"
          isAnimated={false}
          label={buttonLabel}
          onClick={buttionClickHandler}
        />
      </SectionLayout>
    </BaseSection>
  );
};

export default MobileSection;
