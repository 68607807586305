import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  media,
  pcPaddingTopBottom,
  tabletPaddingTopBottom,
} from "../../../../../constants/styles";
import { SectionProps } from "../../../shared/types";

interface ScrollOverlayMackBookAirProps extends SectionProps {
  maxHeight: string;
  minHeight: string;
}

const ScrollOverlayMacBookAir: React.FC<ScrollOverlayMackBookAirProps> = ({
  screenType,
  maxHeight,
  minHeight,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const font1Ref = useRef<HTMLDivElement>(null);
  const font2Ref = useRef<HTMLDivElement>(null);
  const img1Ref = useRef<HTMLImageElement>(null);
  const img2Ref = useRef<HTMLImageElement>(null);
  const tick = 1000;

  const screenHeight = window.innerHeight;

  const scrollEvent = () => {
    if (
      containerRef.current &&
      contentRef.current &&
      font1Ref.current &&
      font2Ref.current &&
      img1Ref.current &&
      img2Ref.current
    ) {
      const isMobileCheck = window.innerWidth <= 672;

      const rect = containerRef.current.getBoundingClientRect();
      const maxFirstTransformValue = 15;
      const minFirstTransformValue = -15;
      const maxSecondTransformValue = 70;
      const minSecondTransformValue = isMobileCheck ? 0 : -20;

      const isContainerInViewport = rect.top + rect.height - window.innerHeight;

      // 뷰포트 안에 들어왔을때
      if (rect.top <= 0) {
        if (isContainerInViewport) {
          const fullSectionHeight =
            (containerRef.current.scrollHeight - window.innerHeight) / 5;

          const opacity1Ratio =
            (-rect.top - fullSectionHeight * 1) / fullSectionHeight;

          const opacity2Ratio =
            (-rect.top - fullSectionHeight * 3) / fullSectionHeight;

          const transform1Ratio =
            (1 - opacity1Ratio - 0.5) * maxFirstTransformValue;

          const transform2Ratio =
            (1 - opacity2Ratio - 0.5) * maxFirstTransformValue;

          if (transform1Ratio >= -maxFirstTransformValue) {
            img1Ref.current.style.opacity = `1`;
            img2Ref.current.style.opacity = `0`;

            font1Ref.current.style.transform = `matrix(${1},0,0,${1},0,${
              transform1Ratio > maxFirstTransformValue
                ? maxFirstTransformValue
                : transform1Ratio < minFirstTransformValue
                ? minFirstTransformValue
                : transform1Ratio
            })`;
            font2Ref.current.style.opacity = "0";
            if (1 - opacity1Ratio < 1)
              font1Ref.current.style.opacity = `${1 - opacity1Ratio + 0.2}`;
          } else {
            img1Ref.current.style.opacity = `${1 - opacity1Ratio}`;
            img2Ref.current.style.opacity = `1`;
            font1Ref.current.style.opacity = "0";
            font2Ref.current.style.opacity = `${opacity2Ratio + 0.4}`;
            font2Ref.current.style.transform = `matrix(${1},0,0,${1},0,${
              transform2Ratio > maxSecondTransformValue
                ? maxSecondTransformValue
                : transform2Ratio < minSecondTransformValue
                ? minSecondTransformValue
                : transform2Ratio
            })`;
          }
        }
      } else {
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollEvent);
    return () => window.removeEventListener("scroll", scrollEvent);
  }, []);
  const renderText1 = useCallback(() => {
    switch (screenType) {
      case "PC":
        return (
          <FontWrapper ref={font1Ref}>
            슬로그업은 하루를
            <br />
            도전과 창조로 채우는
            <br />
            일상 속 혁신가들입니다.
          </FontWrapper>
        );
      case "TABLET":
        return (
          <FontWrapper ref={font1Ref}>
            슬로그업은 하루를
            <br />
            도전과 창조로 채우는
            <br />
            일상 속 혁신가들입니다.
          </FontWrapper>
        );
      case "MOBILE":
        return (
          <FontWrapper ref={font1Ref}>
            슬로그업은 하루를
            <br />
            도전과 창조로 채우는
            <br />
            일상 속 혁신가들입니다.
          </FontWrapper>
        );
      default:
        return (
          <FontWrapper ref={font1Ref}>
            슬로그업은 하루를
            <br />
            도전과 창조로 채우는
            <br />
            일상 속 혁신가들입니다.
          </FontWrapper>
        );
    }
  }, [screenType]);
  const renderText2 = useCallback(() => {
    switch (screenType) {
      case "PC":
        return (
          <Font2Wrapper ref={font2Ref}>
            세상을 크게 깨울
            <br />
            프로덕트를 만듭니다.
          </Font2Wrapper>
        );
      case "TABLET":
        return (
          <Font2Wrapper ref={font2Ref}>
            세상을 크게 깨울
            <br />
            프로덕트를 만듭니다.
          </Font2Wrapper>
        );
      case "MOBILE":
        return (
          <Font2Wrapper ref={font2Ref}>
            세상을 크게 깨울
            <br />
            프로덕트를 만듭니다.
          </Font2Wrapper>
        );
      default:
        return (
          <Font2Wrapper ref={font2Ref}>
            세상을 크게 깨울
            <br />
            프로덕트를 만듭니다.
          </Font2Wrapper>
        );
    }
  }, [screenType]);
  return (
    <Container
      className="homeSection2Container"
      minHeight={minHeight}
      maxHeight={maxHeight}
      ref={containerRef}
    >
      <StickContainer ref={contentRef}>
        <HardWareWrapper>
          <ContentRow>
            {renderText1()}
            <ImgWrapper>
              <Img
                ref={img1Ref}
                src="/image/webp/home/Laptop1.webp"
                screenHeight={screenHeight}
              />
            </ImgWrapper>
          </ContentRow>
        </HardWareWrapper>
        <HardWareWrapper>
          <ContentRow>
            {renderText2()}
            <ImgWrapper>
              <Img2
                ref={img2Ref}
                src="/image/webp/home/MacBook.webp"
                screenHeight={screenHeight}
              />
            </ImgWrapper>
          </ContentRow>
        </HardWareWrapper>
      </StickContainer>
    </Container>
  );
};
export default ScrollOverlayMacBookAir;
const Container = styled.div<
  Pick<ScrollOverlayMackBookAirProps, "maxHeight" | "minHeight">
>`
  height: 300vh;
  width: 100%;
  min-height: 600px;
  position: relative;
  // margin-top: 100px;
  display: flex;
  // padding: 200px 0px;

  justify-content: center;
  ${media.TABLET} {
    // padding: ${tabletPaddingTopBottom} 0px;
  }
`;
const StickContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100vh;
  min-height: 600px;
  // padding-left: calc((100vw - 1200px) / 2);
  width: 100%;
  overflow: hidden;
  max-height: 200vh;
  ${media.TABLET} {
    top: 48px;
  }
  ${media.MOBILE} {
    top: 60px;
  }
`;

const HardWareWrapper = styled.div`
  padding: 0px 48px;
  max-width: 1296px;
  position: absolute;

  height: 100vh;
  width: 100%;
  z-index: 1;

  background: transparent;
  ${media.TABLET} {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: calc((calc(var(--vh, 1vh) * 100) - 670px) / 2);
  }
  ${media.MOBILE} {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: calc((calc(var(--vh, 1vh) * 100) - 430px) / 2);
  }
`;
const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  align-items: center;
  padding: 0px;
  gap: 55px;
  ${media.TABLET} {
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
  }
  ${media.MOBILE} {
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
  }
`;
const FontWrapper = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  color: #1e1e1e;
  font-size: 32px;
  opacity: 1;
  line-height: 44px;
  flex: 0 0 340px;
  transform: matrix(1, 0, 0, 1, 0, 15);
  & > span {
    color: #b3ff01;
  }
  ${media.TABLET} {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    /* or 138% */

    display: table-cell;
    vertical-align: bottom;
    text-align: center;
    flex: 1;
    /* Gray/Gray-08 (White) */

    // color: #ffffff;
  }
  ${media.MOBILE} {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    /* or 150% */

    text-align: center;

    /* Gray/Gray-08 (White) */

    // color: #ffffff;
  }
`;
const Font2Wrapper = styled(FontWrapper)`
  opacity: 0;

  ${media.TABLET} {
    padding-top: 42px;
  }
  ${media.MOBILE} {
    padding-top: 35px;
  }
`;
const ImgWrapper = styled.div`
  position: relative;
  // padding-top: 80px;

  ${media.TABLET} {
    padding-top: 0px;
    flex: 0 0 350px;
  }
  ${media.MOBILE} {
    flex: 0 0 190px;
  }
`;
const Img = styled.img<{ screenHeight: number }>`
  background-image: url("/image/webp/home/Laptop1.webp");
  flex: 1;
  width: 100%;
  max-width: 1153px;
  height: 100%;

  left: 0;
  opacity: 1;
  transition: opacity 0.5s;
  background-repeat: no-repeat;
  background-size: contain;
  ${media.TABLET} {
    width: 604px;
    height: 350px;
  }
  ${media.MOBILE} {
    height: 190px;
    width: 327px;
  }
`;
const Img2 = styled(Img)`
  background-image: url("/image/webp/home/MacBook.webp");
  opacity: 0;
`;
