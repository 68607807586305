import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BUSINESS_CARD_INTERVAL } from "../../../../constants/animationValue";
import {
  outsourcingIcons,
  tabletOutsourcingIcons,
} from "../../../../constants/outsourcingServices";
import routes from "../../../../constants/routes";
import { animations, cssTemplate, media } from "../../../../constants/styles";
import { valueIcons } from "../../../../constants/valueUpServices";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import useInterval from "../../../../hooks/useInterval";
import Responsable from "../../../Responsable";
import BaseSection from "../../BaseSection";
import { Title } from "../../shared/styled";
import MobileSection from "../shared/MobileSection";
import PCSection from "../shared/PCSection";
import TabletSection from "../shared/TabletSection";

interface BusinessSection03Props {}

const BusinessSection03: React.FC<BusinessSection03Props> = (props) => {
  const mobileDescs = [
    `슬로그업은 SW를 잘 만드는 스타트업입니다. ‘잘 만든다’는 말을 개발일정 잘 지키고 디자인 예쁜 걸로 여기지 않습니다. 다층적 비즈니스를 구조적으로 이해하고 사용자 일상에 스며드는 프로덕트를 만듭니다.`,
    `대형 신사업 서비스를 가장 많이 만들어본 스타트업을 꼽으면 슬로그업은 다섯 손가락에 꼽힙니다. 그 모든 프로젝트를 지난 10년간 전부 성공시킨 곳을 추리면, 저희밖에 없을지도 혹시 모릅니다.`,
    `슬로그업은 혁신이 일상에 어떤 모습으로 구현되는지 알고 있습니다.`,
  ];
  const tabletDescs = [
    `슬로그업은 SW를 잘 만드는 스타트업입니다.\n‘잘 만든다’는 말을 개발일정 잘 지키고 디자인 예쁜 걸로 여기지 않습니다.\n다층적 비즈니스를 구조적으로 이해하고\n사용자 일상에 스며드는 프로덕트를 만듭니다.`,
    `대형 신사업 서비스를 가장 많이 만들어본 스타트업을 꼽으면\n슬로그업은 다섯 손가락에 꼽힙니다. 그 모든 프로젝트를 지난 10년간 전부\n성공시킨 곳을 추리면, 저희밖에 없을지도 혹시 모릅니다.`,
    `슬로그업은 혁신이 일상에 어떤 모습으로 구현되는지 알고 있습니다.`,
  ];
  const descs = [
    `슬로그업은 SW를 잘 만드는 스타트업입니다. ‘잘 만든다’는 말을 개발일정\n잘 지키고 디자인 예쁜 걸로 여기지 않습니다. 다층적 비즈니스를\n구조적으로 이해하고 사용자 일상에 스며드는 프로덕트를 만듭니다.`,
    `대형 신사업 서비스를 가장 많이 만들어본 스타트업을 꼽으면 슬로그업은\n다섯 손가락에 꼽힙니다. 그 모든 프로젝트를 지난 10년간 전부\n성공시킨 곳을 추리면, 저희밖에 없을지도 혹시 모릅니다.`,
    `슬로그업은 혁신이 일상에 어떤 모습으로 구현되는지 알고 있습니다.`,
  ];

  const handleClickButton = (e: React.MouseEvent) => {
    // e.preventDefault();
    // nav(routes.RECRUIT);
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLScn90zgmVvJoe1VuzjM7joC-MDf4Ww5ey2Nh7XTxtLPtICX1g/viewform",
      "_blank"
    );
  };

  return (
    <Responsable
      pc={
        <Section3>
          <PCSection
            titleH1={
              <>
                슬로그업이 협력한
                <br />
                아웃소싱 서비스.
              </>
            }
            titleSpan="Outsourcing Service"
            buttonLabel="문의하기"
            buttionClickHandler={handleClickButton}
            descs={descs}
            bottom={<Bottom datas={outsourcingIcons} />}
            types="outsourcing"
          />
        </Section3>
      }
      tablet={
        <Section3>
          <TabletSection
            titleH1={
              <>
                슬로그업이 협력한
                <br />
                아웃소싱 서비스.
              </>
            }
            titleSpan="Outsourcing Service"
            buttonLabel="문의하기"
            buttionClickHandler={handleClickButton}
            descs={tabletDescs}
            bottom={<Bottom datas={outsourcingIcons} />}
            types="outsourcing"
          />
        </Section3>
      }
      mobile={
        <Section3>
          <MobileSection
            titleH1={
              <>
                슬로그업이 협력한
                <br />
                아웃소싱 서비스.
              </>
            }
            titleSpan="Outsourcing Service"
            buttonLabel="문의하기"
            buttionClickHandler={handleClickButton}
            descs={mobileDescs}
            bottom={<Bottom datas={tabletOutsourcingIcons} />}
            types="outsourcing"
          />
        </Section3>
      }
    />
  );
};

export default BusinessSection03;

const Bottom: React.FC<{ datas: string[][] }> = ({ datas }) => {
  const { ref, isIntersecting } = useIntersecting();
  const [isInteracted, setIsInteracted] = useState(-1);
  const maxValue = datas.reduce((sum, next) => {
    return sum + next.length;
  }, 0);
  useInterval(
    () => {
      setIsInteracted(isInteracted + 1);
    },
    isIntersecting
      ? isInteracted > datas.length * (maxValue / datas.length)
        ? null
        : BUSINESS_CARD_INTERVAL - 50
      : null
  );
  return (
    <BottomWrapper ref={ref}>
      {datas.map((iconRow, rowIndex) => (
        <IconRow key={rowIndex + "iconRow"}>
          {iconRow.map((icon, index) => (
            <div style={{ width: "100%" }}>
              <Icon
                theme={{
                  isIntersecting:
                    isInteracted >= rowIndex * iconRow.length + index,
                }}
                key={icon + index}
                src={`/image/webp/business/${icon}.webp`}
              />
            </div>
          ))}
        </IconRow>
      ))}
    </BottomWrapper>
  );
};

const Section3 = styled.div`
  width: 100%;
  background-color: #f8f8f8;
  display: flex;
  flex-wrap: wrap;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 60px;
  ${media.MOBILE} {
    gap: 24px;
  }
`;
const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  ${media.MOBILE} {
    gap: 0px;
  }
`;
const Icon = styled.img<{ src: string }>`
  max-width: 280px;
  width: 100%;

  ${(props) => cssTemplate.fadeInOut(props)}
  ${media.TABLET} {
    max-width: 160px;
    max-height: 34px;
  }
  ${media.MOBILE} {
    width: 149.33px;
    height: 32px;
  }
`;
