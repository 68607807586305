import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { cssTemplate, media } from "../../../../constants/styles";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import { Suspense } from "../../../Suspense";

const Image: React.FC<{ screenType: string }> = ({ screenType }) => {
  const { ref, isIntersecting } = useIntersecting();
  const [isPicIntersecting, setIsPicIntersecting] = useState<boolean>(false);
  useEffect(() => {
    if (isIntersecting) setIsPicIntersecting(true);
  }, [isIntersecting]);
  return (
    <>
      {screenType === "pc" && (
        <ImgWrapper
          src="/image/webp/home/LastImg-Pc.webp"
          ref={ref}
          theme={{ isIntersecting: isPicIntersecting }}
        />
      )}
      {screenType === "tablet" && (
        <ImgWrapper
          src="/image/webp/home/LastImg-Tablet.webp"
          ref={ref}
          theme={{ isIntersecting: isPicIntersecting }}
        />
      )}
      {screenType === "mobile" && (
        <ImgWrapper
          src="/image/webp/home/LastImg-Mobile.webp"
          ref={ref}
          theme={{ isIntersecting: isPicIntersecting }}
        />
      )}
    </>
  );
};
export default Image;
const ImgWrapper = styled.img`
  overflow: hidden;
  max-width: 1200px;
  width: 100%;
  height: 600px;
  object-fit: cover;
  border-radius: 24px;
  ${(props) => cssTemplate.fadeInOut(props)}
  ${media.TABLET} {
    max-width: 672px;
    height: 672px;
  }
  ${media.MOBILE} {
    max-width: 100%;
    height: 327px;
  }
`;

const Img = styled.img`
  ${media.TABLET} {
    display: none;
    // height: 672px;
  }
  ${media.MOBILE} {
    border-radius: 12px;
    display: none;
  }
`;
