import React, { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/Layout";

import RecruitSection01 from "../../components/sections/recruitSection/RecruitSection01";
import RecruitSection02 from "../../components/sections/recruitSection/RecruitSection02";
import LastSection from "../../components/sections/recruitSection/LastSection";
import RecruitSection03 from "../../components/sections/recruitSection/RecruitSection03";
import RecruitSection04 from "../../components/sections/recruitSection/RecruitSection04";
import RecruitSection05 from "../../components/sections/recruitSection/RecruitSection05";

interface JoinProps {}

const Join: React.FC<JoinProps> = (props) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>Slogup - Recruit</title>
      </Helmet>
      {/* 하루를 도전과 창조로 채우는... */}
      <RecruitSection01 />

      {/*  “필요한 프로덕트가... */}
      <RecruitSection02 />

      {/*  일하기 좋은 환경... */}
      <RecruitSection03 />

      {/*   슬로그업 통해 입사지원시... */}
      {/* <RecruitSection04 /> */}

      {/* FAQ... */}
      <RecruitSection05 />

      {/* 우리 함께 일해요!... */}
      <LastSection />
    </Layout>
  );
};

export default Join;
