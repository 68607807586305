import React from "react";

export interface Culture {
  pcImgUrl: string;
  tabletImgUrl: string;
  mobileImgUrl: string;
  title: string;
  desc: string;
  link: string;
}

export const cultures: Culture[] = [
  {
    pcImgUrl: "/image/webp/team/culture1-Pc.webp",
    tabletImgUrl: "/image/webp/team/culture1-Tablet.webp",
    mobileImgUrl: "/image/webp/team/culture1-Mobile.webp",
    title: "조직문화",
    desc: "리소스 낭비가 가장 적은 조직구조를 만들기 위해 꾸준히 노력합니다. 대체로 실리콘밸리에서 검증된 조직문화를 공부하고 도입해보며 우리만의 방법론을 만들어 나갑니다.",
    link: "https://brunch.co.kr/@team-slogup/172",
  },
  {
    pcImgUrl: "/image/webp/team/culture2-Pc.webp",
    tabletImgUrl: "/image/webp/team/culture2-Tablet.webp",
    mobileImgUrl: "/image/webp/team/culture2-Mobile.webp",
    title: "프로덕션문화",
    desc: "슬로그업이 맡는 프로젝트는 대부분 큰 규모이며 혼자 힘으론 좋은 프로덕트를 완성할 수 없습니다. 그래서 협업의 가치를 온전히 이해하고 유기체적인 한팀으로 일하는 걸 중요히 여깁니다.",
    link: "https://brunch.co.kr/@team-slogup/173",
  },
];
