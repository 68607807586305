export interface OutSourcingService {
  logoSrc: string;
  mainTitle: string;
  link?: string;
  appLinkObj?: {
    webLink?: string;
    iosLink?: string;
    aosLink?: string;
  };
  date: string;
  mainInfo: string | React.ReactNode;
  smallInfo: string | React.ReactNode;
  mainImg: string;
  mobileMainInfo?: string | React.ReactNode;
  tabletSmallInfo?: string | React.ReactNode;
  mobileSmallInfo?: string | React.ReactNode;
}

export const outsourcingIcons = [
  ["SKRenterCarDirect", "SKNetwork", "SKgas", "SSCharger"],
  ["EverOn", "MinTit", "Cartini", "LgCurrentDot"],
];
export const tabletOutsourcingIcons = [
  ["SKRenterCarDirect", "SKNetwork"],
  ["SKgas", "SSCharger"],
  ["EverOn", "MinTit"],
  ["Cartini", "LgCurrentDot"],
];
const defaultImageSrc = "/image/webp/home/";
const fileExtention = ".webp";
const getFileUrl = (name: string) => defaultImageSrc + name + fileExtention;
const outSourcingServices: OutSourcingService[] = [
  {
    logoSrc: "/image/home/SkLogo.svg",
    mainImg: getFileUrl("SkRDImg"),
    link: "https://brunch.co.kr/@team-slogup/181",
    mainTitle: "SK 렌터카 다이렉트",
    mainInfo: "최초의 다이렉트 자동차 렌탈 서비스",
    smallInfo: (
      <>
        SK렌터카, SKC&C와 협력해 만든 비대면 다이렉트 렌터카 서비스.
        <br />
        렌터카 시장에 혁신을 일으키며 SK렌터카 사상 최대 실적을 이끈 주인공.
      </>
    ),
    tabletSmallInfo: (
      <>
        SK렌터카, SKC&C와 협력해 만든 비대면 다이렉트 렌터카 서비스.
        <br />
        렌터카 시장에 혁신을 일으키며 SK렌터카 사상 최대 실적을 이끈 주인공.
      </>
    ),
    mobileSmallInfo:
      "SK렌터카, SK C&C와 협력해 만든 비대면 다이렉트 렌터카\n서비스. 렌터카 시장에 혁신을 일으키며 SK렌터카 사상 최대\n실적을 이끈 주인공.",
    date: "2020.09 ~ 2022.12",
    appLinkObj: { webLink: "https://www.skdirect.co.kr/nc" },
  },
  {
    logoSrc: "/image/home/CarpetLogo.svg",
    mainImg: getFileUrl("CarpetImg"),
    link: "https://brunch.co.kr/@team-slogup/180",
    mainTitle: "더카펫",
    mainInfo: "수입차 관리의 모든 것, 카펫에서 쉽고 편리하게",
    smallInfo: (
      <>
        수입차 관리를 손쉽게 만들어주는 수입차 케어 토탈 솔루션.
        <br />
        보증기간이 끝나도, 발품 팔지 않아도 알아서 관리해주는 똑똑한 서비스.
      </>
    ),
    mobileSmallInfo:
      "수입차 관리를 손쉽게 만들어주는 수입차 토탈 케어 솔루션.\n보증기간이 끝나도, 발품 팔지 않아도 알아서 관리해주는 똑똑한 서비스.",
    date: "2021.03 ~ 2023.03",
    appLinkObj: {
      aosLink: "https://play.google.com/store/apps/details?id=com.skn.carpet",
      iosLink:
        "https://apps.apple.com/kr/app/the-carpet-%EC%B9%B4%ED%8E%AB-%EC%B0%A8%EB%B3%84%ED%99[…]C-%EC%88%98%EC%9E%85%EC%B0%A8-%EA%B4%80%EB%A6%AC/id1573498627",
    },
  },
  {
    logoSrc: "/image/home/MintitLogo.svg",
    mainImg: getFileUrl("MintitImg"),
    link: "https://brunch.co.kr/@team-slogup/175",
    mainTitle: "민팃",
    mainInfo: "AI 기반 중고폰 거래 ATM",
    smallInfo: (
      <>
        대형마트, 아파트 등에 설치된 5,600개 ATM으로 중고폰 판매·기부.
        <br />안 쓰는 폰 용돈으로 바꾸고 데이터 완전 삭제로 개인정보까지 안심.
      </>
    ),
    tabletSmallInfo: (
      <>
        대형마트, 아파트 등에 설치된 5,600개 ATM으로 중고폰 판매·기부.
        <br />안 쓰는 폰 용돈으로 바꾸고 데이터 완전 삭제로 개인정보까지 안심.
      </>
    ),
    mobileSmallInfo:
      "대형마트, 아파트 등에 설치된 5,600개 ATM으로 중고폰 판매·기부. 안 쓰는 폰 용돈으로 바꾸고 데이터 완전 삭제로 개인정보까지 안심.",
    date: "2021.03 ~ Now",
    appLinkObj: {
      aosLink: "https://play.google.com/store/apps/details?id=com.skn.mintit",
      iosLink: "https://apps.apple.com/kr/app/%EB%AF%BC%ED%8C%83/id1577650973",
    },
  },
  {
    logoSrc: "/image/home/HighendLogo.svg",
    mainImg: getFileUrl("H2Img"),
    link: "https://brunch.co.kr/@team-slogup/176",
    mainTitle: "하이앤드",
    mainInfo: "수소 모빌리티 플랫폼 하이앤드(H2&)",
    smallInfo: (
      <>
        수소차 충전 시장을 선도하는 미래 모빌리티 시장의 파이오니어.
        <br />
        충전소 검색 및 실시간 대기현황부터 예약, 결제까지 한번에.
      </>
    ),
    tabletSmallInfo: (
      <>
        수소차 충전 시장을 선도하는 미래 모빌리티 시장의 파이오니어.
        <br />
        충전소 검색 및 실시간 대기현황부터 예약, 결제까지 한번에.
      </>
    ),
    mobileSmallInfo:
      "수소차 충전 시장을 선도하는 미래 모빌리티 시장의 파이오\n니어.\n충전소 검색 및 실시간 대기현황부터 예약, 결제까지 한번에.",
    date: "2021.09 ~ Now",
    appLinkObj: {
      aosLink:
        "https://play.google.com/store/apps/details?id=com.skgas.hiand&pli=1",
      iosLink: "https://apps.apple.com/kr/app/h2/id1606102006",
    },
  },
  {
    logoSrc: "/image/home/TirepickLogo.svg",
    mainImg: getFileUrl("TirepickImg"),
    link: "https://brunch.co.kr/@team-slogup/179",
    mainTitle: "타이어픽",
    mainInfo: "타이어는 역시 타이어픽, 타이어 교체 NO.1",
    smallInfo: (
      <>
        SK네트웍스 스피드메이트에서 스핀오프한 차량 관리 플랫폼.
        <br />차 번호만 입력하면 내 차에 맞는 소모품 매칭, 구매, 장착까지.
      </>
    ),
    tabletSmallInfo: (
      <>
        SK네트웍스 스피드메이트에서 스핀오프한 차량 관리 플랫폼.
        <br />차 번호만 입력하면 내 차에 맞는 소모품 매칭, 구매, 장착까지.
      </>
    ),
    mobileSmallInfo:
      "SK네트웍스 스피드메이트에서 스핀오프한 차량 관리 플랫\n폼. 차 번호만 입력하면 내 차에 맞는 소모품 매칭, 구매, 장착\n까지.",
    date: "2022.01 ~ Now",
    appLinkObj: {
      aosLink:
        "https://play.google.com/store/apps/details?id=com.cartini.tirepick&pli=1",
      iosLink: "https://apps.apple.com/app/id1615491108",
    },
  },
  {
    logoSrc: "/image/home/PluspotLogo.svg",
    mainImg: getFileUrl("PluspotImg"),
    // link: "https://brunch.co.kr/@team-slogup/177",
    link: "comingsoon",
    mainTitle: "플러스팟",
    mainInfo: "킥보드 주차하면 포인트 플러스, 환경까지 플러스",
    mobileMainInfo: (
      <>
        킥보드 주차하면 포인트 플러스,
        <br />
        환경까지 플러스
      </>
    ),
    smallInfo: (
      <>
        LG전자의 PM(Personal Mobility) 스테이션 서비스.
        <br />
        ‘팜’에 주차하면 포인트 적립받고 도시환경도 개선하는 힙한 서비스.
      </>
    ),
    tabletSmallInfo: (
      <>
        LG전자의 PM(Personal Mobility) 스테이션 서비스.
        <br />
        ‘팜’에 주차하면 포인트 적립받고 도시환경도 개선하는 힙한 서비스.
      </>
    ),
    mobileSmallInfo:
      "LG전자의 PM(Personal Mobility) 스테이션 서비스.\n‘팜’에 주차하면 포인트 적립받고 도시환경도 개선하는 힙한\n서비스.",
    date: "2022.04 ~ Now",
    appLinkObj: {
      aosLink: "https://play.google.com/store/apps/details?id=com.lge.pluspot",
      // aosLink: "comingsoon",
      iosLink:
        "https://apps.apple.com/kr/app/%ED%94%8C%EB%9F%AC%EC%8A%A4%ED%8C%9F/id1625238503",
      // iosLink: "comingsoon",
    },
  },
  {
    logoSrc: "/image/home/EveronLogo.svg",
    mainImg: getFileUrl("EveronImg"),
    link: "https://brunch.co.kr/@team-slogup/182",
    mainTitle: "에버온",
    mainInfo: "고객을 학습해 불편을 줄이는 전기차 충전 플랫폼",
    mobileMainInfo: (
      <>
        고객을 학습해 불편을 줄이는
        <br />
        전기차 충전 플랫폼
      </>
    ),
    smallInfo: (
      <>
        국내 최대 규모 전기차 충전 플랫폼.
        <br />
        충전기 위치부터 모바일 간편 충전까지, 미래 성장의 주역이 될 잠재력.
      </>
    ),
    tabletSmallInfo: (
      <>
        국내 최대 규모 전기차 충전 플랫폼.
        <br />
        충전기 위치부터 모바일 간편 충전까지, 미래 성장의 주역이 될 잠재력.
      </>
    ),
    mobileSmallInfo:
      "국내 최대 규모 전기차 충전 플랫\n폼. 충전기 위치부터 모바일 간편 충전까지, 미래 성장의 주역\n이 될 잠재력.",
    date: "2022.08 ~ Now",
    appLinkObj: {
      aosLink:
        "https://play.google.com/store/apps/details?id=com.chasygo&hl=ko",
      iosLink:
        "https://apps.apple.com/kr/app/%EC%97%90%EB%B2%84%EC%98%A8-%EC%A0%84%EA%B8%B0%EC%B0%A[…]4%EA%B8%B0-%EA%B2%80%EC%83%89-%EA%B2%B0%EC%A0%9C/id1330538811",
    },
  },
  // {
  //   logoSrc: getFileUrl("ChargerLogo2"),
  //   mainImg: getFileUrl("ChargerImg2"),
  //   link: "https://brunch.co.kr/@team-slogup/178",
  //   mainTitle: "SK 일렉링크 ",
  //   mainInfo: "On Your Way, Always, 전기차 충전 통합 솔루션",
  //   mobileMainInfo: "On Your Way, Always,\n전기차 충전 통합 솔루션",
  //   smallInfo:
  //     "더 가깝고, 더 스마트하고, 더 유쾌하고, 더 착한 충전 1위 플랫폼.\n대기시간 없앤 예약 서비스, 긴급 충전 배달 서비스까지.",
  //   mobileSmallInfo:
  //     "더 가깝고, 더 스마트하고, 더 유쾌하고, 더 착한 충전 1위 플\n랫폼.대기시간 없앤 예약 서비스, 긴급 충전 배달 서비스까\n지.",
  //   date: "2023.01 ~ Now",
  // },
];

export default outSourcingServices;
