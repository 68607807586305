import React, { forwardRef, PropsWithChildren } from "react";
import styled, { css, CSSObject } from "styled-components";
import { grid, media } from "../../../constants/styles";

interface BaseSectionProps extends PropsWithChildren {
  style?: CSSObject;
  fullWidth?: boolean;
  fullHeight?: boolean;
  autoHeight?: boolean;
}

const BaseSection = forwardRef<any, BaseSectionProps>((props, ref) => {
  return (
    <StyledSection
      ref={ref}
      style={props.style}
      fullWidth={props.fullWidth}
      fullHeight={props.fullHeight}
      autoHeight={props.autoHeight}
    >
      <article>{props.children}</article>
    </StyledSection>
  );
});

export default BaseSection;

const StyledSection = styled.section<{
  fullWidth?: boolean;
  fullHeight?: boolean;
  autoHeight?: boolean;
}>`
  position: relative;
  // width: ${({ fullWidth }) => (fullWidth ? "100%" : `${grid.MAX}px`)};
  width: 100%;
  height: ${({ autoHeight }) => (autoHeight ? "auto" : "100%")};
  margin: auto;
  ${({ fullHeight }) => {
    if (fullHeight) {
      return css`
        min-height: 100vh;
      `;
    }
  }}

  ${media.TABLET} {
    width: 100%;
  }

  ${media.MOBILE} {
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
`;
