import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { observerEntriesState, observerState } from "../constants/recoil";

export const useIntersecting = () => {
  const observer = useRecoilValue(observerState);
  const entries = useRecoilValue(observerEntriesState);
  const [isIntersecting, setIsIntersecting] = useState<boolean>();

  const ref = useRef<any>(null);

  useEffect(() => {
    if (ref.current && observer) {
      observer.observe(ref.current);
    }
  }, [observer]);

  useEffect(() => {
    if (entries) {
      entries.forEach((entry) => {
        if (entry.target === ref.current) {
          setIsIntersecting(entry.isIntersecting);
        }
      });
    }
  }, [entries]);

  return {
    isIntersecting,
    ref,
  };
};
