export interface ValueUpService {
  logSrc: string;
  tabletMainImg: string;
  mobileMainImg: string;
  link?: string;
  title: string;
  brunchLink?: string;
  date: string;
  mainDesc: string | React.ReactNode;
  subDesc: React.ReactNode;
  mobileMainDesc?: React.ReactNode;
  mobileSubDesc?: React.ReactNode;
  mainImg: string;
  [index: string]: any;
}
export const valueIcons = ["SGSGIcon.webp", "BBLIcon.webp"];
export const valueUpServices: ValueUpService[] = [
  {
    logSrc: "/image/webp/home/SGSG.webp",
    tabletMainImg: "/image/webp/home/SGSGTablet.webp",
    link: "https://sgsg.slogup.com/",
    brunchLink: "https://brunch.co.kr/@team-slogup/174",
    title: "쓱싹",
    date: "2021.11 ~ Now",
    mainDesc: "정직한 시장을 만드는 가전 케어 플랫폼",
    subDesc: (
      <>
        소비자 기만이 일상이던 가전시장을 개선하는 1위 가전 케어 플랫폼.
        <br />
        고객 95%가 대만족·만족을 표하며 응원하는 세상에 꼭 필요한 서비스.
        <br />
        고객·전문가 만족도 4.9↑ / LG전자, 오늘의집 등에 서비스 제휴공급중.
      </>
    ),
    tabletSubDesc: (
      <>
        소비자 기만이 일상이던 가전시장을 개선하는 1위 가전 케어 플랫폼.
        <br />
        고객 95%가 대만족·만족을 표하며 응원하는 세상에 꼭 필요한 서비스.
        <br />
        고객·전문가 만족도 4.9↑ / LG전자, 오늘의집 등에 서비스 제휴공급중.
      </>
    ),
    mobileSubDesc: (
      <>
        소비자 기만이 일상이던 가전시장을 개선하는 1위 가전 케어 플랫폼.
        <br />
        고객 95%가 대만족·만족을 표하며 응원하는 세상에 꼭 필요한 서비스.
        <br />
        고객·전문가 만족도 4.9↑ / LG전자, 오늘의집 등에 서비스 제휴공급중.
      </>
    ),
    mainImg: "/image/webp/home/SGSGBig.webp",
    mobileMainImg: "/image/webp/home/SGSGMobile.webp",
  },
  {
    link: "end",
    logSrc: "/image/webp/home/BBL.webp",
    tabletMainImg: "/image/webp/home/BBLTablet.webp",
    mobileMainImg: "/image/webp/home/BBLMobile.webp",

    title: "봄블링",
    date: "2014.06 ~ 2017.01",
    mainDesc: "매치메이킹 앱의 새로운 가능성을 보여준 호감도 게임",
    mobileMainDesc: (
      <>
        매치메이킹 앱의 새로운 가능성을 <br />
        보여준 호감도 게임
      </>
    ),
    subDesc: (
      <>
        한국·미국·중국 등 실시간 글로벌 접속자들의 호감도 게임 앱.
        <br />
        7만 가입까지 마케팅비 270만원, CAC 38.5원의 비현실적 지표 기록.
        <br />
        양대 앱 마켓 소셜 최고매출 10권 안착, 중국시장 진출(칭워卿我).
      </>
    ),
    tabletSubDesc: (
      <>
        한국·미국·중국 등 실시간 글로벌 접속자들의 호감도 게임 앱.
        <br />
        7만 가입까지 마케팅비 270만원, CAC 38.5원의 비현실적 지표 기록.
        <br />
        양대 앱 마켓 소셜 최고매출 10권 안착, 중국시장 진출(칭워卿我).
      </>
    ),
    mobileSubDesc: (
      <>
        한국·미국·중국 등 실시간 글로벌 접속자들의 호감도 게임 앱.
        <br />
        7만 가입까지 마케팅비 270만원, CAC 38.5원의 비현실적 지표 기록.
        <br />
        양대 앱 마켓 소셜 최고매출 10권 안착, 중국시장 진출(칭워卿我).
      </>
    ),
    mainImg: "/image/webp/home/BBLBig.webp",
  },
  // {
  //   logSrc: "/image/webp/home/Milk.webp",
  //   tabletMainImg: "/image/webp/home/MilkTablet.webp",
  //   mobileMainImg: "/image/webp/home/MilkMobile.webp",
  //   title: "우유",
  //   date: "2023.01 ~ Coming Soon",
  //   mainDesc: "비급여 메디컬 시장 혁신 서비스",
  //   subDesc: (
  //     <>
  //       고백하자면, 슬로그업은 메디컬 SW로 첫발을 떼었던 회사입니다.
  //       <br />
  //       10주년 되는 23년, 쌓인 역량은 코어근육으로 삼고
  //       <br />
  //       초심으로 돌아가려 합니다.
  //       <br />
  //       비급여 메디컬 시장을 혁신할 영양가 높은 우유, 곧 출시 예정입니다.
  //     </>
  //   ),
  //   tabletSubDesc: (
  //     <>
  //       고백하자면, 슬로그업은 메디컬 SW로 첫발을 떼었던 회사입니다.
  //       <br />
  //       10주년 되는 23년, 쌓인 역량은 코어근육으로 삼고 초심으로 돌아가려
  //       합니다.
  //       <br />
  //       비급여 메디컬 시장을 혁신할 영양가 높은 우유, 곧 출시 예정입니다.
  //     </>
  //   ),
  //   mobileSubDesc: (
  //     <>
  //       고백하자면, 슬로그업은 메디컬 SW로 첫발을 떼었던 회사입니다.
  //       <br />
  //       10주년 되는 23년, 쌓인 역량은 코어근육으로 삼고 초심으로 돌아가려
  //       합니다.
  //       <br />
  //       비급여 메디컬 시장을 혁신할 영양가 높은 우유, 곧 출시 예정입니다.
  //     </>
  //   ),
  //   mainImg: "/image/webp/home/MilkBig.webp",
  // },
  // {
  //   logSrc: "/image/webp/home/BOOST.webp",
  //   tabletMainImg: "/image/webp/home/Main-BOOST.webp",
  //   mobileMainImg: "/image/webp/home/Main-BOOST.webp",
  //   title: "부스트리",
  //   date: "2023.01 ~ Coming Soon",
  //   mainDesc: "비급여 메디컬 시장 혁신 서비스",
  //   link: "comming",
  //   subDesc: (
  //     <>
  //       비급여 병원 업무에 혁신을 불러올 원스탑 B2B SaaS 솔루션.
  //       <br />
  //       피부과를 시작으로 코디네이팅, 리텐션 관리 효율 최적화 제공.
  //       <br />
  //       매출도 서비스도 튼튼히 자라나도록, 23년 2분기 부스트리 출시.
  //     </>
  //   ),
  //   tabletSubDesc: (
  //     <>
  //       비급여 병원 업무에 혁신을 불러올 원스탑 B2B SaaS 솔루션.
  //       <br />
  //       피부과를 시작으로 코디네이팅, 리텐션 관리 효율 최적화 제공.
  //       <br />
  //       매출도 서비스도 튼튼히 자라나도록, 23년 2분기 부스트리 출시.
  //     </>
  //   ),
  //   mobileSubDesc: (
  //     <>
  //       비급여 병원 업무에 혁신을 불러올 원스탑 B2B SaaS 솔루션.
  //       <br />
  //       피부과를 시작으로 코디네이팅, 리텐션 관리 효율 최적화 제공.
  //       <br />
  //       매출도 서비스도 튼튼히 자라나도록, 23년 2분기 부스트리 출시.
  //     </>
  //   ),
  //   mainImg: "/image/webp/home/Main-BOOST.webp",
  // },
];
