import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { navigation } from "../../constants/ref";
import { color, cssTemplate, media } from "../../constants/styles";
import { ReactComponent as Close } from "../../svgs/white-close.svg";
import Responsable from "../Responsable";
interface MobileMenuProps {
  buttonHandler: (e?: React.MouseEvent) => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ buttonHandler }) => {
  const router = useLocation();

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.removeAttribute("style");
    };
  }, []);

  return (
    <Container>
      <MobileMenuCloseButtonWrapper onClick={buttonHandler}>
        <Responsable
          tablet={<Close width={40} height={40} />}
          mobile={<Close width={32} height={32} />}
        />
      </MobileMenuCloseButtonWrapper>
      <ul>
        {navigation.menu.map(({ link, label }, index) => {
          return (
            <li
              key={index}
              onClick={buttonHandler}
              style={{
                color: router.pathname === link ? color.GREEN : "#ffffff",
              }}
            >
              <Link to={link}>{label}</Link>
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default MobileMenu;

const Container = styled.div`
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #111111;
  z-index: 60;
  color: white;

  ${media.TABLET} {
    height: 100vh;
    display: block;
    ul {
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: ${() => `84px 0`};

      li {
        padding: ${() => `12px 24px`};

        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 72px;
      }
    }
  }
  ${media.MOBILE} {
    height: 100vh;
    display: block;
    ul {
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 24px 0px;
      width: 100%;

      li {
        padding: ${() => `12px 24px`};
        width: 100%;
        font-size: 40px;
        line-height: 48px;
        font-weight: 700;
        text-align: center;
      }
    }
  }
`;
const MobileMenuCloseButtonWrapper = styled.div`
  position: absolute;
  ${media.TABLET} {
    top: 40px;
    right: 48px;
  }
  ${media.MOBILE} {
    top: 14px;
    right: 14px;
  }
`;
