import routes from "./routes";

/**
 * menu bar
 */
export const navigationState = {
  SCROLL_TO_VALUE_UP: "scrollToValueUp",
};
export const navigation = {
  menu: [
    // {
    //   link: routes.ABOUT,
    //   label: "About",
    // },
    {
      link: routes.HOME,
      label: "Home",
    },
    {
      link: routes.TEAM,
      label: "Team",
    },
    {
      link: routes.RECRUIT,
      label: "Recruit",
    },
    {
      link: routes.BUSINESS,
      label: "Business",
    },
  ],
};

export const services = [
  {
    id: "service-overlay-sgsg",
    subTitle: "홈·오피스 설치관리 솔루션",
    title: "쓱싹",
    link: "SGSG",
    // link: "https://sgsg.slogup.com",
    backgroundSrc: "/image/service/sgsg.jpg",
    mobileBackgroundSrc: "/image/service/sgsg_1.jpg", // FIXME works의 이미지랑 사이즈 같은듯 나중에 정리
  },
  {
    id: "service-overlay-bbl",
    subTitle: "소셜 데이팅 앱",
    title: "봄블링",
    link: "",
    backgroundSrc: "/image/service/bbl.jpg",
    mobileBackgroundSrc: "/image/service/bbl_1.jpg", // FIXME works의 이미지랑 사이즈 같은듯 나중에 정리
  },
  {
    id: "service-overlay-acti",
    subTitle: "러닝 헬스케어 서비스",
    title: "액티",
    link: "",
    backgroundSrc: "/image/service/acti.jpg",
    mobileBackgroundSrc: "/image/service/acti_1.jpg", // FIXME works의 이미지랑 사이즈 같은듯 나중에 정리
  },
];

export const supports = [
  {
    title: "견적부터 계약까지\n온라인으로 다이렉트하게!",
    subTitle: "차량 렌트 서비스",
    serviceName: "SK렌터카 다이렉트",
    key: "SKRANTERCAR",
    backgroundSrc: "/image/support/works/skdirect.jpg",
    mobileBackgroundSrc: "/image/support/skdirect_1.jpg",
  },
  {
    title: "서랍속에서 민팃속으로,\n중고폰 팔땐, 중고폰 세탁!",
    subTitle: "비대면 인공지능 중고폰 판매 서비스",
    serviceName: "민팃",
    key: "MINTIT",
    backgroundSrc: "/image/support/works/mintit.jpg",
    mobileBackgroundSrc: "/image/support/mintit_1.jpg",
  },
  {
    title: "검증된 데이터로\nA부터 Z까지 쉽고 간편하게",
    subTitle: "수입차 차량 관리 서비스",
    serviceName: "더 카펫",
    key: "CARPET",
    backgroundSrc: "/image/support/works/carpet.jpg",
    mobileBackgroundSrc: "/image/support/carpet_1.jpg",
  },
  // {
  //   title: "쉽고 편리한 전기차 충전\nEV 충전 맛집 에버온",
  //   subTitle: "편리한 전기차 충전 서비스",
  //   serviceName: "에버온",
  //   key: "",
  //   backgroundSrc: "/image/support/carpet.jpg",
  // },
  {
    title: "우리동네의 또다른 재발견\n함께 찾는 즐거움과 보상까지 Plus",
    subTitle: "공유 킥보드 기반 앱테크 서비스",
    serviceName: "플러스팟",
    key: "PLUSPOT",
    backgroundSrc: "/image/support/pluspot.jpg",
    mobileBackgroundSrc: "/image/support/pluspot_1.jpg",
    subBackgroundSrc: "/image/support/works/pluspot.jpg", // FIXME 로직 고민
  },
  {
    title: "내 차 관리의 모든것\n타이어픽에서 한 번에",
    subTitle: "차량 부품 장착/배송 서비스",
    serviceName: "타이어픽",
    key: "TIREPICK",
    backgroundSrc: "/image/support/works/tirepick.jpg",
    mobileBackgroundSrc: "/image/support/tirepick_1.jpg",
  },
  {
    title: "탁송과 물류를 모두가\n더 쉽고 더 이롭게",
    subTitle: "트럭 차량 관리 서비스",
    serviceName: "오픈마일",
    key: "OPENMILE",
    backgroundSrc: "/image/support/works/openmile.jpg", // FIXME 고민 같은 사이즈 인거 같음
    mobileBackgroundSrc: "/image/support/openmile_1.jpg",
  },
  {
    title: "수소차 충전소의 불편함은\n하이앤드로 깔끔하게!",
    subTitle: "수소차 충전 서비스",
    serviceName: "하이엔드",
    key: "HIGHEND",
    backgroundSrc: "/image/support/works/hiend.jpg", // FIXME 고민 같은 사이즈 인거 같음
    mobileBackgroundSrc: "/image/support/hiend_1.jpg",
  },
];

export const whoAreSlogers = [
  {
    title: "실력은 타협하지 않습니다.\n각자의 장점으로 매일 성장합니다.",
    mobileTitle: "실력은 타협하지 않습니다\n각자의 장점으로 매일\n성장합니다",
  },
  {
    title: "가치있는 일의 실현을 위해\n대담하게 생각하고 과감하게 행동합니다.",
    mobileTitle:
      "가치있는 일의 실현을 위해\n대담하게 생각하고 과감하게\n행동합니다",
  },
  {
    title:
      "솔직하고 명확하게 소통합니다.\n이상한 게 있으면 이상하다고 말합니다.",
    mobileTitle:
      "솔직하고 명확하게 소통합니다\n이상한 게 있으면 이상하다고\n말합니다",
  },
];
export const interviews = [
  { blank: true },

  {
    interview:
      "일 잘하는 좋은 사람들이 모여있는 곳. 슬로그업은 겉치레를 싫어하고 실력과 내실을 중요히 여겨요. 업무적으로 편견이나 제약을 두지 않는 도전정신이 맘에 들었고, 내가 하고 싶은 것들을 모두 다 해보며 재밌게 일할 수 있어요.",
    name: "Scott",
    team: "마케팅 챕터",
    profileUrl: "/image/webp/profile/scott.webp",
  },
  {
    interview:
      "회의할 때 자유롭게 자신의 생각을 얘기하다 보면 같은 주제에 모두가 깊숙이 빠져, 빠른 시간 내 더 좋은 해결 방안을 찾게 돼요. 다들 자신이 맡은 프로덕트의 책임감과 애정이 크다보니, 각 파트 담당자들이 할 수 있는 최선의 방법을 강구하고 의논하기 때문에 결과물의 완성도가 높아 업무 만족도도 덩달아 높아져요.",
    name: "Snow",
    team: "프로덕트 매니지먼트 챕터",
    profileUrl: "/image/webp/profile/snow.webp",
  },
  {
    interview:
      "프로덕트 빌딩부터 출시까지 디자이너의 생각과 논리를 다양한 시스템을 이용해 펼쳐볼 수 있기 때문에, 다양한 도메인에 도전하고 동시에 프로덕트 디자이너로서 성장할 수 있는 곳이에요. 뛰어난 슬로거들과 함께 재밌는 프로덕트 만들어 보실래요?",
    name: "Harry",
    team: "프로덕트 디자인 챕터",
    profileUrl: "/image/webp/profile/harry.webp",
  },
  {
    interview:
      "틀에 박힌 업무 프로세스가 아닌 본인의 역량 하에 다양한 시도를 해볼 수 있어요. 타 개발자 코드와 비교하고 성장하며 개발 기술의 깊이를 늘릴 수 있고, 그동안 습득한 지식을 활용하여 내것으로 만들며 개발기술의 넓이를 늘릴 수 있기 때문에, 개발자로서 폭넓은 경험을 할 수 있다는 점은 가장 큰 장점 중에 장점!",
    name: "Jesse",
    team: "프론트엔드 챕터",
    profileUrl: "/image/webp/profile/jessy.webp",
  },
  {
    interview:
      "직무와 상관없이 서로 좋은 아이디어를 공유하며 프로덕트의 개선안을 편안하게 제안할 수 있고, 영어 닉네임 호칭을 쓰기 때문에 업무 분위기가 수평적이고 자유로워요! 또한 단기적인 시간에 프로젝트의 처음과 끝을 경험 할 수 있다보니, 다양한 외부 채널과 소통 할 수 있는 경험을 쌓을 수 있을 거예요.",
    name: "Hero",
    team: "프로덕트 매니지먼트 챕터",
    profileUrl: "/image/webp/profile/hero.webp",
  },
  {
    interview:
      "아침에 눈을 뜨고, 슬로그업에 출근 하는것이 괴롭지 않아요! 누군가에 의해 다듬어져 만들어진 보석같은 곳에서 일하는 것도 좋지만, 저는 원석을 직접 더 멋있고 아름답게 다듬고 깎아낼 수 있는 곳에서 일하고 싶었어요. 슬로그업은 제게 원석같은 곳입니다!",
    name: "Mickey",
    team: "경영기획 챕터",
    profileUrl: "/image/webp/profile/mickey.webp",
  },
  {
    interview:
      "슬로그업의 최대 장점은 자유로운 분위기 안에서 직원들의 업무능력을 향상시킬 수 있도록 아낌없이 지원해 주고, 사내복지를 꾸준히 개선하며 발전시켜나가는 점이에요!",
    name: "Hazel",
    team: "경영기획 챕터",
    profileUrl: "/image/webp/profile/hazel.webp",
  },
  {
    interview:
      "슬로그업에는 사내 사람으로 인한 스트레스는 없고, 모든 직원이 서로서로 친하기 때문에 불편한 점이나 개선사항에 대해 피드백을 받을 수 있어요. 또한 파트 단합일인 파단일과 휴게실, 헬스장등의 복지로 인해 휴식과 함께 친해지고 정보를 공유할 수 있는 시간이 있어 좋은 것 같아요!",
    name: "Regan",
    team: "프론트엔드 챕터",
    profileUrl: "/image/webp/profile/regan.webp",
  },
  {
    interview:
      "동료의 고민이라면 언제든 함께 고민해주는 열정넘치는 멋있는 사람들이 정말 많아요. 서로 배울 수 있는 편안한 분위기! 항상 감사하고 있어요. 팀워크 덕분에 프로젝트의 어려움도 함께 이겨나갈 수 있고, 더 성장한 나를 만날 수 있을거에요.",
    name: "Ian",
    team: "백엔드 챕터",
    profileUrl: "/image/webp/profile/ian.webp",
  },
  {
    interview:
      "슬로그업에서는 서로가 소통할 때 장애물이 있다는 느낌이 들지 않고 언제든지 원할때 누구나와 편하게 소통할 수 있는 점이 강점인것 같아요! 같은 목표를 바라보더라도 누구는 a라는 길을, 다른 누구는 b라는 길을 볼 수 있지만, 서로가 소통하다보면 각자 a와 b길을 바라보는 이유가 이해되고 더 나은 방향을 함께 찾아나갈 수 있어요",
    name: "Are",
    team: "백엔드 챕터",
    profileUrl: "/image/webp/profile/are.webp",
  },
  {
    interview:
      "실력, 책임감, 발전의지 가득한 프로들과 함께할 수 있다는 점이 슬로그업의 핵심 장점입니다! 언제나 새로운 신기술에 열망하는 슬로그업에서 함께 일하실래요?",
    name: "Bliss",
    team: "백엔드 챕터",
    profileUrl: "/image/webp/profile/bliss.webp",
  },
  {
    interview:
      "자유로운 분위기와 1시간 단위로 쓸 수 있는 시차, 그리고 실력있는 선배 개발자들이 이끌어주는 슬로그업! 다양한 프로젝트를 경험하며 빠른 시일에 실력을 키울 수 있는 곳이라 장담합니다!",
    name: "Evan",
    team: "백엔드 챕터",
    profileUrl: "/image/webp/profile/evan.webp",
  },
  { blank: true },
];

export const welfare = [
  {
    key: 0,
    imageUrl: "/image/welfare/1.jpg",
    title: "기본에 충실합니다",
    description: "일에 온전히 집중할 수 있게 업무환경을\n돌보고 개선합니다.",
    mobileDescription:
      "일에 온전히 집중할 수 있게\n업무환경을 돌보고 개선합니다.",
    list: [
      [
        {
          title: ["100% 실력기반, 합리적 연봉평가"],
          subtitle: "",
        },
        {
          title: ["닉네임 호칭, 수평적 토론문화"],
          subtitle: "",
        },
        {
          title: ["도서/교육 구입 등 개인성장 지원"],
          subtitle: "",
        },
        {
          title: ["청년내일채움공제 등 정부 연계지원"],
          subtitle: "",
        },
      ],
      [
        {
          title: ["4대 보험등 기본사항 당연히 지원"],
          subtitle: "",
        },
        {
          title: ["웰컴 KIT와 굿즈"],
          subtitle: "",
        },
        {
          title: ["맥북 등 최고급 업무장비"],
          subtitle: "",
        },
        {
          title: ["듀얼 모니터 / 시디즈 의자"],
          subtitle: "",
        },
        {
          title: ["사내 문방구 / 미니 도서관"],
          subtitle: "",
        },
      ],
    ],
  },
  {
    key: 1,
    imageUrl: "/image/welfare/2.jpg",
    title: "헌신에 보답합니다",
    description: "서로를 위한 서로의 헌신을 감사히 여기고\n최대한 보답합니다.",
    mobileDescription:
      "서로를 위한 서로의 헌신을 감사히 여기고\n최대한 보답합니다.",
    list: [
      [
        { title: ["인센티브 및 스톡옵션 제도"], subtitle: "" },
        { title: ["장기근속자 특별휴가 및 포상"], subtitle: "" },
        { title: ["연차 차감 없는 오버워크 보상휴가"], subtitle: "" },
        { title: ["직무별 유연출근제"], subtitle: "" },
      ],
      [
        { title: ["불가피한 야근시 카카오T안심귀가"], subtitle: "" },
        { title: ["야근시 식대 지원"], subtitle: "" },
        { title: ["명절/경조사비 지원"], subtitle: "" },
        { title: ["주차비 지원"], subtitle: "" },
      ],
    ],
  },
  {
    key: 2,
    imageUrl: "/image/welfare/3.jpg",
    title: "쉴 때는 푹 쉽니다",
    description: "컨디션 관리 위한 강약조절\n휴식의 중요성을 놓치지 않습니다.",
    mobileDescription:
      "컨디션 관리 위한 강약조절\n휴식의 중요성을 놓치지 않습니다.",
    list: [
      [
        { title: ["1시간 10분의 여유로운 점심시간"], subtitle: "" },
        { title: ["파트 액티비티데이(조기퇴근/문화회식)"], subtitle: "" },
        { title: ["월 적립 가능한 팀 회식비"], subtitle: "" },
        { title: ["운동 공간 (탁구대/헬스장)"], subtitle: "" },
        { title: ["생일 케이크 및 반차"], subtitle: "" },
      ],
      [
        { title: ["평화로운 휴게실 / 안마의자"], subtitle: "" },
        { title: ["다양한 간식 / 라면"], subtitle: "" },
        { title: ["최고급 원두 커피 및 머신"], subtitle: "" },
        { title: ["무제한 맥주 냉장고"], subtitle: "" },
        { title: ["게임기 (PlayStation)"], subtitle: "" },
      ],
    ],
  },
];

export const footer = {
  linkList: [
    {
      title: "Github",
      link: "https://github.com/teamslogup",
    },
    // {
    //   title: "NPM",
    //   link: "https://www.npmjs.com/search?q=slogup",
    // },
    // {
    //   title: "Behance",
    //   link: "https://www.behance.net/design58c8",
    // },
    {
      title: "Blog",
      link: "https://blog.naver.com/team-slogup",
    },
    {
      title: "Brunch",
      link: "https://brunch.co.kr/@team-slogup#magazines",
    },
    {
      title: "Press",
      link: "https://flipboard.com/@slogup",
    },
  ],
};

export const joinInfo = [
  [
    {
      arrayKey: "product",
      teamName: "프로덕트 매니지먼트 챕터",
      title: "프로덕트 매니저",
      description:
        "전체를 조망하며 프로젝트를 리딩합니다. 제대로 기획하기 위해 오래 사용자를 연구하고, 소통의 허브 역할을 합니다.",
      mobileDescription:
        "전체를 조망하며 프로젝트를 리딩합니다. 제대로 기획하기 위해 오래 사용자를 연구하고, 소통의 허브 역할을 합니다.",
      link: "https://slogup.oopy.io/11832a85-1823-480c-be08-f708fe668871",
    },
    {
      arrayKey: "design",
      teamName: "프로덕트 디자인 챕터",
      title: "프로덕트 디자이너",
      description:
        "예쁜 UI를 넘어 경험이 유려할 수 있게 만듭니다. 포괄적으로 공부하고 총체적으로 고민한 후 사용자의 경험을 그립니다.",
      mobileDescription:
        "예쁜 UI를 넘어 경험이 유려할 수 있게 만듭니다. 포괄적으로 공부하고 총체적으로 고민한 후 사용자의 경험을 그립니다.",
      link: "https://slogup.oopy.io/270c768b-3433-449e-8015-cdff4e0b884b",
    },
  ],
  [
    {
      arrayKey: "frontend",
      teamName: "프론트엔드 챕터",
      title: "클라이언트 개발자",
      description:
        "유저와 구조를 연결합니다. 늘 새롭게 공부하고, 백엔드와 디자인 사이에서 논리와 감각이 대립하지 않게 완충합니다.",
      mobileDescription:
        "유저와 구조를 연결합니다. 늘 새롭게 공부하고, 백엔드와 디자인 사이에서 논리와 감각이 대립하지 않게 완충합니다.",
      link: "https://slogup.oopy.io/0bda8d9c-a0cb-4b0b-903d-db050bb68ec1",
    },
    {
      arrayKey: "backend",
      teamName: "백엔드 챕터",
      title: "서버 개발자",
      description:
        "비즈니스 요구사항을 개발 로직으로 치환합니다. 구조를 쌓아 올리고, 흔들리지 않는지 확인하기 위한 도구를 만듭니다.",
      mobileDescription:
        "비즈니스 요구사항을 개발 로직으로 치환합니다. 구조를 쌓아올리고, 흔들리지 않는지 확인하기 위한 도구를 만듭니다.",
      link: "https://slogup.oopy.io/c42d89ab-6e4d-4677-bb1e-f46bf0adf38f",
    },
  ],
  [
    {
      arrayKey: "marcketing",
      teamName: "마케팅 챕터",
      title: "퍼포먼스 마케터",
      description:
        "서비스를 세상에 알립니다. 돈으로 일하지 않게 연구하고 실험 하며, 시키는대로 안 하고 플랫폼을 변칙적으로 활용합니다.",
      mobileDescription:
        "서비스를 세상에 알립니다. 돈으로 일하지 않게 연구하고 실험하며, 시키는대로 안 하고 플랫폼을 변칙적으로 활용합니다.",
      link: "https://slogup.oopy.io/f97e1250-9161-459a-89ec-8fadaa8f5081",
    },
    {
      arrayKey: "operation",
      teamName: "서비스 운영 챕터",
      title: "서비스 운영 전문가",
      description:
        "비즈니스가 앞으로 가도록 엔진의 역할을 합니다. 사용자와 공급자를 모두 만나며 개선점을 가장 먼저 알아냅니다.",
      mobileDescription:
        "비즈니스가 앞으로 가도록 엔진의 역할을 합니다. 사용자와 공급자를 모두 만나며 개선점을 가장 먼저 알아냅니다.",
      link: "https://slogup.oopy.io/cb71f648-2d43-4142-a3c6-d665eab2204f",
    },
  ],
  [
    {
      arrayKey: "person",
      teamName: "경영기획 챕터",
      title: "HR 전문가",
      description:
        "인재를 확보하고 성장기반을 다집니다. 회사와 구성원에 관한 이해를 바탕으로 뭐가 필요한지 예리하게 알아챕니다.",
      mobileDescription:
        "인재를 확보하고 성장기반을 다집니다. 회사와 구성원에 관한 이해를 바탕으로 뭐가 필요한지 예리하게 알아챕니다.",
      link: "https://slogup.oopy.io/971d87fe-678d-4133-b1c3-95a9c43bb015",
    },
    {
      arrayKey: "money",
      teamName: "경영기획 챕터",
      title: "재무 전문가",
      description:
        "내외를 살피고 리스크를 분석합니다. 잠재적 위험에 대비하고 안정성을 확보하기 위해 미래를 계획합니다.",
      mobileDescription:
        "내외를 살피고 리스크를 분석합니다. 잠재적 위험에 대비하고 안정성을 확보하기 위해\n 미래를 계획합니다.",
      link: "https://slogup.oopy.io/afe8da1d-36b5-4041-bb20-adcb087f0d5e",
    },
  ],
];
export const yesLink = "https://forms.gle/LeipjqzTtYzBzsNE9";

export const workPlace = [
  {
    pcImgUrl: "/image/webp/team/place01-Pc.webp",
    mobileImgUrl: "/image/webp/team/place01-Pc.webp",
  },
  {
    pcImgUrl: "/image/webp/team/place02-Pc.webp",
    mobileImgUrl: "/image/webp/team/place02-Pc.webp",
  },
  {
    pcImgUrl: "/image/webp/team/place03-Pc.webp",
    mobileImgUrl: "/image/webp/team/place03-Pc.webp",
  },
  {
    pcImgUrl: "/image/webp/team/place04-Pc.webp",
    mobileImgUrl: "/image/webp/team/place04-Pc.webp",
  },
  {
    pcImgUrl: "/image/webp/team/place05-Pc.webp",
    mobileImgUrl: "/image/webp/team/place05-Pc.webp",
  },
  {
    pcImgUrl: "/image/webp/team/place06-Pc.webp",
    mobileImgUrl: "/image/webp/team/place06-Pc.webp",
  },
  {
    pcImgUrl: "/image/webp/team/place07-Pc.webp",
    mobileImgUrl: "/image/webp/team/place07-Pc.webp",
  },
];
