import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { footer } from "../../constants/ref";
import { cssTemplate, grid, media } from "../../constants/styles";
import { ReactComponent as CopyrightIcon } from "../../svgs/copyright.svg";
import { maxWidth } from "../sections/shared/styled";

interface FooterProps {}

const Footer: React.FC<FooterProps> = (props) => {
  return (
    <StyledFooter>
      <div>
        <div style={{ flex: "auto" }}>
          <LinkList>
            {footer.linkList.map(({ title, link }, index) => {
              return (
                <li key={index}>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {title}
                  </a>
                </li>
              );
            })}
          </LinkList>
          <Copyright>
            <StyledCopyrightIcon />
            Slogup. All Rights Reserved
          </Copyright>
          <Address>
            <span>서울특별시 마포구 성지3길 74 장석빌딩 (주)슬로그업</span>
            <span>74, Seongji 3-gil, Mapo-gu</span>
            <span>Seoul, Republic of Korea</span>
          </Address>
        </div>

        <ContactList>
          <li>
            <span>Career</span>
            <span>
              <a href="mailto:recruit@slogup.com">recruit@slogup.com</a>
            </span>
          </li>
          <li>
            <span>Business</span>
            <span>
              <a href="mailto:operation@slogup.com">operation@slogup.com</a>
            </span>
          </li>
          <li>
            <span>PR</span>
            <span>
              <a href="mailto:admin@slogup.com">admin@slogup.com</a>
            </span>
          </li>
        </ContactList>
      </div>
    </StyledFooter>
  );
};

export default Footer;

const StyledFooter = styled.footer`
  background: #111111;
  border-top: 2px solid #1e1e1e;

  position: relative;
  color: #ffffff;
  display: flex;
  justify-content: center;
  width: 100vw;
  padding: 120px;

  & > div {
    ${maxWidth}
    width:100%;
    display: flex;
    padding: 0 48px;
    flex-wrap: nowrap;
  }

  ${media.TABLET} {
    padding: 56px 48px;
    display: block;

    & > div {
      gap: 48px;
      flex-direction: column;
      width: 100%;
      display: flex;
      padding: 0px;
      flex-wrap: nowrap;
    }
  }

  ${media.MOBILE} {
    padding: 40px 24px 60px;

    & > div {
      gap: 48px;
      flex-direction: column;
      width: 100%;
      display: flex;
      padding: 0px;
      flex-wrap: nowrap;
    }
  }
`;

const LinkList = styled.ul`
  display: flex;
  font-weight: 700;
  margin-bottom: 16px;
  font-size: 24px;
  li {
    position: relative;
    padding: ${() => `0 40px`};
    &:after {
      content: "";
      display: inline-block;
      background: #ffffff;
      width: 1px;
      height: 16px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  ${media.TABLET} {
    gap: 16px;
    margin-bottom: 24px;
    font-size: 17px;
    flex-direction: column;
    li {
      position: relative;
      padding: ${() => `0`};
      &:after {
        display: none;
      }
    }
  }

  ${media.MOBILE} {
    gap: 16px;
    margin-bottom: 24px;
    font-size: 17px;
    flex-direction: column;
  }
`;

const Copyright = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  margin-bottom: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;

  height: 24px;
  ${media.TABLET} {
    font-size: 16px;
    margin-bottom: 48px;
  }

  ${media.MOBILE} {
    font-size: 16px;
    margin-bottom: 48px;
  }
  color: #949494;
`;

const StyledCopyrightIcon = styled(CopyrightIcon)`
  width: 20px;
  height: 20px;
  ${media.TABLET} {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
  ${media.MOBILE} {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
  stroke: #949494;
`;

const Address = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;
  ${media.TABLET} {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    gap: 6px;
  }
  ${media.MOBILE} {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    gap: 6px;
  }
`;

const ContactList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-weight: 500;
  line-height: 1.5;

  > li {
    display: flex;
    flex-direction: column;
    gap: 2px;
    > span {
      &:nth-child(1) {
        color: #949494;
        font-size: 14px;
      }
      &:nth-child(2) {
        font-size: 20px;
      }
    }
  }

  ${media.TABLET} {
    gap: 20px;

    > li {
      gap: 2px;
      > span {
        &:nth-child(1) {
          font-size: 14px;
        }
        &:nth-child(2) {
          font-size: 18px;
        }
      }
    }
  }
  ${media.MOBILE} {
    gap: 20px;
    > li {
      gap: 2px;
      > span {
        &:nth-child(1) {
          font-size: 14px;
        }
        &:nth-child(2) {
          font-size: 18px;
        }
      }
    }
  }
`;
