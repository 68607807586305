import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { navigation } from "../../constants/ref";
import routes from "../../constants/routes";

import { color, cssTemplate, media } from "../../constants/styles";
import { maxWidth } from "../sections/shared/styled";

interface MenuProps {}

const Menu: React.FC<MenuProps> = (props) => {
  const router = useLocation();

  return (
    <StyledList>
      {navigation.menu.map(({ link, label }) => (
        <li
          key={link}
          style={{
            color: router.pathname === link ? color.GREEN : "#ffffff",
          }}
        >
          <Link to={link} onClick={() => window.scrollTo(0, 0)}>
            {label}
          </Link>
        </li>
      ))}
      {/* <Indicator
        nowIndex={navigation.menu.findIndex(
          (value) => value.link === (router.pathname || routes.HOME)
        )}
      /> */}
    </StyledList>
  );
};

export default Menu;

const StyledList = styled.ul`
  display: flex;
  justify-content: center;
  gap: 16px;
  position: relative;
  font-weight: 800;
  font-size: 18px;
  line-height: 28px;
  overflow: hidden;

  text-align: center;

  color: #ffffff;

  ${maxWidth}
  width: 100%;
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 0px 0px;
    gap: 7px;
    height: 43px;
    width: 120px;
  }
`;
const nowMenuCss = css`
  color: #b3ff01;
`;
const Indicator = styled.div<{ nowIndex: number }>`
  position: absolute;
  bottom: 0;
  transition: transform 0.3s;
  transition-delay: 0.2s;
  ${nowMenuCss}
  width:80px;
  border-bottom: 1px solid #b3ff01;
  left: 0;

  transform: translate3d(${({ nowIndex }) => 136 * nowIndex + 20}px, 0, 0);
  ${media.TABLET} {
    display: none;
  }
  ${media.MOBILE} {
    display: none;
  }
`;
