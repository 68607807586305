import { ServiceKeys } from "../serviceKeys";

export const serviceDetail = [
  // sgsg
  {
    key: ServiceKeys.SGSG,
    section01: {
      backgroundSrc: "/image/detail/sgsg/sgsg.jpg",
      mobileBackgroundSrc: "/image/detail/sgsg/sgsg1.jpg",
      serviceDescription: [
        "Service Consulting",
        "Branding",
        "UI/UX Design",
        "Mobile Application",
        "Back Office System",
      ],
    },
    section02: {
      overviewDescription:
        "플러스팟은 앱테크 기반 전동 킥보드 무료 충전 서비스를 제공하여 도시미관 및 지역경제 활성화에 공헌하고자 출시되었습니다. 슬로그업이 가지고 있는 전략과 경험을 바탕으로  플러스팟의 서비스가 사용자들에게 편리하게 이용될 수 있도록 우리는 Branding부터 B2C App과 Back Office System을 설계하였습니다.",
    },
    section03: {
      problemDescription:
        "공유킥보드 시장의 규모가 커지면서 전동킥보드 공유서비스는 어느새 또 하나의 이동수단으로 자리 잡게 되었으나, 비거치식 서비스 운영 방식에 따른 무분별한 주·정차로 인해 통행 및 시설 이용 방해, 차도·대중교통 승강장 등의 교통 흐름 방해, 소방시설과 같은 주요 안전시설을 방해하는 사례들이 다수 발생되었습니다.",
      problemImage: [
        "/image/detail/pluspot/problem01.jpg",
        "/image/detail/pluspot/problem02.jpg",
      ],
      goals: [
        {
          title: "마이크로모빌리티 주차는 스테이션에",
          subTitle: "다양한 미션을 통한 스테이션 이용 활성화 도모",
        },
        {
          title: "손쉬운 킥보드 주차와 빌려타기",
          subTitle: "This is where the project objectives are 01",
        },
        {
          title: "다양한 제휴 상점을 통한 지역 경제 활성화",
          subTitle: "This is where the project objectives are 01",
        },
        {
          title: "리워드를 통한 리텐션",
          subTitle: "This is where the project objectives are 01",
        },
      ],
    },
    section04: {
      ux: [
        {
          title: "마이크로모빌리티 주차는 스테이션에",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "손쉬운 킥보드 주차와 빌려타기",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "다양한 제휴 상점을 통한 지역 경제 활성화",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "리워드를 통한 리텐션",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
      ],
      tech: [
        {
          title: "다양한 제휴 상점을 통한 지역 경제 활성화",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "리워드를 통한 리텐션",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
      ],
      imageSrc: "/image/detail/pluspot/tech.jpg",
      mobileImageSrc: "/image/detail/pluspot/tech1.jpg",
    },
    section05: {
      solutionDescription:
        "길거리 방치가 아닌 스테이션에 주차하는것을 유도하며, 주차시에 리워드를 지급하여 스테이션 활용성을 높이는 전략으로 구성하였습니다",
      steps: [
        "플러스팟을 통해\n킥보드 이용",
        "목적지 근처\n스테이션 도달",
        "스테이션에\n주차",
        "주차 시\n리워드 지급",
      ],
    },
    section06: {
      uiUx01: {
        title: "킥보드를 빌려타기",
        description:
          "플러스팟은 길거리에 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해  앱테크 기반 전동 킥보드 무료 충전 서비스를 제공합니다. 슬로그업이 가지고 있는 전략과 경험을 바탕으로  플러스팟의 서비스가 사용자들에게 편리하게 이용될 수 있도록 우리는 Branding부터 B2C App과 Back Office System을 설계하였습니다.",
        imageSrc01: "/image/detail/pluspot/pluspot-ui01.jpg",
        imageSrc02: "/image/detail/pluspot/pluspot-ui02.jpg",
        imageSrc03: "/image/detail/pluspot/pluspot-ui03.jpg",
      },
    },
    section07: {
      uiUx02: {
        title: "리워드 지급",
        description:
          "플러스팟은 길거리에 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해  앱테크 기반 전동 킥보드 무료 충전 서비스를 제공합니다. 슬로그업이 가지고 있는 전략과 경험을 바탕으로  플러스팟의 서비스가 사용자들에게 편리하게 이용될 수 있도록 우리는 Branding부터 B2C App과 Back Office System을 설계하였습니다.",
        imageSrcs: [
          "/image/detail/pluspot/pluspot-ui04.jpg",
          "/image/detail/pluspot/pluspot-ui05.jpg",
          "/image/detail/pluspot/pluspot-ui06.jpg",
          "/image/detail/pluspot/pluspot-ui07.jpg",
        ],
      },
    },
  },
  // skdirect
  {
    key: ServiceKeys.SKRANTERCAR,
    section01: {
      backgroundSrc: "/image/detail/skdirect/mainPc.png",
      mobileBackgroundSrc: "/image/detail/skdirect/mainMobile.png",
      tableBackgroundSrc: "/image/detail/skdirect/mainTablet.png",
      serviceDescription: [],
    },
    section02: {
      overviewDescription:
        "SK렌터카 다이렉트는 견적부터 계약, 최종 딜리버리까지 비대면으로 렌터카 서비스를 제공 합니다. 기존 SP(영업사원) 중심에서 벗어나\nTotal  Online Service를 구축하기 위해 슬로그업은 SK렌터카와 첫 걸음부터 함께 했습니다.\n최초 브레인스토밍, 서비스 기획부터 시작해 라이브커머스 동시접속 60만명에 육박하는 서비스로 거듭 나기까지,\n슬로그업은 서비스 구축과 운영업무를 수행하면서 서비스 기획 및 B2C, Back Office System Design, 그리고 최종 Front 개발까지 담당 했습니다.",
      mobileDesecription:
        "SK렌터카 다이렉트는 견적부터 계약, 최종 딜리버리까지 비대면으로 렌터카 서비스를 제공 합니다. 기존\nSP(영업사원) 중심에서 벗어나 Total  Online Service를 구축하기 위해 슬로그업은 SK렌터카와 첫 걸음부터\n함께 했습니다.최초 브레인스토밍, 서비스 기획부터 시작해 라이브커머스 동시접속 60만명에 육박하는 서\n비스로 거듭 나기까지, 슬로그업은 서비스 구축과 운영업무를 수행하면서 서비스 기획 및 B2C, Back Office \nSystem Design, 그리고 최종 Front 개발까지 담당 했습니다.",
    },
    section03: {
      problemDescription:
        "기존 SK렌터카 고객은 나에게 맞는 정확한 견적을 내기 힘들었습니다.\n기존 온라인 서비스에서는 표준화된 견적 체크만 가능했고, 개인정보에 기반한 정확한 견적은 SP(영업사원)을 통해야만 했습니다.\n오프라인 중심 시장에서 대고객 서비스 및 마케팅은 SP에 따라 불균등하게 적용 되었습니다. 이에 따른 불필요한 비용 지출 역시 고민이었습니다.\n고객은 정확한 견적이 필요했고, SK렌터카는 분산된 마케팅 에너지를 집중해야 했습니다.",
      mobileDesecription:
        "기존 SK렌터카 고객은 나에게 맞는 정확한 견적을 내기 힘들었습니다. 기존 온라인 서비스에서는 표준화된 견\n적 체크만 가능했고, 개인정보에 기반한 정확한 견적은 SP(영업사원)을 통해야만 했습니다. 오프라인 중심 시\n장에서 대고객 서비스 및 마케팅은 SP에 따라 불균등하게 적용 되었습니다. 이에 따른 불필요한 비용 지출 역\n시 고민이었습니다. 고객은 정확한 견적이 필요했고, SK렌터카는 분산된 마케팅 에너지를 집중해야 했습니다.",

      problemImage: [
        "/image/detail/skdirect/skDirect-problem-1.png",
        "/image/detail/skdirect/skDirect-problem-2.png",
      ],

      goals: [
        {
          title: "정확한 견적",
          subTitle: "연령, 신용도 등 개인정보에 기반한 정확한 견적 산출",
          mobileDesecription:
            "연령, 신용도 등 개인정보에 기반한 정확한 견\n적 산출",
        },
        {
          title: "언제 어디서나 편리한 접근성",
          subTitle: "PC 뿐 아니라 모바일 환경에서도 접근 가능한 플랫폼 구성",
          mobileDesecription:
            "PC 뿐 아니라 모바일 환경에서도 접근 가능한\n플랫폼 구성",
        },
        {
          title: "견적, 계약, 딜리버리까지 온라인 솔루션 제공",
          subTitle: "단순 견적, 비교 견적, 계약 및 배송 담당자 확인까지 한번에",
          mobileDesecription:
            "단순 견적, 비교 견적, 계약 및 배송 담당자 확\n인까지 한번에",
        },
        {
          title: "중앙 집중식 마케팅 커뮤니케이션",
          subTitle:
            "사업부 중심 시즈널한 프로모션 및 이벤트로 균일한 혜택 제공",
          mobileDesecription:
            "사업부 중심 시즈널한 프로모션 및 이벤트로 균\n일한 혜택 제공",
        },
      ],
    },
    section04: {
      ux: [
        {
          title: "신속 보다 정확",
          subTitle:
            "상품 접근 시 본인인증을 사전에 진행하고,\n이를바탕으로 정확한 정보를 열람할 수 있도록 구성",
          mobileDesecription:
            "상품 접근 시 본인인증을 사전에 진행하고, 이를\n바탕으로 정확한 정보를 열람할 수 있도록 구성",
        },
        {
          title: "Full Responsive Layout",
          subTitle:
            "모든 디바이스에서 최적화된 정보 및 사용자 경험을 제공하기 위해\n반응형 레이아웃 도입",
          mobileDesecription:
            "모든 디바이스에서 최적화된 정보 및 사용자 경\n험을 제공하기 위해 반응형 레이아웃 도입",
        },
        {
          title: "복잡한 견적 옵션을 쉽고 간편하게 선택하도록 유도",
          subTitle:
            "스텝 바이 스텝으로 복잡해 보이는 견적 옵션들을 최대한 친절하게\n설명하고 편리하게 접근할 수 있도록 UX 구성",
          mobileDesecription:
            "스텝 바이 스텝으로 복잡해 보이는 견적 옵션들\n을 최대한 친절하게 설명하고 편리하게 접근할\n수 있도록 UX 구성",
        },
        {
          title: "다양한 마케팅 도구 구성",
          subTitle:
            "띠배너, 메인배너, 다양한 형태의 기획전, 이벤트 등 고객에게\n접근 가능한 다양한 루트 세팅",
          mobileDesecription:
            "띠배너, 메인배너, 다양한 형태의 기획전, 이벤트\n등 고객에게 접근 가능한 다양한 루트 세팅",
        },
      ],
      tech: [
        {
          title: "인프라 시스템과 유기적인 연동",
          subTitle:
            "SK렌터카의 인프라 시스템을 통해 개인정보 조회 및\n정확한 차량 정보, 견적/계약 시스템 연동",
          mobileDesecription:
            "SK렌터카의 인프라 시스템을 통해 개인정보 조\n회 및 정확한 차량 정보, 견적/계약 시스템 연동",
        },
        {
          title: "3rd Party 연동 확대",
          subTitle:
            "외부 마케팅 도구 및 시스템 분석 도구 등 신속하게 도입 가능한\n외부 솔루션을 도입해서 효율적으로 기능 구현",
          mobileDesecription:
            "외부 마케팅 도구 및 시스템 분석 도구 등 신속하\n게 도입 가능한 외부 솔루션을 도입해서 효율적\n으로 기능 구현",
        },
      ],
      imageSrc: "/image/detail/skdirect/tech.jpg",
      mobileImageSrc: "/image/detail/skdirect/tech1.jpg",
    },
    section05: {
      solutionDescription:
        "정확한 견적을 제공하는데 집중해서 고객에게 신뢰를 주고,\n최종 계약 및 차량 인도까지 온라인으로 해결 가능한 Total Online Solution을 구성 합니다.",
      mobileDesecription:
        "정확한 견적을 제공하는데 집중해서 고객에게 신뢰를\n주고,  최종 계약 및 차량 인도까지 온라인으로 해결 \n가능한 Total Online Solution을 구성 합니다.",
      steps: [
        "상품 접근 시\n사전 본인인증",
        "정확하고 간편한\n견적 시스템",
        "온라인으로\n계약 완료 및 배송 조회",
        "다양한 마케팅툴로\n충성/신규 고객 관리",
      ],
    },
    section06: {
      uiUx01: {
        title: "다양한 기획전으로 구성된 메인 페이지",
        description:
          "다양한 카테고리의 차량들과 실시간으로 업데이트되는 실시간 재고물량까지 다양한 방식의 디스플레이로 고객과 커뮤니케이션 합니다.\n여러 방식의 리스트와 영상물에 특화된 쇼츠, 특정 시간대에만 특별가로 판매되는 타임딜 까지 이 모든 화면은 Back Office System으로\n마케팅 담당자가 직접 관리할 수 있습니다.",
        mobileDesecription:
          "다양한 카테고리의 차량들과 실시간으로 업데이트되는 실시간 재고물량까지 다양한 방식의 디스플레이로 고\n객과 커뮤니케이션 합니다. 여러 방식의 리스트와 영상물에 특화된 쇼츠, 특정 시간대에만 특별가로 판매되는 \n타임딜 까지 이 모든 화면은 Back Office System으로 마케팅 담당자가 직접 관리할 수 있습니다.",
        imageSrc01: "/image/detail/skdirect/mobileEx1.png",
        imageSrc02: "/image/detail/skdirect/mobileEx2.png",
        imageSrc03: "/image/detail/skdirect/pcEx.png",
        imageSrc04: "/image/detail/skdirect/pcEx2.png",
        positionInfo: {
          pc: {
            top: 100,
            left: 113,
            width: 703,
            height: 906,
          },
          tablet: {
            top: 160,
            left: 229,
            width: 311,
            height: 674,
          },
          mobile: {
            top: 80,
            left: 32,
            width: 311,
            height: 379,
          },
        },
      },
    },
    section07: {
      uiUx02: {
        title: "어려운 견적도 쉽게, 친절하게",
        description:
          "SK렌터카 다이렉트에서 견적내는 과정은 쉽고 친절합니다. 차근차근 하나하나 원하는 옵션을 선택하다보면 나에게 정확하게 적용되는 견적을 볼 수 있\n습니다. 각 과정에서 고민이 된다면 다른 사람들은 어떤걸 많이 선택했는지, 원하면 온라인 상담사와 연결해서 궁금한 부분을 바로바로 질문할 수도 있\n습니다. 견적을 저장하고, 여러 견적을 한번에 비교해볼 수도 있습니다.",
        mobileDesecription:
          "SK렌터카 다이렉트에서 견적내는 과정은 쉽고 친절합니다. 차근차근 하나하나 원하는 옵션을 선택하다보면 \n나에게 정확하게 적용되는 견적을 볼 수 있습니다. 각 과정에서 고민이 된다면 다른 사람들은 어떤걸 많이 선택\n했는지, 원하면 온라인 상담사와 연결해서 궁금한 부분을 바로바로 질문할 수도 있습니다. 견적을 저장하고, 여\n러 견적을 한번에 비교해볼 수도 있습니다. ",
        imageSrcs: [
          "/image/detail/skdirect/b1.png",
          "/image/detail/skdirect/b2.png",
          "/image/detail/skdirect/b3.png",
          "/image/detail/skdirect/b4.png",
        ],
      },
    },
    section08: {
      uiUx02: {
        title: "내 손 안에서 끝나는 손쉬운 계약과 전담 카매니저",
        description:
          "마음만 먹으면 계약은 순식간에 끝납니다. 계약차량 배송이 시작되기 전이라면 언제든 계약을 취소할 수도 있습니다.\n하지만 일반 쇼핑몰과 다른 값비싼 차량이기 때문에 SK렌터카 다이렉트에서는 전담 카매니저가 배치 됩니다. 계약과 납입이 완료되면 전담 카매니저는\n고객이 차량을 인도받기 까지 책임지고 끝까지 안내를 해드립니다. 각종 계약서 등 계약관련 정보도 언제든 열람할 수 있습니다.",
        mobileDesecription:
          "마음만 먹으면 계약은 순식간에 끝납니다. 계약차량 배송이 시작되기 전이라면 언제든 계약을 취소할 수도 있\n습니다. 하지만 일반 쇼핑몰과 다른 값비싼 차량이기 때문에 SK렌터카 다이렉트에서는 전담 카매니저가 배치\n됩니다. 계약과 납입이 완료되면 전담 카매니저는 고객이 차량을 인도받기 까지 책임지고 끝까지 안내를 해드\n립니다. 각종 계약서 등 계약관련 정보도 언제든 열람할 수 있습니다.",

        imageSrcs: [
          "/image/detail/skdirect/b5.png",
          "/image/detail/skdirect/b6.png",
          "/image/detail/skdirect/b7.png",
          "/image/detail/skdirect/b8.png",
        ],
      },
    },
  },
  // carpet
  {
    key: ServiceKeys.CARPET,
    section01: {
      backgroundSrc: "/image/detail/carpet/carpet.jpg",
      mobileBackgroundSrc: "/image/detail/carpet/carpet1.jpg",
      serviceDescription: [
        "Service Consulting",
        "Branding",
        "UI/UX Design",
        "Mobile Application",
        "Back Office System",
      ],
    },
    section02: {
      overviewDescription:
        "플러스팟은 앱테크 기반 전동 킥보드 무료 충전 서비스를 제공하여 도시미관 및 지역경제 활성화에 공헌하고자 출시되었습니다. 슬로그업이 가지고 있는 전략과 경험을 바탕으로  플러스팟의 서비스가 사용자들에게 편리하게 이용될 수 있도록 우리는 Branding부터 B2C App과 Back Office System을 설계하였습니다.",
    },
    section03: {
      problemDescription:
        "공유킥보드 시장의 규모가 커지면서 전동킥보드 공유서비스는 어느새 또 하나의 이동수단으로 자리 잡게 되었으나, 비거치식 서비스 운영 방식에 따른 무분별한 주·정차로 인해 통행 및 시설 이용 방해, 차도·대중교통 승강장 등의 교통 흐름 방해, 소방시설과 같은 주요 안전시설을 방해하는 사례들이 다수 발생되었습니다.",
      problemImage: [
        "/image/detail/pluspot/problem01.jpg",
        "/image/detail/pluspot/problem02.jpg",
      ],
      goals: [
        {
          title: "마이크로모빌리티 주차는 스테이션에",
          subTitle: "다양한 미션을 통한 스테이션 이용 활성화 도모",
        },
        {
          title: "손쉬운 킥보드 주차와 빌려타기",
          subTitle: "This is where the project objectives are 01",
        },
        {
          title: "다양한 제휴 상점을 통한 지역 경제 활성화",
          subTitle: "This is where the project objectives are 01",
        },
        {
          title: "리워드를 통한 리텐션",
          subTitle: "This is where the project objectives are 01",
        },
      ],
    },
    section04: {
      ux: [
        {
          title: "마이크로모빌리티 주차는 스테이션에",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "손쉬운 킥보드 주차와 빌려타기",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "다양한 제휴 상점을 통한 지역 경제 활성화",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "리워드를 통한 리텐션",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
      ],
      tech: [
        {
          title: "다양한 제휴 상점을 통한 지역 경제 활성화",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "리워드를 통한 리텐션",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
      ],
      imageSrc: "/image/detail/pluspot/tech.jpg",
      mobileImageSrc: "/image/detail/pluspot/tech1.jpg",
    },
    section05: {
      solutionDescription:
        "길거리 방치가 아닌 스테이션에 주차하는것을 유도하며, 주차시에 리워드를 지급하여 스테이션 활용성을 높이는 전략으로 구성하였습니다",
      steps: [
        "플러스팟을 통해\n킥보드 이용",
        "목적지 근처\n스테이션 도달",
        "스테이션에\n주차",
        "주차 시\n리워드 지급",
      ],
    },
    section06: {
      uiUx01: {
        title: "킥보드를 빌려타기",
        description:
          "플러스팟은 길거리에 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해  앱테크 기반 전동 킥보드 무료 충전 서비스를 제공합니다. 슬로그업이 가지고 있는 전략과 경험을 바탕으로  플러스팟의 서비스가 사용자들에게 편리하게 이용될 수 있도록 우리는 Branding부터 B2C App과 Back Office System을 설계하였습니다.",
        imageSrc01: "/image/detail/pluspot/pluspot-ui01.jpg",
        imageSrc02: "/image/detail/pluspot/pluspot-ui02.jpg",
        imageSrc03: "/image/detail/pluspot/pluspot-ui03.jpg",
      },
    },
    section07: {
      uiUx02: {
        title: "리워드 지급",
        description:
          "플러스팟은 길거리에 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해  앱테크 기반 전동 킥보드 무료 충전 서비스를 제공합니다. 슬로그업이 가지고 있는 전략과 경험을 바탕으로  플러스팟의 서비스가 사용자들에게 편리하게 이용될 수 있도록 우리는 Branding부터 B2C App과 Back Office System을 설계하였습니다.",
        imageSrcs: [
          "/image/detail/pluspot/pluspot-ui04.jpg",
          "/image/detail/pluspot/pluspot-ui05.jpg",
          "/image/detail/pluspot/pluspot-ui06.jpg",
          "/image/detail/pluspot/pluspot-ui07.jpg",
        ],
      },
    },
  },
  // pluspot
  {
    key: ServiceKeys.PLUSPOT,
    section01: {
      backgroundSrc: "/image/detail/pluspot/pluspot.jpg",
      mobileBackgroundSrc: "/image/detail/pluspot/pluspot1.jpg",
      serviceDescription: [
        "Service Consulting",
        "Branding",
        "UI/UX Design",
        "Mobile Application",
        "Back Office System",
      ],
    },
    section02: {
      overviewDescription:
        "플러스팟은 앱테크 기반 전동 킥보드 무료 충전 서비스를 제공하여 도시미관 및 지역경제 활성화에 공헌하고자 출시되었습니다.\n슬로그업이 가지고 있는 전략과 경험을 바탕으로 플러스팟의 서비스가 사용자들에게 편리하게 이용될 수 있도록 우리는 Branding부터 B2C App과\n Back Office System을 설계하였습니다.",
    },
    section03: {
      problemDescription:
        "공유킥보드 시장의 규모가 커지면서 전동킥보드 공유서비스는 어느새 또 하나의 이동수단으로 자리 잡게 되었으나, 비거치식 서비스 운영 방식에 따른 \n무분별한 주·정차로 인해 통행 및 시설 이용 방해, 차도·대중교통 승강장 등의 교통 흐름 방해, 소방시설과 같은 주요 안전시설을 방해하는 사례들이 다수\n발생되었습니다.",
      problemImage: [
        "/image/detail/pluspot/problem01.jpg",
        "/image/detail/pluspot/problem02.jpg",
      ],
      goals: [
        {
          title: "직관적인 로고 디자인",
          subTitle:
            "스팟에 ‘Personal mobility’를 충전하는 모습을\n직관적으로 표현",
        },
        {
          title: "마이크로모빌리티 주차는 스테이션에",
          subTitle: "스테이션 활성화를 위한 앱테크 컨셉 도입",
        },
        {
          title: "지역 상점을 통한 지역 경제 활성화",
          subTitle:
            "사용자가 스테이션 인근 다양한 상점을 방문할 수 있도록 유도",
        },
        {
          title: "리워드를 통한 리텐션",
          subTitle: "미션 성공 시 제공되는 다양한 보상을 통한 리텐션",
        },
      ],
    },
    section04: {
      ux: [
        {
          title: "필요한 정보를 한눈에",
          subTitle:
            "다양한 공유 킥보드의 위치 및 스테이션 정보를 빠르게 찾아\n킥보드 이용에 용이할 수 있도록 설계된 Map",
        },
        {
          title: "플러스팟 특화 비주얼 디자인",
          subTitle:
            "흥미를 유발할 수 있는 UI를 통해, 사용자의 시각적 피로도를 낮추고\n쉽게 앱을 사용할 수 있도록 구성",
        },
        {
          title: "사용자 중심의 인터페이스",
          subTitle:
            "플러스팟의 여러 콘텐츠를 편안하게 이용할 수 있도록\n직관적인 레이아웃 설계",
        },
      ],
      tech: [
        {
          title: "새로운 기능에 대한 확장성 고려",
          subTitle:
            "추후 다양한 기능들을 고도화할 수 있도록 고려한 아키텍처 설계 및 \n 모든 서비스의 전반적인 비즈니스 로직 구현\n고객사의 니즈에 맞춰진 Cloud, Infra system 구축",
        },
        {
          title: "빠르고 효과적인 퍼포먼스 창출",
          subTitle:
            "최신기술을 수용하며 짧은 기간 내 최고의 성과를 도출할 수 있도록 \nReact Native를 사용하여 크로스 플랫폼 앱 개발",
        },
      ],
      imageSrc: "/image/detail/pluspot/tech.jpg",
      mobileImageSrc: "/image/detail/pluspot/tech1.jpg",
    },
    section05: {
      solutionDescription:
        "길거리 방치가 아닌 스테이션에 주차하는것을 유도하며, 주차시에 리워드를 지급하여 스테이션 활용성을 \n높이는 전략으로 구성하였습니다",
      steps: [
        "플러스팟을 통해\n킥보드 이용",
        "목적지 근처\n스테이션 도달",
        "스테이션에\n주차",
        "주차 시\n리워드 지급",
      ],
    },
    section06: {
      uiUx01: {
        title: "쉽게 확인할 수 있는 스테이션 및 킥보드 정보",
        description:
          "플러스팟의 핵심 전략인 킥보드를 이용하고 포인트를 받는 행위에 초점을 맞춰 사용자가 불편함 없이 누구나 쉽게 서비스를 이용할 수 있도록 구성하였습니다.",
        imageSrc01: "/image/detail/pluspot/pluspot-ui01.jpg",
        imageSrc02: "/image/detail/pluspot/pluspot-ui02.jpg",
        imageSrc03: "/image/detail/pluspot/pluspot-ui03.jpg",
      },
    },
    section07: {
      uiUx02: {
        title: "재미있는 미션을 통한 보상은 덤",
        description:
          "다양한 미션과 보상을 활용하여 서비스의 리텐션을 높이고 사용자들이 킥보드 이용 후 자연스럽게 스테이션에 주차할 수 있도록 설계하였습니다.",
        imageSrcs: [
          "/image/detail/pluspot/b1.jpg",
          "/image/detail/pluspot/b2.jpg",
          "/image/detail/pluspot/b3.jpg",
          "/image/detail/pluspot/b4.jpg",
          "/image/detail/pluspot/b5.jpg",
          "/image/detail/pluspot/b6.jpg",
        ],
      },
    },
  },
  // tirepick
  {
    key: ServiceKeys.TIREPICK,
    section01: {
      backgroundSrc: "/image/detail/tirepick/tirepick.jpg",
      mobileBackgroundSrc: "/image/detail/tirepick/tirepick1.jpg",
      serviceDescription: [
        "Service Consulting",
        "Branding",
        "UI/UX Design",
        "Mobile Application",
        "Back Office System",
      ],
    },
    section02: {
      overviewDescription:
        "플러스팟은 앱테크 기반 전동 킥보드 무료 충전 서비스를 제공하여 도시미관 및 지역경제 활성화에 공헌하고자 출시되었습니다. 슬로그업이 가지고 있는 전략과 경험을 바탕으로  플러스팟의 서비스가 사용자들에게 편리하게 이용될 수 있도록 우리는 Branding부터 B2C App과 Back Office System을 설계하였습니다.",
    },
    section03: {
      problemDescription:
        "공유킥보드 시장의 규모가 커지면서 전동킥보드 공유서비스는 어느새 또 하나의 이동수단으로 자리 잡게 되었으나, 비거치식 서비스 운영 방식에 따른 무분별한 주·정차로 인해 통행 및 시설 이용 방해, 차도·대중교통 승강장 등의 교통 흐름 방해, 소방시설과 같은 주요 안전시설을 방해하는 사례들이 다수 발생되었습니다.",
      problemImage: [
        "/image/detail/pluspot/problem01.jpg",
        "/image/detail/pluspot/problem02.jpg",
      ],
      goals: [
        {
          title: "마이크로모빌리티 주차는 스테이션에",
          subTitle: "다양한 미션을 통한 스테이션 이용 활성화 도모",
        },
        {
          title: "손쉬운 킥보드 주차와 빌려타기",
          subTitle: "This is where the project objectives are 01",
        },
        {
          title: "다양한 제휴 상점을 통한 지역 경제 활성화",
          subTitle: "This is where the project objectives are 01",
        },
        {
          title: "리워드를 통한 리텐션",
          subTitle: "This is where the project objectives are 01",
        },
      ],
    },
    section04: {
      ux: [
        {
          title: "마이크로모빌리티 주차는 스테이션에",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "손쉬운 킥보드 주차와 빌려타기",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "다양한 제휴 상점을 통한 지역 경제 활성화",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "리워드를 통한 리텐션",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
      ],
      tech: [
        {
          title: "다양한 제휴 상점을 통한 지역 경제 활성화",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "리워드를 통한 리텐션",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
      ],
      imageSrc: "/image/detail/pluspot/tech.jpg",
      mobileImageSrc: "/image/detail/pluspot/tech1.jpg",
    },
    section05: {
      solutionDescription:
        "길거리 방치가 아닌 스테이션에 주차하는것을 유도하며, 주차시에 리워드를 지급하여 스테이션 활용성을 높이는 전략으로 구성하였습니다",
      steps: [
        "플러스팟을 통해\n킥보드 이용",
        "목적지 근처\n스테이션 도달",
        "스테이션에\n주차",
        "주차 시\n리워드 지급",
      ],
    },
    section06: {
      uiUx01: {
        title: "킥보드를 빌려타기",
        description:
          "플러스팟은 길거리에 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해  앱테크 기반 전동 킥보드 무료 충전 서비스를 제공합니다. 슬로그업이 가지고 있는 전략과 경험을 바탕으로  플러스팟의 서비스가 사용자들에게 편리하게 이용될 수 있도록 우리는 Branding부터 B2C App과 Back Office System을 설계하였습니다.",
        imageSrc01: "/image/detail/pluspot/pluspot-ui01.jpg",
        imageSrc02: "/image/detail/pluspot/pluspot-ui02.jpg",
        imageSrc03: "/image/detail/pluspot/pluspot-ui03.jpg",
      },
    },
    section07: {
      uiUx02: {
        title: "리워드 지급",
        description:
          "플러스팟은 길거리에 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해  앱테크 기반 전동 킥보드 무료 충전 서비스를 제공합니다. 슬로그업이 가지고 있는 전략과 경험을 바탕으로  플러스팟의 서비스가 사용자들에게 편리하게 이용될 수 있도록 우리는 Branding부터 B2C App과 Back Office System을 설계하였습니다.",
        imageSrcs: [
          "/image/detail/pluspot/pluspot-ui04.jpg",
          "/image/detail/pluspot/pluspot-ui05.jpg",
          "/image/detail/pluspot/pluspot-ui06.jpg",
          "/image/detail/pluspot/pluspot-ui07.jpg",
        ],
      },
    },
  },
  // mintit
  {
    key: ServiceKeys.MINTIT,
    section01: {
      backgroundSrc: "/image/detail/mintit/mintit.jpg",
      mobileBackgroundSrc: "/image/detail/mintit/mintit1.jpg",
      serviceDescription: [
        "Service Consulting",
        "Branding",
        "UI/UX Design",
        "Mobile Application",
        "Back Office System",
      ],
    },
    section02: {
      overviewDescription:
        "플러스팟은 앱테크 기반 전동 킥보드 무료 충전 서비스를 제공하여 도시미관 및 지역경제 활성화에 공헌하고자 출시되었습니다. 슬로그업이 가지고 있는 전략과 경험을 바탕으로  플러스팟의 서비스가 사용자들에게 편리하게 이용될 수 있도록 우리는 Branding부터 B2C App과 Back Office System을 설계하였습니다.",
    },
    section03: {
      problemDescription:
        "공유킥보드 시장의 규모가 커지면서 전동킥보드 공유서비스는 어느새 또 하나의 이동수단으로 자리 잡게 되었으나, 비거치식 서비스 운영 방식에 따른 무분별한 주·정차로 인해 통행 및 시설 이용 방해, 차도·대중교통 승강장 등의 교통 흐름 방해, 소방시설과 같은 주요 안전시설을 방해하는 사례들이 다수 발생되었습니다.",
      problemImage: [
        "/image/detail/pluspot/problem01.jpg",
        "/image/detail/pluspot/problem02.jpg",
      ],
      goals: [
        {
          title: "마이크로모빌리티 주차는 스테이션에",
          subTitle: "다양한 미션을 통한 스테이션 이용 활성화 도모",
        },
        {
          title: "손쉬운 킥보드 주차와 빌려타기",
          subTitle: "This is where the project objectives are 01",
        },
        {
          title: "다양한 제휴 상점을 통한 지역 경제 활성화",
          subTitle: "This is where the project objectives are 01",
        },
        {
          title: "리워드를 통한 리텐션",
          subTitle: "This is where the project objectives are 01",
        },
      ],
    },
    section04: {
      ux: [
        {
          title: "마이크로모빌리티 주차는 스테이션에",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "손쉬운 킥보드 주차와 빌려타기",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "다양한 제휴 상점을 통한 지역 경제 활성화",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "리워드를 통한 리텐션",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
      ],
      tech: [
        {
          title: "다양한 제휴 상점을 통한 지역 경제 활성화",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "리워드를 통한 리텐션",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
      ],
      imageSrc: "/image/detail/pluspot/tech.jpg",
      mobileImageSrc: "/image/detail/pluspot/tech1.jpg",
    },
    section05: {
      solutionDescription:
        "길거리 방치가 아닌 스테이션에 주차하는것을 유도하며, 주차시에 리워드를 지급하여 스테이션 활용성을 높이는 전략으로 구성하였습니다",
      steps: [
        "플러스팟을 통해\n킥보드 이용",
        "목적지 근처\n스테이션 도달",
        "스테이션에\n주차",
        "주차 시\n리워드 지급",
      ],
    },
    section06: {
      uiUx01: {
        title: "킥보드를 빌려타기",
        description:
          "플러스팟은 길거리에 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해  앱테크 기반 전동 킥보드 무료 충전 서비스를 제공합니다. 슬로그업이 가지고 있는 전략과 경험을 바탕으로  플러스팟의 서비스가 사용자들에게 편리하게 이용될 수 있도록 우리는 Branding부터 B2C App과 Back Office System을 설계하였습니다.",
        imageSrc01: "/image/detail/pluspot/pluspot-ui01.jpg",
        imageSrc02: "/image/detail/pluspot/pluspot-ui02.jpg",
        imageSrc03: "/image/detail/pluspot/pluspot-ui03.jpg",
      },
    },
    section07: {
      uiUx02: {
        title: "리워드 지급",
        description:
          "플러스팟은 길거리에 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해  앱테크 기반 전동 킥보드 무료 충전 서비스를 제공합니다. 슬로그업이 가지고 있는 전략과 경험을 바탕으로  플러스팟의 서비스가 사용자들에게 편리하게 이용될 수 있도록 우리는 Branding부터 B2C App과 Back Office System을 설계하였습니다.",
        imageSrcs: [
          "/image/detail/pluspot/pluspot-ui04.jpg",
          "/image/detail/pluspot/pluspot-ui05.jpg",
          "/image/detail/pluspot/pluspot-ui06.jpg",
          "/image/detail/pluspot/pluspot-ui07.jpg",
        ],
      },
    },
  },
  // highend
  {
    key: ServiceKeys.HIGHEND,
    section01: {
      backgroundSrc: "/image/detail/highend/highend.jpg",
      mobileBackgroundSrc: "/image/detail/highend/highend1.jpg",
      serviceDescription: [
        "Service Consulting",
        "Branding",
        "UI/UX Design",
        "Mobile Application",
        "Back Office System",
      ],
    },
    section02: {
      overviewDescription:
        "플러스팟은 앱테크 기반 전동 킥보드 무료 충전 서비스를 제공하여 도시미관 및 지역경제 활성화에 공헌하고자 출시되었습니다. 슬로그업이 가지고 있는 전략과 경험을 바탕으로  플러스팟의 서비스가 사용자들에게 편리하게 이용될 수 있도록 우리는 Branding부터 B2C App과 Back Office System을 설계하였습니다.",
    },
    section03: {
      problemDescription:
        "공유킥보드 시장의 규모가 커지면서 전동킥보드 공유서비스는 어느새 또 하나의 이동수단으로 자리 잡게 되었으나, 비거치식 서비스 운영 방식에 따른 무분별한 주·정차로 인해 통행 및 시설 이용 방해, 차도·대중교통 승강장 등의 교통 흐름 방해, 소방시설과 같은 주요 안전시설을 방해하는 사례들이 다수 발생되었습니다.",
      problemImage: [
        "/image/detail/pluspot/problem01.jpg",
        "/image/detail/pluspot/problem02.jpg",
      ],
      goals: [
        {
          title: "마이크로모빌리티 주차는 스테이션에",
          subTitle: "다양한 미션을 통한 스테이션 이용 활성화 도모",
        },
        {
          title: "손쉬운 킥보드 주차와 빌려타기",
          subTitle: "This is where the project objectives are 01",
        },
        {
          title: "다양한 제휴 상점을 통한 지역 경제 활성화",
          subTitle: "This is where the project objectives are 01",
        },
        {
          title: "리워드를 통한 리텐션",
          subTitle: "This is where the project objectives are 01",
        },
      ],
    },
    section04: {
      ux: [
        {
          title: "마이크로모빌리티 주차는 스테이션에",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "손쉬운 킥보드 주차와 빌려타기",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "다양한 제휴 상점을 통한 지역 경제 활성화",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "리워드를 통한 리텐션",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
      ],
      tech: [
        {
          title: "다양한 제휴 상점을 통한 지역 경제 활성화",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
        {
          title: "리워드를 통한 리텐션",
          subTitle:
            "플러스팟은 길거리에 무분별하게 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해 앱테크 기반 전동",
        },
      ],
      imageSrc: "/image/detail/pluspot/tech.jpg",
      mobileImageSrc: "/image/detail/pluspot/tech1.jpg",
    },
    section05: {
      solutionDescription:
        "길거리 방치가 아닌 스테이션에 주차하는것을 유도하며, 주차시에 리워드를 지급하여 스테이션 활용성을 높이는 전략으로 구성하였습니다",
      steps: [
        "플러스팟을 통해\n킥보드 이용",
        "목적지 근처\n스테이션 도달",
        "스테이션에\n주차",
        "주차 시\n리워드 지급",
      ],
    },
    section06: {
      uiUx01: {
        title: "킥보드를 빌려타기",
        description:
          "플러스팟은 길거리에 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해  앱테크 기반 전동 킥보드 무료 충전 서비스를 제공합니다. 슬로그업이 가지고 있는 전략과 경험을 바탕으로  플러스팟의 서비스가 사용자들에게 편리하게 이용될 수 있도록 우리는 Branding부터 B2C App과 Back Office System을 설계하였습니다.",
        imageSrc01: "/image/detail/pluspot/pluspot-ui01.jpg",
        imageSrc02: "/image/detail/pluspot/pluspot-ui02.jpg",
        imageSrc03: "/image/detail/pluspot/pluspot-ui03.jpg",
      },
    },
    section07: {
      uiUx02: {
        title: "리워드 지급",
        description:
          "플러스팟은 길거리에 방치되어 있는 공유 킥보드로 인한 지역사회 문제를 해결하기 위해  앱테크 기반 전동 킥보드 무료 충전 서비스를 제공합니다. 슬로그업이 가지고 있는 전략과 경험을 바탕으로  플러스팟의 서비스가 사용자들에게 편리하게 이용될 수 있도록 우리는 Branding부터 B2C App과 Back Office System을 설계하였습니다.",
        imageSrcs: [
          "/image/detail/pluspot/pluspot-ui04.jpg",
          "/image/detail/pluspot/pluspot-ui05.jpg",
          "/image/detail/pluspot/pluspot-ui06.jpg",
          "/image/detail/pluspot/pluspot-ui07.jpg",
        ],
      },
    },
  },
  // openmile
  {
    key: ServiceKeys.OPENMILE,
    section01: {
      backgroundSrc: "/image/detail/openmile/openmile.jpg",

      mobileBackgroundSrc: "/image/detail/openmile/openmile1.jpg",
      serviceDescription: [],
    },
    section02: {
      overviewDescription:
        "오픈마일은 화물기사들을 대상으로 합리적이고 편리한 정기점검 구독 서비스를 제공하는 플랫폼 입니다.\n쉴틈 없이 운행하는 화물차에 필요한 정기 점검과 소모품 보충을 방문정비 방식으로 제공 합니다.\n 기존 탁송물류업 기반을 바탕으로 새로운 서비스를 창출하는 오픈마일과 슬로그업이 함께 했습니다.\n 슬로그업은 서비스 기획부터 디자인, Back-end System, 고객용 모바일App, Back Office System 까지 모든 것을 담당 했습니다.",
    },
    section03: {
      problemDescription:
        "영업용 화물차는 빈번한 운행 빈도 만큼 주기적인 차량점검 및 소모품 보충이 필요 합니다.\n하지만 특정 공업사나 제휴처들만 이용해야하는 불편함이 있습니다.\n특히 운송업이라는 특성상 언제 어디서 응급상황이 나올지 모르는 상황도 있습니다.",
      problemImage: [
        "/image/detail/openmile/problem01.jpg",
        "/image/detail/openmile/problem02.jpg",
      ],
      goals: [
        {
          title: "찾아오는 방문정비 서비스 구축",
          subTitle: "특정 공업사나 제휴처가 아니라도 방문정비 서비스면 오케이",
        },
        {
          title: "정기 구독 서비스로 비용부담 없이",
          subTitle: "한번에 빠져나가는 정비 비용 부담 없이 월별로 나눠서 지급",
        },
        {
          title: "다양한 정비 서비스를 원하는 방식으로",
          subTitle: "각종 소모품 교환과 타이어 교체까지 오픈마일에서 진행",
        },
        {
          title: "운행 중에도 쉽고 간편하게",
          subTitle: "바쁜 운송업 중에도 활용할 수 있는 직관적 화면 구성",
        },
      ],
    },
    section04: {
      ux: [
        {
          title: "매일 업데이트되는 내 차량상태",
          subTitle:
            "문제가 발생하기 전에 확인해서 미리 확인하고 점검할 수 있는\n 메인화면 (OBD 설치형 상품 구독 시)",
        },
        {
          title: "원하는 서비스를 원하는 장소에서",
          subTitle:
            "잠시 갓길에 주차해서 대기해도 방문정비 기사가 찾아올 수 있도록\n 정교하고 적확한 내 차 위치 지정",
        },
        {
          title: "필요한 추가 서비스도 한번에",
          subTitle:
            "기본으로 제공되는 서비스 뿐 아니라 추가적으로 필요한 소모품도\n 원한다면 바로 구매 가능",
        },
        {
          title: "심플하고 직관적인 화면 구성",
          subTitle:
            "언제 어디서나 직관적으로 확인하고 접근할 수 있는\n 단순하고 직관적인 UI 구성",
        },
      ],
      tech: [
        {
          title: "온라인으로 제어하는 오프라인 서비스",
          subTitle:
            "원활한 오프라인 서비스를 위해 Back Office System을 활용하는\n 상황실을 중심으로 고객과 매니저를 유기적으로 연결",
        },
        {
          title: "멀티 플랫폼으로 확장성 제공",
          subTitle:
            "계속 추가되는 서비스와 기능에 대응할 수 있는 유연하고 확장성있는\n 시스템 구축",
        },
      ],
      imageSrc: "/image/detail/pluspot/tech.jpg",
      mobileImageSrc: "/image/detail/pluspot/tech1.jpg",
    },
    section05: {
      solutionDescription:
        "고객을 위한 모바일앱, 매니저를 위한 매니저앱, 그리고 이 모두를 관제하는 Back Office System을 바탕으로\n 고객이 원하는 서비스를 언제 어디서나 받을 수 있는 솔루션 구축",
      steps: [
        "고객이 앱으로\n원하는 서비스 신청",
        "상황실에서\n매니저 배치",
        "매니저 고객과 소통하며\n찾아가는 서비스 제공",
        "모든 서비스를\n구독 방식으로 제공",
      ],
    },
    section06: {
      uiUx01: {
        title: "내가 원하는 상품 구독하기",
        description:
          "고객은 내가 원하는 만큼의 서비스를 제공하는 상품을 구독할 수 있습니다. 추가적으로 필요한 서비스가 있다면 적절한 추가비용만 지불하면 됩니다.",
        imageSrc01: "/image/detail/openmile/mobileEx1.jpg",
        imageSrc02: "/image/detail/openmile/mobileEx1.jpg",
        imageSrc03: "/image/detail/openmile/pcEx.jpg",
      },
    },
    section07: {
      uiUx02: {
        title: "원하는 때, 원하는 곳에서, 원하는 서비스 받기",
        description:
          "필수적인 정기점검 사항부터 소모품 교환. 그리고 연간 1번 정기적으로 받는 검사대행까지. 고객이 신청하면 언제, 어디든 매니저가 방문해서 서비스를\n진행 합니다.",
        imageSrcs: [
          "/image/detail/openmile/b1.jpg",
          "/image/detail/openmile/b2.jpg",
          "/image/detail/openmile/b3.jpg",
          "/image/detail/openmile/b4.jpg",
        ],
      },
    },
  },
];
export const showableDetailServices = [
  ServiceKeys.OPENMILE,
  ServiceKeys.SKRANTERCAR,
  ServiceKeys.PLUSPOT,
];
