import { useEffect } from "react";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { observerEntriesState, observerState } from "../constants/recoil";

export const useObserver = (options?: IntersectionObserverInit) => {
  const setObserver = useSetRecoilState(observerState);
  const resetObserver = useResetRecoilState(observerState);
  const setEntries = useSetRecoilState(observerEntriesState);
  const resetEntries = useResetRecoilState(observerEntriesState);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      setEntries(entries);
    }, options);
    setObserver(observer);
    return () => {
      observer.disconnect();
      resetObserver();
      resetEntries();
    };
  }, []);
};
