import React, { useEffect, useState } from "react";
import styled from "styled-components";
import faqs, { Faq, FaqCategories } from "../../constants/faqs";
import { media, zIndex } from "../../constants/styles";
import { maxWidth } from "../sections/shared/styled";
import BoardBody from "./BoardBody";
import TabMenu from "./TabMenu";

const Board: React.FC = () => {
  const menus = Object.keys(faqs) as FaqCategories[];

  const [nowMenu, setNowMenu] = useState<FaqCategories>(FaqCategories.Support);
  const [nowMenuList, setNowMenuList] = useState<Faq[]>(
    faqs[FaqCategories.Support]
  );
  const [nowIndex, setNowIndex] = useState(-1);

  const handleClickTabMenuButton = (menu: FaqCategories) => {
    setNowMenu(menu);
    setNowIndex(-1);
  };

  const handleClickBoardRow = (index: number) => {
    if (nowIndex === index) setNowIndex(-1);
    else setNowIndex(index);
  };
  useEffect(() => {
    setNowMenuList(faqs[nowMenu]);
  }, [nowMenu]);

  return (
    <BoardWrapper>
      <TabMenuContainer>
        <TabMenu
          nowMenu={nowMenu}
          menus={menus}
          handleClickTabMenuButton={handleClickTabMenuButton}
        />
      </TabMenuContainer>
      <BoardContainer>
        <BoardBody
          nowIndex={nowIndex}
          handleClickBoardRow={handleClickBoardRow}
          menuList={nowMenuList}
        />
      </BoardContainer>
    </BoardWrapper>
  );
};
export default Board;
const BoardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  ${maxWidth};
  width: 100%;
  overflow-x: hidden;
  position: relative;

  ${media.MOBILE} {
    max-width: 100%;
  }
`;

const TabMenuContainer = styled.div`
  position: sticky;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #fff);
  z-index: 20;
  padding-bottom: 7px;
`;

const BoardContainer = styled.div`
  width: 100%;
  // max-height: 60vh;
`;
