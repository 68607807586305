import React, { useEffect, useState } from "react";
import BaseSection from "../../BaseSection";

import Responsable from "../../../Responsable";
import styled from "styled-components";
import {
  color,
  cssTemplate,
  grid,
  media,
  pcPaddingTopBottom,
} from "../../../../constants/styles";

import { ReactComponent as Yes } from "../../../../svgs/yes.svg";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import { yesLink } from "../../../../constants/ref";
import Text from "./Text";
import { useObserver } from "../../../../hooks/useObserver";
import Button from "../../../Button";
import useInterval from "../../../../hooks/useInterval";
import { DEFAULT_INTERVAL } from "../../../../constants/animationValue";
import { useNavigate } from "react-router-dom";
import routes from "../../../../constants/routes";

interface LastSectionProps {}

const Base = styled(BaseSection)``;

const LastSection: React.FC<LastSectionProps> = (props) => {
  const { ref, isIntersecting } = useIntersecting();
  const nav = useNavigate();
  const [isInteracted, setIsInteracted] = useState<number>(-1);
  const handleClickButton = (e: React.MouseEvent) => {
    e.preventDefault();
    // nav(routes.RECRUIT);
    window.open("https://www.rocketpunch.com/companies/slogup/jobs", "_blank");
  };
  useInterval(
    () => {
      setIsInteracted(isInteracted + 1);
    },
    isIntersecting ? (isInteracted > 3 ? null : DEFAULT_INTERVAL) : null
  );
  return (
    // <Base>
    <Container>
      <SectionWrapper>
        <ContentWrapper>
          {/* <img src="/image/home/FooterImg.png" height="100%" /> */}
          <TextWrapper
            ref={ref}
            theme={{ isIntersecting: isInteracted >= 1 }}
            style={{ alignItems: "center" }}
          >
            <Text
              smallText="Come Grow With Us"
              bigText={
                <>
                  우리 함께 일해요!
                  <br />
                  새로운 슬로거를 환영합니다.
                </>
              }
            />
          </TextWrapper>
          <Button
            isIntersecting={isInteracted >= 2}
            isAnimated={false}
            label="채용 알아보기"
            onClick={handleClickButton}
          ></Button>
        </ContentWrapper>
      </SectionWrapper>
    </Container>
    // </Base>
  );
};

export default LastSection;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.TABLET} {
    // max-height: ${window.innerHeight}px;
    display: block;
  }
  ${media.MOBILE} {
    // max-height: ${window.innerHeight - 45}px;
  }
`;
const SectionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 160px;
  isolation: isolate;
  // max-width: 1920px;
  min-height: 830px;
  width: 100%;

  padding: 160px 0px;
  /* width: 100%;
  height: calc(var(--vh, 1vh) * 100);; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("/image/webp/recruit/FooterImg-Pc.webp");

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    content: " ";
  }
  ${media.TABLET} {
    background-image: url("/image/webp/recruit/FooterImg-Tablet.webp");
    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0);
      content: " ";
    }
    min-height: 596px;
  }
  ${media.MOBILE} {
    min-height: 388px;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0px;
  gap: 60px;
  ${media.TABLET} {
    gap: 40px;
  }
  ${media.MOBILE} {
    gap: 24px;
  }
  & > img {
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  ${(props) => cssTemplate.fadeInOut(props)};
  ${media.TABLET} {
    gap: 24px;
  }
  ${media.MOBILE} {
    gap: 12px;
  }
  /* position: absolute; */
`;
const Desc = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* or 150% */

  text-align: center;

  /* Gray/Gray-08 (White) */

  color: #ffffff;

  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
  ${(props) => cssTemplate.fadeInOut(props)};
  ${media.TABLET} {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
  }
  ${media.MOBILE} {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;
