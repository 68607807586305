import React from "react";
import styled from "styled-components";
import BaseSection from "../../BaseSection";
import { cssTemplate, media } from "../../../../constants/styles";
import {
  SectionLayout,
  ContentLayout,
  Title,
  GridWrap,
  GridItem,
  ItemContents,
} from "../../shared/DetailSectionStyled";
import { ReactComponent as Pin } from "../../../../svgs/pin.svg";
import Responsable from "../../../Responsable";
import { Section04 } from "../../../../constants/detail/types";

interface DetailSection04Props {
  data: Section04;
}

const DetailSection04: React.FC<DetailSection04Props> = ({
  data: { ux, tech, imageSrc, mobileImageSrc },
}) => {
  return (
    <BaseSection fullWidth autoHeight>
      <StyledSectionLayout>
        <ContentLayout>
          <Title>How do we solve the problem?</Title>

          <UxContentWrap>
            {/* mobile이랑 컨텐츠 내용 다름 */}
            <Responsable
              pc={
                <ContentsTitle>
                  UX Strategy <span>(UX전략내용)</span>
                </ContentsTitle>
              }
              tablet={<ContentsTitle>UX Strategy</ContentsTitle>}
              mobile={<ContentsTitle>UX Strategy</ContentsTitle>}
            />
            <ContentsList>
              {ux.map(({ title, subTitle }) => {
                return (
                  <ContentsItem key={title}>
                    <div>
                      <StyledPin />
                      <p>{title}</p>
                    </div>
                    <div>
                      <small>{subTitle}</small>
                    </div>
                  </ContentsItem>
                );
              })}
            </ContentsList>
          </UxContentWrap>

          <div>
            <Responsable
              pc={
                <ContentsTitle>
                  Tech Strategy <span>(UX전략에 따른 기술적 해결)</span>
                </ContentsTitle>
              }
              tablet={<ContentsTitle>Tech Strategy</ContentsTitle>}
              mobile={<ContentsTitle>Tech Strategy</ContentsTitle>}
            />
            <ContentsList>
              {tech.map(({ title, subTitle }) => {
                return (
                  <ContentsItem key={title}>
                    <div>
                      <StyledPin />
                      <p>{title}</p>
                    </div>
                    <div>
                      <small>{subTitle}</small>
                    </div>
                  </ContentsItem>
                );
              })}
            </ContentsList>
          </div>
        </ContentLayout>
      </StyledSectionLayout>
    </BaseSection>
  );
};

export default DetailSection04;

const StyledSectionLayout = styled(SectionLayout)`
  height: 866px;
  ${media.TABLET} {
    height: auto;
  }
  ${media.MOBILE} {
    height: 1080px;
  }
`;

const UxContentWrap = styled.div`
  /*  */
  margin-bottom: 80px;
  ${media.TABLET} {
    margin-bottom: 60px;
  }
  ${media.MOBILE} {
    margin-bottom: 60px;
  }
`;

const ContentsTitle = styled.p`
  font-weight: 700;
  font-size: ${cssTemplate.vwConvertPc(26)};
  line-height: 1.5;
  margin-bottom: 32px;

  > span {
    color: #dcdcdc;
  }

  ${media.TABLET} {
    font-size: ${cssTemplate.vwConvertTablet(22)};
    margin-bottom: 24px;
  }
  ${media.MOBILE} {
    font-size: ${cssTemplate.vwConvertMobile(22)};
    margin-bottom: 24px;
  }
`;

const ContentsList = styled(GridWrap)`
  row-gap: 32px;
  column-gap: 24px;
  height: auto;
  ${media.TABLET} {
    row-gap: 32px;
    gap: 24px;
    height: auto;
  }

  ${media.MOBILE} {
    row-gap: none;
    gap: 24px;
  }
`;

const ContentsItem = styled(GridItem)`
  display: block;
  height: 98px;
  gap: none;

  > div:nth-child(1) {
    height: 34px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    gap: 6px;

    > p {
      font-size: ${cssTemplate.vwConvertPc(22)};
      font-weight: 700;
      line-height: 1.6;
    }
  }

  > div:nth-child(2) {
    padding-left: 30px;

    > small {
      line-height: 1.6;
      font-weight: 500;
      font-size: ${cssTemplate.vwConvertPc(18)};
      color: #646464;
    }
  }

  ${media.TABLET} {
    width: auto;
    height: auto;
    > div:nth-child(1) {
      margin-bottom: 8px;

      > p {
        overflow-x: hidden;
        white-space: nowrap;
        font-size: ${cssTemplate.vwConvertTablet(17)};
        line-height: 1.4;
      }
    }

    > div:nth-child(2) {
      padding-left: 26px;

      > small {
        font-size: ${cssTemplate.vwConvertTablet(16)};
        font-weight: 400;
        line-height: 1.5;
      }
    }
  }
  ${media.MOBILE} {
    width: 327px;
    height: 104px;

    > div:nth-child(1) {
      height: 24px;
      margin-bottom: 8px;
      gap: 4px;

      > p {
        font-size: ${cssTemplate.vwConvertMobile(17)};
        line-height: 1.4;
      }
    }

    > div:nth-child(2) {
      padding-left: 26px;

      > small {
        font-size: ${cssTemplate.vwConvertMobile(16)};
        font-weight: 400;
        line-height: 1.5;
      }
    }
  }
`;

const StyledPin = styled(Pin)`
  width: 24px;
  height: 24px;
  ${media.TABLET} {
    min-width: 24px;
    min-height: 24px;
  }
  ${media.MOBILE} {
    width: 24px;
    height: 24px;
  }
`;

const TechImage = styled.div<{ imageSrc: string; mobileImageSrc: string }>`
  height: 320px;
  margin-top: 80px;
  background: no-repeat center/cover url(${({ imageSrc }) => imageSrc});

  ${media.MOBILE} {
    height: 320px;
    margin-top: 60px;
    background-image: (${({ mobileImageSrc }) => mobileImageSrc});
  }
`;
