import React, { useState } from "react";

import { DEFAULT_INTERVAL } from "../../../../../constants/animationValue";

import { useIntersecting } from "../../../../../hooks/useIntersecting";
import useInterval from "../../../../../hooks/useInterval";
import Button from "../../../../Button";

import BaseSection from "../../../BaseSection";

import {
  SectionLayout,
  InfoWrapper,
  TextWrapper,
  TextSpan,
  TextH1,
  DescWrapper,
  Desc,
  TitleWrapper,
} from "../styled";

interface SectionProps {
  titleSpan: React.ReactNode;
  titleH1: React.ReactNode;
  buttonLabel: string;
  buttionClickHandler: (e: React.MouseEvent) => void;
  bottom?: React.ReactNode;
  descs: string[];
  types?: string;
}

const PCSection: React.FC<SectionProps> = ({
  titleH1,
  titleSpan,
  buttionClickHandler,
  buttonLabel,
  bottom,
  descs,
  types,
}) => {
  return (
    <BaseSection fullWidth>
      <SectionLayout>
        <InfoWrapper>
          <TitleWrapper>
            <TextWrapper>
              <TextSpan>{titleSpan}</TextSpan>
              <TextH1>{titleH1}</TextH1>
            </TextWrapper>
            <Button
              label={buttonLabel}
              types="black"
              isAnimated={false}
              onClick={buttionClickHandler}
            />
          </TitleWrapper>
          <DescWrapper
            style={
              types === "valueup" ? { flexDirection: "column-reverse" } : {}
            }
          >
            {descs.map((desc, index) => (
              <Desc key={desc}>{desc}</Desc>
            ))}
            <div style={{ marginTop: "20px" }}>
              {(!types || types === "valueup") && bottom}
            </div>
          </DescWrapper>
        </InfoWrapper>
        {(types === "outsourcing" || types === "tech") && bottom}
      </SectionLayout>
    </BaseSection>
  );
};

export default PCSection;
