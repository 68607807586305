import React, { useEffect, useRef, useState } from "react";
import {
  color,
  cssTemplate,
  grid,
  media,
  pcPaddingRightLeft,
  pcPaddingTopBottom,
  tabletPaddingTopBottom,
} from "../../../../constants/styles";
import BaseSection from "../../BaseSection";

import Responsable from "../../../Responsable";

import ScrollOverlayMacBookAir from "../scrollOverays/ScrollOverlayMacBookAir";
import styled from "styled-components";
import { url } from "inspector";
import { useIntersecting } from "../../../../hooks/useIntersecting";

interface HomeSection02Props {}

const HomeSection02_2: React.FC<HomeSection02Props> = (props) => {
  const windowHeight = useRef<number>(window.innerHeight);
  const isMobileCheck = window.innerWidth <= 672;
  const animationHeight = windowHeight.current * (isMobileCheck ? 3 : 2.4);
  return (
    <BaseSection fullWidth fullHeight>
      <Responsable
        pc={
          <ScrollOverlayMacBookAir
            minHeight={`${animationHeight}px`}
            maxHeight={`${windowHeight}px`}
            screenType="PC"
          />
        }
        tablet={
          <ScrollOverlayMacBookAir
            minHeight={`${animationHeight}px`}
            maxHeight={`${windowHeight}px`}
            screenType="TABLET"
          />
        }
        mobile={
          <ScrollOverlayMacBookAir
            minHeight={`${animationHeight}px`}
            maxHeight={`${windowHeight}px`}
            screenType="MOBILE"
          />
        }
      />
    </BaseSection>
  );
};

export default HomeSection02_2;
