import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  cssTemplate,
  media,
  mobileContainerGap,
  mobilePaddingTopBottom,
  pcContainerGap,
  pcPaddingRightLeft,
  pcPaddingTopBottom,
  tabletContainerGap,
} from "../../../../../constants/styles";
import { useIntersecting } from "../../../../../hooks/useIntersecting";
import Responsable from "../../../../Responsable";
import BaseSection from "../../../BaseSection";
import {
  Wrapper,
  MiniTitle,
  BigDesc,
  SmallDesc,
  cardDate,
  cardMain,
  cardDesc,
  maxWidth,
} from "../../../shared/styled";
import { SectionProps } from "../../../shared/types";
import ValueUpCardList from "./ValueUpCardList";

interface ContentRef {
  intro1: HTMLDivElement | null;
}
interface Props extends SectionProps {}
const ScrollOverlayValueUpService: React.FC<Props> = ({ screenType }) => {
  const { ref, isIntersecting } = useIntersecting();
  const [interacted, setInteracted] = useState<boolean>(false);
  useEffect(() => {
    if (isIntersecting) setInteracted(true);
  }, [isIntersecting]);
  return (
    <Container>
      <StickyWrapper>
        <Wrapper>
          <MainWrapper ref={ref} theme={{ isIntersecting: interacted }}>
            <MiniTitle>Valueup Service</MiniTitle>
            <BigDesc style={{ color: "#fff" }}>
              세상에 필요한 서비스가 <br />
              세상에 있게 합니다.
            </BigDesc>
            <Responsable
              pc={
                <SmallDesc style={{ color: "#fff" }}>
                  밸류업 사업부에선 세상에 필요한 서비스를 직접 선보입니다.{" "}
                  <br />
                  특정 분야에 강한 회사와 조인트벤처를 설립해 가치있는 서비스를
                  만듭니다.
                </SmallDesc>
              }
              tablet={
                <SmallDesc style={{ color: "#fff" }}>
                  밸류업 사업부에선 세상에 필요한 서비스를 직접 선보입니다.{" "}
                  <br />
                  특정 분야에 강한 회사와 조인트벤처를 설립해 가치있는 서비스를
                  만듭니다.
                </SmallDesc>
              }
              mobile={
                <SmallDesc style={{ color: "#fff" }}>
                  밸류업 사업부에선 세상에 필요한 서비스를 직접 선보입니다. 특정
                  분야에 강한 회사와 조인트벤처를 설립해 가치있는 서비스를
                  만듭니다.
                </SmallDesc>
              }
            />
          </MainWrapper>
        </Wrapper>
        <ValueUpCardList screenType={screenType} />
      </StickyWrapper>
    </Container>
  );
};
export default ScrollOverlayValueUpService;

const Container = styled.div`
  display: flex;
  background-color: #111;
  padding: ${pcPaddingTopBottom} 48px;

  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 600px;
  gap: ${pcContainerGap};
  min-height: 150vh;
  ${media.TABLET} {
    // padding: 0px;
    gap: ${tabletContainerGap};
  }
  ${media.MOBILE} {
    padding: ${mobilePaddingTopBottom} 0px;
    gap: ${mobileContainerGap};
  }
`;

const StickyWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  top: 0;

  position: sticky;

  gap: 40px;
  ${media.TABLET} {
    // padding: 0px 0px;
    max-width: 672px;
    gap: ${tabletContainerGap};
  }
  ${media.MOBILE} {
    padding: 0px 24px;
    gap: ${mobileContainerGap};
  }
`;
const MainWrapper = styled.div`
  ${(props) => cssTemplate.fadeInOut(props)}
  ${maxWidth}

 ${media.TABLET} {
    // margin: 0;
  }

  ${media.MOBILE} {
    // margin: 0px 24px;
    max-width: 100%;
  }
`;
