import React, { useEffect, useRef, useState } from "react";
import BaseSection from "../../BaseSection";

import Responsable from "../../../Responsable";

import styled from "styled-components";
import { maxWidth, Wrapper } from "../../shared/styled";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import {
  cssTemplate,
  media,
  mobilePaddingTopBottom,
  pcContainerGap,
  pcPaddingTopBottom,
  tabletContainerGap,
  tabletPaddingTopBottom,
} from "../../../../constants/styles";

import Image from "./Image";

interface HomeSection05Props {}

const HomeSection05: React.FC<HomeSection05Props> = (props) => {
  const imgRef = useRef<HTMLDivElement>(null);
  const { ref, isIntersecting } = useIntersecting();
  const [isDivIntersecting, setIsDivIntersecting] = useState(false);

  useEffect(() => {
    if (isIntersecting) setIsDivIntersecting(true);
  }, [isIntersecting]);

  return (
    <BaseSection fullWidth>
      <Container>
        <SectionWrapper>
          <ContentWrapper>
            <TextWrapper
              ref={ref}
              theme={{ isIntersecting: isDivIntersecting }}
            >
              <Responsable
                pc={
                  <Content>
                    혁신을 손에 잡히는 모습으로 만드는
                    <br />
                    슬로그업의 도전과 창조는 계속됩니다.
                    <Image screenType="pc" />
                  </Content>
                }
                tablet={
                  <Content>
                    혁신을 손에 잡히는 모습으로
                    <br /> 만드는 슬로그업의
                    <br /> 도전과 창조는 계속됩니다.
                    <Image screenType="tablet" />
                  </Content>
                }
                mobile={
                  <Content>
                    혁신을 손에 잡히는 모습으로
                    <br /> 만드는 슬로그업의 도전과 창조는
                    <br />
                    계속됩니다.
                    <Image screenType="mobile" />
                  </Content>
                }
              />
            </TextWrapper>
            {/* <Image /> */}
          </ContentWrapper>
        </SectionWrapper>
      </Container>
    </BaseSection>
  );
};

export default HomeSection05;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  ${media.TABLET} {
    gap: 80px;
  }

  ${media.MOBILE} {
    font-size: 24px;
    line-height: 36px;
    gap: 60px;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  background-color: #f8f8f8;
  // max-width: 1200px;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${pcPaddingTopBottom} 48px;
  gap: ${pcContainerGap};
  isolation: isolate;

  overflow: hidden;
  ${media.TABLET} {
    align-items: center;
    gap: ${tabletContainerGap};
    padding: ${tabletPaddingTopBottom} 0px;
  }
  ${media.MOBILE} {
    gap: 80px;
    padding: 0px;
  }
`;

const SectionWrapper = styled(Wrapper)`
  overflow: hidden;
  margin: 0 auto;
  // min-height: calc(var(--vh, 1vh) * 100);
  ${media.TABLET} {
    // padding: ${tabletPaddingTopBottom} 48px;
    padding: 0 48px;
    max-width: unset;
  }
  ${media.MOBILE} {
    padding: ${mobilePaddingTopBottom} 24px;
    max-width: unset;
  }
  overflow-x: hidden;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 100px;
  width: 100%;

  ${media.TABLET} {
    flex-direction: column;
    align-items: center;
    gap: 60px;
  }
  ${media.MOBILE} {
    flex-direction: column;
    align-items: center;
    gap: 60px;
  }
`;
const TextWrapper = styled.div`
  /* Display [KR]/Big 5-60B */

  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 150%;
  /* or 90px */

  ${maxWidth}
  /* Gray/Gray-08 (White) */
  ${(props) => cssTemplate.fadeInOut(props)}
  color: #1e1e1e;
  ${media.TABLET} {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 68px;
    /* or 142% */

    text-align: center;

    /* Gray/Gray-08 (White) */

    color: #1e1e1e;
  }
  ${media.MOBILE} {
    max-width: 100%;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    /* or 155% */

    text-align: center;

    /* Gray/Gray-08 (White) */

    color: #1e1e1e;
  }
`;

const Img = styled.img`
  ${(props) => cssTemplate.fadeInOut(props)}
  height: 100%;
  width: 100%;
  border-radius: 24px;
  max-height: 601.35px;
  object-fit: cover;
  ${media.TABLET} {
    min-height: 601.35px;
    object-fit: cover;
  }

  ${media.MOBILE} {
    border-radius: 12px;
  }
`;
