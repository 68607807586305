import React from "react";
import BaseSection from "../../BaseSection";
import styled from "styled-components";
import { cssTemplate, media } from "../../../../constants/styles";
import {
  ContentLayout,
  SectionLayout,
  Title,
} from "../../shared/DetailSectionStyled";
import whiteArrow from "../../../../svgs/white-arrow.svg";
import { Section05 } from "../../../../constants/detail/types";

interface DetailSection05Props {
  data: Section05;
}

const DetailSection05: React.FC<DetailSection05Props> = ({
  data: { solutionDescription, steps },
}) => {
  return (
    <BaseSection fullWidth autoHeight>
      <StyledSectionLayout>
        <StyledContentLayout>
          <div>
            <Title>Solution</Title>
            <span>{solutionDescription}</span>
          </div>

          <SolutionStepList>
            {steps.map((step, idx) => {
              return (
                <SolutionStep key={`${step}${idx}`}>
                  <span>{step}</span>
                </SolutionStep>
              );
            })}
          </SolutionStepList>
        </StyledContentLayout>
      </StyledSectionLayout>
    </BaseSection>
  );
};

export default DetailSection05;

const StyledSectionLayout = styled(SectionLayout)`
  /*  */
  background: #111111;
  height: 670px;
  ${media.TABLET} {
    height: auto;
  }
  ${media.MOBILE} {
    height: auto;
  }
`;

const StyledContentLayout = styled(ContentLayout)`
  /*  */
  color: #fff;

  > div {
    margin-bottom: 80px;

    > span {
      white-space: pre-wrap;
      display: inline-block;
      line-height: 1.6;
    }
  }
  ${media.TABLET} {
    > div:first-child {
      margin-bottom: 60px;

      > span {
        display: block;
        white-space: normal;
        line-height: 1.6;
      }
    }
    > div {
      margin-bottom: 0px;
    }
  }
  ${media.MOBILE} {
    height: auto;

    > div:first-child {
      margin-bottom: 60px;

      > span {
        display: block;
        white-space: normal;
        line-height: 1.6;
      }
    }
  }
`;

const SolutionStepList = styled.div`
  /*  */
  width: 1040px;
  height: 218px;
  display: flex;
  gap: 56px;

  ${media.TABLET} {
    width: auto;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    /* background: red; */ // FIXME
  }
  ${media.MOBILE} {
    width: 327;
    height: 334;
    display: flex;
    flex-wrap: wrap;
    gap: 32;
    /* background: red; */ // FIXME
  }
`;

const SolutionStep = styled.div`
  /*  */
  width: 218px;
  height: 218px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #1e1e1e;

  /* text */
  > span {
    white-space: pre-wrap;
    text-align: center;
    font-weight: 400;
    font-size: ${cssTemplate.vwConvertPc(20)};
    line-height: 1.7;
  }

  /* white arrow */
  ::after {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    position: absolute;
    right: -40px;
    background: no-repeat url(${whiteArrow});
  }

  &:last-child {
    ::after {
      display: none;
    }
  }
  ${media.TABLET} {
    width: 140px;
    height: 140px;
    flex-wrap: wrap;

    /* text */
    > span {
      font-size: 14px;
      line-height: 1.4;
    }

    /* white arrow */
    ::after {
      width: 24px;
      height: 24px;
      right: -28px;
    }

    &:nth-child(1) {
      order: 1;
    }
    &:nth-child(2) {
      order: 2;
    }
    &:nth-child(3) {
      order: 3;
    }
    &:nth-child(4) {
      order: 3;
    }
  }
  ${media.MOBILE} {
    width: 140px;
    height: 140px;
    flex-wrap: wrap;

    /* text */
    > span {
      font-size: ${cssTemplate.vwConvertMobile(14)};
      line-height: 1.4;
    }

    /* white arrow */
    ::after {
      width: 24px;
      height: 24px;
      right: -28px;
    }

    &:nth-child(1) {
      order: 1;
    }
    &:nth-child(2) {
      order: 2;
      ::after {
        display: none;
      }
    }
    &:nth-child(3) {
      order: 4;
      ::after {
        left: -28px;
        transform: rotate(180deg);
      }
    }
    &:nth-child(4) {
      order: 3;
    }
  }
`;
