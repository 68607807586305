import React, { useLayoutEffect } from "react";

import Layout from "../../components/Layout";

import TeamSection01 from "../../components/sections/teamSections/TeamSection01";
import TeamSection02 from "../../components/sections/teamSections/TeamSection02";
import TeamSection03 from "../../components/sections/teamSections/TeamSection03";
import TeamSection04 from "../../components/sections/teamSections/TeamSection04";
import { Helmet } from "react-helmet";
import LastSection from "../../components/sections/teamSections/LastSection";
import TeamSection05 from "../../components/sections/teamSections/TeamSection05";

interface TeamProps {}

const Team: React.FC<TeamProps> = (props) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>Slogup - Team</title>
      </Helmet>
      {/* We Are... */}
      <TeamSection01 />

      {/* 우리는 여섯가지 가치를... */}
      <TeamSection02 />

      {/* 여러분은 왜... */}
      <TeamSection03 />

      {/* 슬로그업은... */}
      <TeamSection04 />

      {/* 우리 함께 일해요!... */}
      <TeamSection05 />
    </Layout>
  );
};

export default Team;
