import React, { useState } from "react";
import styled, { css } from "styled-components";
import { cssTemplate, media } from "../../../../../constants/styles";
import { ReactComponent as Slash } from "../../.././../../svgs/slash.svg";
import { ReactComponent as Arrow } from "../../.././../../svgs/arrow.svg";
import { ArrowIcon, JoinIcon } from "../../../shared/styled";
import Responsable from "../../../../Responsable";

export interface JoinCardProps {
  teamName: string;
  title: string;
  description: string;
  mobileDescription: string;
  link: string;
  intersecting?: boolean;
}
export const JoinCard: React.FC<JoinCardProps> = (props) => {
  const {
    teamName,
    title,
    description,
    mobileDescription,
    intersecting,
    link,
  } = props;

  return (
    <JoinCardContainer
      theme={{ isIntersecting: intersecting }}
      // onClick={() => window.open(link)}
    >
      <div className="join-card-team-name">
        <div>{teamName}</div>
      </div>

      <div className="join-card-title">
        <div>
          {title === "프로덕트 매니저" && (
            <img src="/image/webp/icons/Recruit-product.svg" />
          )}
          {title === "프로덕트 디자이너" && (
            <img src="/image/webp/icons/Recruit-design.svg" />
          )}
          {title === "클라이언트 개발자" && (
            <img src="/image/webp/icons/Recruit-front.svg" />
          )}
          {title === "서버 개발자" && (
            <img src="/image/webp/icons/Recruit-server.svg" />
          )}
          {title === "퍼포먼스 마케터" && (
            <img src="/image/webp/icons/Recruit-marketer.svg" />
          )}
          {title === "서비스 운영 전문가" && (
            <img src="/image/webp/icons/Recruit-service.svg" />
          )}
          {title === "HR 전문가" && (
            <img src="/image/webp/icons/Recruit-HR.svg" />
          )}
          {title === "재무 전문가" && (
            <img src="/image/webp/icons/Recruit-finance.svg" />
          )}
        </div>
        {title}
      </div>
      <div className="join-card-description">
        <Responsable
          pc={description}
          tablet={description}
          mobile={mobileDescription}
        />
      </div>
      {/* <StyledArrow className="join-card-arrow" /> */}
    </JoinCardContainer>
  );
};

const tabletJoinCard = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px;
  gap: 11px;

  // width: 672px;

  /* Gray/Gray-02 */

  background: #f8f8f8;
  ${(props) => cssTemplate.fadeInOut(props)}
  cursor: pointer;

  .join-card-team-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    gap: 24px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */

    /* Primary/Neon Yellow Green 05 ⭐️ */

    color: #1e1e1e;
    width: 100%;
  }

  .join-card-title {
    display: flex;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    /* identical to box height, or 150% */
    white-space: pre;
    /* Gray/Gray-08 (White) */

    color: #1e1e1e;
  }

  .join-card-description {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    /* or 165% */

    /* Gray/Gray-05 */

    color: #505050;
    white-space: pre-line;
    word-break: keep-all;
  }

  .join-card-arrow {
    path {
      stroke: #646464;
    }
  }
`;
const mobileJoinCard = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 4px;

  width: 100%;
  ${(props) => cssTemplate.fadeInOut(props)}
  cursor: pointer;

  .join-card-team-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    gap: 24px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* Primary/Neon Yellow Green 05 ⭐️ */

    color: #1e1e1e;
    width: 100%;
  }

  .join-card-title {
    display: flex;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    /* or 138% */
    white-space: pre;
    /* Gray/Gray-08 (White) */

    color: #1e1e1e;
  }

  .join-card-description {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 34px */

    /* Gray/Gray-05 */
    white-space: pre-line;
    word-break: keep-all;

    color: #505050;
  }

  .join-card-arrow {
    path {
      stroke: #646464;
    }
  }
`;
const JoinCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px 50px;
  // gap: 24px;
  background: #f8f8f8;
  border-radius: 24px;
  max-width: 580px;
  flex: 50%;
  ${(props) => cssTemplate.fadeInOut(props)}
  /* cursor: pointer; */
  &:hover {
    svg {
      fill: #b3ff01;
      stroke: #b3ff01;
    }
  }

  ${media.TABLET} {
    max-width: 100%;
  }
  .join-card-team-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    gap: 24px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 34px;
    /* identical to box height, or 150% */

    /* Primary/Neon Yellow Green 05 ⭐️ */

    color: #1e1e1e;
    width: 100%;
  }

  .join-card-title {
    display: flex;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    /* or 138% */
    white-space: pre;
    /* Gray/Gray-08 (White) */
    gap: 8px;
    color: #1e1e1e;
    margin-bottom: 16px;
  }

  .join-card-description {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    /* or 34px */

    /* Gray/Gray-05 */
    white-space: pre-line;
    word-break: keep-all;

    color: #505050;
  }

  .join-card-arrow {
    path {
      stroke: #646464;
    }
  }
  & > div.join-card-title > div > img {
    width: 40px;
  }

  // & > div.join-card-title > div {
  //   width: 45px;
  //   height: 40px;
  //   margin-top: 4px;
  // }
  ${media.TABLET} {
    ${tabletJoinCard};
  }
  ${media.MOBILE} {
    ${mobileJoinCard};

    .join-card-title {
      margin-bottom: 12px;
    }

    & > div.join-card-title > div > img {
      width: 30px;
    }
  }
`;

const StyledArrow = styled(ArrowIcon)`
  &:hover {
    fill: #b3ff01;
    stroke: #b3ff01;
  }
  ${media.TABLET} {
    fill: #b3ff01;
    stroke: #b3ff01;
  }
  ${media.MOBILE} {
    fill: #b3ff01;
    stroke: #b3ff01;
  }
`;
