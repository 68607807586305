import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { interviews, workPlace } from "../../../../constants/ref";
import {
  cssTemplate,
  grid,
  media,
  mobileContainerGap,
  mobilePaddingTopBottom,
  pcPaddingTopBottom,
  tabletContainerGap,
  tabletPaddingTopBottom,
} from "../../../../constants/styles";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import useInterval from "../../../../hooks/useInterval";
import Button from "../../../Button";
import Responsable from "../../../Responsable";
import BaseSection from "../../BaseSection";
import { StyledTitle, Title, TitleWrapper } from "../../shared/styled";

enum LOCATION {
  START,
  MIDDLE,
  END,
}

const TeamSection05: React.FC = () => {
  // const { ref, isIntersecting } = useIntersecting();
  // const [isInteracted, setIsInteracted] = useState(false);
  // useEffect(() => {
  //   if (isIntersecting) setIsInteracted(true);
  // }, [isIntersecting]);

  // const data = [...interviews, ...interviews, ...interviews, ...interviews];

  const data = [...workPlace];

  const navigateToLink = () => {
    window.open("https://www.rocketpunch.com/companies/slogup/jobs", "_blank");
  };
  const [isHovering, setIsHovering] = useState(false);
  const sliderRef = useRef<HTMLUListElement>(null);
  const cardRef = useRef<HTMLLIElement>(null);
  const prevRef = useRef<HTMLDivElement>(null);
  const nextRef = useRef<HTMLDivElement>(null);
  // useEffect(() => {
  //   let interval: NodeJS.Timeout | null = null;
  //   const flatform = window.navigator.userAgent.toLowerCase();
  //   if (
  //     flatform.includes("ios") ||
  //     flatform.includes("android") ||
  //     flatform.includes("iphone") ||
  //     flatform.includes("ipad")
  //   ) {
  //   } else {
  //     if (!isHovering && sliderRef.current) {
  //       interval = setInterval(() => {
  //         if (sliderRef.current) {
  //           sliderRef.current.scrollLeft += 1;
  //           if (
  //             sliderRef.current.scrollLeft + sliderRef.current.offsetWidth ===
  //             sliderRef.current.scrollWidth
  //           ) {
  //             sliderRef.current.scrollLeft = 0;
  //           }
  //         }
  //       }, 20);
  //     }
  //   }

  //   return () => {
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   };
  // }, [isHovering]);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleClick = (direction: string) => {
    if (sliderRef.current && cardRef.current) {
      if (direction === "left") {
        sliderRef.current.scrollTo({
          left:
            sliderRef.current.scrollLeft - (cardRef.current.offsetWidth + 10),
          behavior: "smooth",
        });
      }

      if (direction === "right") {
        sliderRef.current.scrollTo({
          left:
            sliderRef.current.scrollLeft + (cardRef.current.offsetWidth + 10),
          behavior: "smooth",
        });
      }
    }
  };

  const handleScrollEvent = () => {
    if (sliderRef.current && cardRef.current) {
      if (sliderRef.current.scrollLeft === 0) {
        if (prevRef.current) prevRef.current.style.opacity = "0.5";
      } else {
        if (prevRef.current) prevRef.current.style.opacity = "1";
      }
      if (
        sliderRef.current.scrollLeft + sliderRef.current.offsetWidth ===
        sliderRef.current.scrollWidth
      ) {
        if (nextRef.current) nextRef.current.style.opacity = "0.5";
      } else {
        if (nextRef.current && prevRef.current)
          nextRef.current.style.opacity = "1";
      }
    }
  };

  useEffect(() => {
    console.log(sliderRef.current);
    const el = sliderRef.current;
    if (el)
      el.addEventListener("scroll", () => {
        handleScrollEvent();
      });
    return el?.removeEventListener("scroll", () => {
      handleScrollEvent();
    });
  }, [sliderRef]);

  return (
    <BaseSection fullWidth>
      <SectionLayout>
        <Responsable
          pc={
            <TitleWrapper style={{ marginBottom: "60px" }}>
              <StyledTitle
                style={{
                  width: "unset",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>Come Grow with Us!</span>
                <h1>우리 함께 일해요!</h1>
                <h1>새로운 슬로거를 환영합니다.</h1>
              </StyledTitle>
            </TitleWrapper>
          }
          tablet={
            <TitleWrapper>
              {" "}
              <StyledTitle
                style={{
                  width: "unset",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>Come Grow With Us!</span>
                <h1 style={{ textAlign: "center" }}>
                  우리 함께 일해요!
                  <br /> 새로운 슬로거를 환영합니다.
                </h1>{" "}
              </StyledTitle>
            </TitleWrapper>
          }
          mobile={
            <TitleWrapper>
              <StyledTitle
                style={{
                  width: "unset",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>Come Grow With Us!</span>
                <h1 style={{ textAlign: "center" }}>
                  우리 함께 일해요!
                  <br /> 새로운 슬로거를 환영합니다.
                </h1>{" "}
              </StyledTitle>
            </TitleWrapper>
          }
        />
        <Responsable
          pc={
            <Button
              style={{ marginBottom: "67px" }}
              types="black"
              isAnimated={false}
              onClick={navigateToLink}
              label="채용 알아보기"
            />
          }
          tablet={
            <Button
              style={{ marginTop: "-40px" }}
              types="black"
              isAnimated={false}
              onClick={navigateToLink}
              label="채용 알아보기"
            />
          }
          mobile={
            <Button
              style={{ marginTop: "-45px" }}
              types="black"
              isAnimated={false}
              onClick={navigateToLink}
              label="채용 알아보기"
            />
          }
        />

        <CardListWrapper style={{ width: "100%" }}>
          <CardArrowContainer>
            <div
              className="lastPrev"
              ref={prevRef}
              style={{ display: "flex" }}
              onClick={() => handleClick("left")}
            >
              <CardArrowLeft src="/image/team/ArrowBlack.svg" />
              <span>PREV</span>
            </div>
            <div
              className="lastNext"
              ref={nextRef}
              style={{ display: "flex" }}
              onClick={() => handleClick("right")}
            >
              <span>NEXT</span>
              <CardArrowRight src="/image/team/ArrowBlack.svg" />
            </div>
          </CardArrowContainer>
          <CardList>
            <ul className="original" ref={sliderRef}>
              {data.map(({ mobileImgUrl, pcImgUrl }, index) => {
                return (
                  <li
                    ref={cardRef}
                    key={index}
                    style={{ borderRadius: "24px" }}
                  >
                    {index % (data.length / 4) === 3 ? (
                      <Card>
                        <Img src={pcImgUrl} alt={index + "place"} />
                      </Card>
                    ) : (
                      <CardSemi>
                        <Img src={pcImgUrl} alt={index + "place"} />
                      </CardSemi>
                    )}
                  </li>
                );
              })}
            </ul>
          </CardList>
          {/* <CardListReverse theme={{ interviewCount: interviews.length - 2 }}>
            <ul>
              {data.map(
                ({ interview, name, team, profileUrl, blank }, index) => {
                  return blank ? null : (
                    <li key={index + "reverse"}>
                      {index % (data.length / 4) === 3 ? (
                        <Card>
                          <img src={profileUrl} alt={name} />
                        </Card>
                      ) : (
                        <CardSemi>
                          <img src={profileUrl} alt={name} />
                        </CardSemi>
                      )}
                    </li>
                  );
                }
              )}
            </ul>
          </CardListReverse> */}
        </CardListWrapper>
      </SectionLayout>
    </BaseSection>
  );
};

export default TeamSection05;
const SectionLayout = styled.div`
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${pcPaddingTopBottom} 0px;
  overflow-x: hidden;

  ${media.TABLET} {
    gap: ${tabletContainerGap};
    padding: ${tabletPaddingTopBottom} 0px;
  }
  ${media.MOBILE} {
    gap: ${mobileContainerGap};
    padding: ${mobilePaddingTopBottom} 0px;
  }
`;

const CardList = styled.div`
  position: relative;
  width: 100%;

  > ul {
    &::-webkit-scrollbar {
      display: none;
    }
    display: flex;
    gap: 20px;
    width: 100%;
    overflow-y: hidden;
  }
  ${media.TABLET} {
    > ul {
      display: flex;
      gap: 12px;
      width: 100%;
    }
  }
  ${media.MOBILE} {
    > ul {
      display: flex;
      gap: 8px;
      width: 100%;
    }
  }
  overflow-x: hidden;
  overflow-y: hidden;
`;

const CardArrowContainer = styled.div`
  max-width: 1296px;
  display: flex;
  // position: absolute;
  justify-content: space-between;
  width: 100%;
  padding: 0px 48px;
  // bottom: 50%;
  // margin-bottom: 20px;

  ${media.TABLET} {
    display: none;
  }
  ${media.MOBILE} {
    padding: 0px 24px;
  }
  & > div {
    opacity: 1;
    cursor: pointer;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-top: 4px;
    height: 24px;
    color: #1e1e1e;
  }

  & > div.lastPrev {
    opacity: 0.5;
  }
`;

const CardArrowRight = styled.img`
  width: 22px;
  height: 16px;
  margin-top: 4px;
  margin-left: 10px;
  // height: 32px;
  // background-image: url("/image/team/ArrowBlack.svg");
  // background-size: cover;
  object-fit: cover;
`;

const CardArrowLeft = styled.img`
  width: 22px;
  height: 16px;
  margin-top: 4px;
  margin-right: 10px;
  // height: 32px;
  // background-image: url("/image/team/ArrowBlack.svg");
  // background-size: cover;
  object-fit: cover;
  transform: rotate(180deg);
`;

const CardListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 40px;

  ${media.TABLET} {
    gap: 20px;
  }
  ${media.MOBILE} {
    gap: 12px;
  }
`;

const Card = styled.div`
  border-radius: 24px;
  width: 400px;
  height: 267px;
  color: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  ${media.TABLET} {
    width: 400px;
    height: 280px;
  }
  ${media.MOBILE} {
    width: 220px;
    height: 154px;
  }
`;

const CardSemi = styled.div`
  border-radius: 24px;
  width: 400px;
  height: 267px;
  color: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  ${media.TABLET} {
    width: 400px;
    height: 267px;
  }
  ${media.MOBILE} {
    width: 220px;
    height: 146.84px;
  }
`;

const Img = styled.img`
  border-radius: 24px;

  ${media.MOBILE} {
    border-radius: 12px;
  }
`;

const CardSlider = styled.div`
  @keyframes marquee {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-25%);
    }
  }
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-snap-type: x proximity;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    dsiplay: none;
  }
`;

const Card2 = styled.div`
  flex: 0 0 320px;
  margin-right: 1rem;
  scroll-snap-align: center;

  &:hover {
    transform: scale(1.05);
    transition: none;
  }
`;
