import { useEffect, useState } from "react";
import { useIntersecting } from "../../../../../hooks/useIntersecting";
import styled from "styled-components";
import {
  cssTemplate,
  media,
  pcPaddingTopBottom,
} from "../../../../../constants/styles";
import { MovingArrow } from "../../../shared/styled";

interface SectionContentProps {
  screenType: string;
}

const Section1Content: React.FC<SectionContentProps> = (props) => {
  const { ref, isIntersecting } = useIntersecting();
  const [interacted, setInteracted] = useState<boolean>(false);
  useEffect(() => {
    if (isIntersecting) setInteracted(true);
  }, [isIntersecting]);

  return (
    <SectionContent>
      {props.screenType === "pc" && (
        <>
          <SectionContent>
            <SectionContentText
              ref={ref}
              theme={{ isIntersecting: interacted }}
            >
              With Slogup
            </SectionContentText>
            <SectionContentText
              ref={ref}
              theme={{ isIntersecting: interacted }}
            >
              Ideas Wake Up
            </SectionContentText>
            {/* <MovingArrow
              direction="up"
              src="/image/webp/icons/ArrowDown.webp"
            /> */}
          </SectionContent>
        </>
      )}
      {props.screenType === "tablet" && (
        <>
          <SectionContent>
            <SectionContentText
              ref={ref}
              theme={{ isIntersecting: interacted }}
            >
              With Slogup
            </SectionContentText>
            <SectionContentText
              ref={ref}
              theme={{ isIntersecting: interacted }}
            >
              Ideas Wake Up
            </SectionContentText>
            {/* <MovingArrow
              direction="up"
              src="/image/webp/icons/ArrowDown.webp"
            /> */}
          </SectionContent>
        </>
      )}

      {/* <MovingArrow direction="up" src="/image/webp/icons/ArrowDown.webp" /> */}
    </SectionContent>
  );
};

export default Section1Content;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-size: cover;
  opacity: 1;
  left: 0;
  width: 100vw;
  // min-height: calc(var(--vh, 1vh) * 100);
  max-height: 100vh;
  font-family: "Fivo Sans Modern";
  background-image: url("/image/webp/business/BusinessMain-Pc.webp");
  overflow: hidden;
  position: absolute;
  bottom: 0;
  top: unset;
  line-height: 124px;
  background-position: center;
  ${media.TABLET} {
    background-image: url("/image/webp/business/BusinessMain-Tablet.webp");
  }
  ${media.MOBILE} {
    background-image: url("/image/webp/business/BusinessMain-Mobile.webp");
  }
`;

const SectionContentText = styled.div`
  ${(props) => cssTemplate.fadeInOut(props)}
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  font-weight: 700;
  font-size: 60px;
  line-height: 150%;
  text-align: center;
  ${media.TABLET} {
    font-size: 48px;
    line-height: 68px;
  }
  ${media.MOBILE} {
    font-size: 32px;
    line-height: 44px;
  }
`;
