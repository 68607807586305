import React from "react";
import styled from "styled-components";
import { Section08 } from "../../../../constants/detail/types";
import { cssTemplate, media } from "../../../../constants/styles";
import BaseSection from "../../BaseSection";
import {
  ContentLayout,
  ImageLayout,
  SectionLayout,
} from "../../shared/DetailSectionStyled";

interface DetailSection07Props {
  data: Section08;
}

const DetailSection08: React.FC<DetailSection07Props> = ({
  data: { uiUx02 },
}) => {
  return (
    <BaseSection fullWidth autoHeight>
      <StyledSectionLayout>
        <StyledContentLayout>
          <ContentsWrap>
            <div>
              <span>03</span>
              <p>{uiUx02.title}</p>
            </div>
            <span>{uiUx02.description}</span>
          </ContentsWrap>
        </StyledContentLayout>

        <StyledImageLayout>
          {uiUx02.imageSrcs.map((imageSrc) => {
            return <View key={imageSrc} imageSrc={imageSrc} />;
          })}
        </StyledImageLayout>
      </StyledSectionLayout>
    </BaseSection>
  );
};

export default DetailSection08;

const StyledSectionLayout = styled(SectionLayout)`
  /*  */
  height: 1402px;

  ${media.MOBILE} {
    height: auto;
  }
`;

const StyledContentLayout = styled(ContentLayout)`
  /*  */
  ${media.MOBILE} {
    height: 420px;
  }
`;

const ContentsWrap = styled.div`
  > div {
    margin-bottom: 40px;

    > span {
      display: inline-block;
      font-weight: 700;
      font-size: ${cssTemplate.vwConvertPc(26)};
      line-height: 1.5;
      color: #949494;
      margin-bottom: 16px;
    }

    > p {
      font-weight: 700;
      font-size: ${cssTemplate.vwConvertPc(32)};
      line-height: 1.4;
    }
  }

  > span {
    display: inline-block;
    font-weight: 500;
    font-size: ${cssTemplate.vwConvertPc(18)};
    line-height: 1.6;
  }
  ${media.TABLET} {
    > div {
      margin-bottom: 40px;

      > span {
        font-size: ${cssTemplate.vwConvertTablet(22)};
        line-height: 1.5;
        margin-bottom: 16px;
      }

      > p {
        font-size: ${cssTemplate.vwConvertTablet(22)};
        line-height: 1.4;
      }
    }

    > span {
      font-weight: 500;
      font-size: ${cssTemplate.vwConvertTablet(16)};
      line-height: 1.5;
    }
  }
  ${media.MOBILE} {
    > div {
      margin-bottom: 40px;

      > span {
        font-size: ${cssTemplate.vwConvertMobile(22)};
        line-height: 1.5;
        margin-bottom: 16px;
      }

      > p {
        font-size: ${cssTemplate.vwConvertMobile(22)};
        line-height: 1.4;
      }
    }

    > span {
      font-weight: 500;
      font-size: ${cssTemplate.vwConvertMobile(16)};
      line-height: 1.5;
    }
  }
`;

const StyledImageLayout = styled(ImageLayout)`
  /*  */
  height: 980px;

  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;

  ${media.MOBILE} {
    height: 904px;
    background: #f8f8f8;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 19px;
  }
`;

const View = styled.div<{ imageSrc: string }>`
  /*  */
  width: 284px;
  height: 614px;
  box-shadow: 0px 32px 48px rgba(0, 0, 0, 0.08);

  background: no-repeat url(${({ imageSrc }) => imageSrc});
  background-size: contain;

  ${media.MOBILE} {
    width: 154px;
    height: 332px;

    :nth-child(1) {
      order: 1;
    }
    :nth-child(2) {
      order: 2;
    }
    :nth-child(3) {
      order: 3;
    }
    :nth-child(4) {
      order: 4;
    }
  }
`;
