import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { cssTemplate, media } from "../../../../constants/styles";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import Responsable from "../../../Responsable";
import BaseSection from "../../BaseSection";
import { MovingArrow, Title } from "../../shared/styled";
import ScrollOverlayPicExtention from "../scrollOverlays/ScrollOverlayPicExtention";
import Section1Content from "./Content/SectionContent";

interface TeamSection01Props {}

interface TeamSection01Props {}

const TeamSection01: React.FC<TeamSection01Props> = (props) => {
  const { ref, isIntersecting } = useIntersecting();
  const windowHieght = useRef<number>(window.screen.height);
  const animationHeight = window.screen.height;

  return (
    <BaseSection ref={ref} fullWidth fullHeight>
      <Responsable
        pc={
          <ScrollOverlayPicExtention
            text1={
              <TextMain>
                <div className="white">서로 힘이 되어주는</div>
                <div className="white">유기체적 원팀</div>
              </TextMain>
            }
            text2={
              <>
                팀으로서 실패하고,
                <br />
                팀으로서 성공합니다.
              </>
            }
            text3={
              <>
                누구의 도약이든
                <br />
                우리 모두의 도약입니다.
              </>
            }
            picture="/image/webp/team/TeamMain-Pc.webp"
            animationHeight={`${animationHeight * 4}px`}
            maxHeight={"100vh"}
          />
        }
        tablet={
          <ScrollOverlayPicExtention
            text1={
              <TextMain>
                <div className="white">서로 힘이 되어주는</div>
                <div className="white">유기체적 원팀</div>
              </TextMain>
            }
            text2={
              <>
                팀으로서 실패하고,
                <br />
                팀으로서 성공합니다.
              </>
            }
            text3={
              <>
                누구의 도약이든
                <br />
                우리 모두의 도약입니다.
              </>
            }
            picture="/image/webp/team/TeamMain-Tablet.webp"
            animationHeight={`${animationHeight * 3}px`}
            maxHeight={"100vh"}
          />
        }
        mobile={
          <ScrollOverlayPicExtention
            text1={
              <TextMain>
                <div className="white">서로 힘이 되어주는</div>
                <div className="white">유기체적 원팀</div>
              </TextMain>
            }
            text2={
              <>
                팀으로서 실패하고,
                <br />
                팀으로서 성공합니다.
              </>
            }
            text3={
              <>
                누구의 도약이든
                <br />
                우리 모두의 도약입니다.
              </>
            }
            picture="/image/webp/team/TeamMain-Mobile.webp"
            animationHeight={`${animationHeight * 3}px`}
            maxHeight={"100vh"}
          />
        }
      />
    </BaseSection>
  );
};

export default TeamSection01;

const TextMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  & > div.white {
    font-family: "Fivo Sans Modern";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 150%;
    /* identical to box height, or 120% */
    text-align: center;
    /* Gray/Gray-08 (White) */
    color: #ffffff;
  }

  ${media.TABLET} {
    & > div.white {
      font-family: "Fivo Sans Modern";
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 72px;
      /* identical to box height, or 120% */
      text-align: center;
      /* Gray/Gray-08 (White) */
      color: #ffffff;
      mix-blend-mode: normal;
    }
    & > div.green {
      font-family: "Fivo Sans Modern";
      font-style: italic;
      font-weight: 700;
      font-size: 120px;
      line-height: 144px;
      /* identical to box height, or 120% */
      text-align: center;
      /* Primary/Neon Yellow Green 05 ⭐️ */
      color: #b3ff01;
    }
  }
  ${media.MOBILE} {
    padding: 40px 0 0;
    & > div.white {
      font-family: "Fivo Sans Modern";
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      /* identical to box height, or 120% */
      text-align: center;
      /* Gray/Gray-08 (White) */
      color: #ffffff;
    }
    & > div.green {
      font-family: "Fivo Sans Modern";
      font-style: italic;
      font-weight: 700;
      font-size: 60px;
      line-height: 72px;
      /* identical to box height, or 120% */
      text-align: center;
      /* Primary/Neon Yellow Green 05 ⭐️ */
      color: #b3ff01;
    }
  }
`;

// const TeamSection01: React.FC<TeamSection01Props> = (props) => {
//   const { ref, isIntersecting } = useIntersecting();
//   const containerRef = useRef<HTMLDivElement>(null);
//   const [interacted, setInteracted] = useState<boolean>(false);
//   const windowHieght = useRef<number>(window.screen.height);
//   const animationHeight = window.screen.height;

//   useEffect(() => {
//     if (isIntersecting) setInteracted(true);
//   }, [isIntersecting]);

//   return (
//     <Base ref={containerRef} fullWidth fullHeight>
//       <Responsable
//         pc={<Section1Content />}
//         tablet={<Section1Content />}
//         mobile={<Section1Content />}
//       />
//     </Base>
//   );
// };

// export default TeamSection01;

// const Base = styled(BaseSection)`
//   position: relative;
//   min-height: 600px;
// `;
