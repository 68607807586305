import React from "react";
import styled from "styled-components";
import { PositionInfo, Section06 } from "../../../../constants/detail/types";
import { cssTemplate, media } from "../../../../constants/styles";
import BaseSection from "../../BaseSection";
import {
  ContentLayout,
  ImageLayout,
  ImageWrap,
  SectionLayout,
  SmallView,
  Title,
} from "../../shared/DetailSectionStyled";

interface DetailSection06Props {
  data: Section06;
}

const DetailSection06: React.FC<DetailSection06Props> = ({
  data: { uiUx01 },
}) => {
  return (
    <BaseSection fullWidth autoHeight>
      <StyledSectionLayout>
        <StyledContentLayout>
          <Title>UIUX</Title>

          <ContentsWrap>
            <div>
              <span>01</span>
              <p>{uiUx01.title}</p>
            </div>
            <span>{uiUx01.description}</span>
          </ContentsWrap>
        </StyledContentLayout>

        <StyledImageLayout>
          <ImageWrap>
            <FirstView imageSrc={uiUx01.imageSrc01} />
            <SecondView imageSrc={uiUx01.imageSrc02} />
          </ImageWrap>
          <ImageWrap>
            <BigView
              notPcSrc={uiUx01.imageSrc04}
              imageSrc={uiUx01.imageSrc03}
              positionInfo={uiUx01.positionInfo}
            />
          </ImageWrap>
        </StyledImageLayout>
      </StyledSectionLayout>
    </BaseSection>
  );
};

export default DetailSection06;

const StyledSectionLayout = styled(SectionLayout)`
  /*  */
  height: 1354px;
  ${media.TABLET} {
    height: auto;
  }
  ${media.MOBILE} {
    height: 1560px;
  }
`;

const StyledContentLayout = styled(ContentLayout)`
  /*  */
  ${media.TABLET} {
    height: 376px;
    padding: 60px 48px;
  }
  ${media.MOBILE} {
    width: 327px;
    height: 472px;
    padding: 60px 0;
  }
`;

const ContentsWrap = styled.div`
  > div {
    margin-bottom: 40px;

    > span {
      display: inline-block;
      font-weight: 700;
      font-size: ${cssTemplate.vwConvertPc(26)};
      line-height: 1.5;
      color: #949494;
      margin-bottom: 16px;
    }

    > p {
      font-weight: 700;
      font-size: ${cssTemplate.vwConvertPc(32)};
      line-height: 1.4;
    }
  }

  > span {
    display: inline-block;
    font-weight: 500;
    font-size: ${cssTemplate.vwConvertPc(18)};
    line-height: 1.6;
  }
  ${media.TABLET} {
    height: 292px;
    > div {
      margin-bottom: 40px;

      > span {
        font-size: ${cssTemplate.vwConvertTablet(22)};
        line-height: 1.5;
        margin-bottom: 16px;
      }

      > p {
        font-size: ${cssTemplate.vwConvertTablet(22)};
        line-height: 1.4;
      }
    }

    > span {
      font-size: ${cssTemplate.vwConvertTablet(16)};
      line-height: 1.5;
    }
  }
  ${media.MOBILE} {
    height: 292px;
    > div {
      margin-bottom: 40px;

      > span {
        font-size: ${cssTemplate.vwConvertMobile(22)};
        line-height: 1.5;
        margin-bottom: 16px;
      }

      > p {
        font-size: ${cssTemplate.vwConvertMobile(22)};
        line-height: 1.4;
      }
    }

    > span {
      font-size: ${cssTemplate.vwConvertMobile(16)};
      line-height: 1.5;
    }
  }
`;

const StyledImageLayout = styled(ImageLayout)`
  /*  */
  ${media.TABLET} {
    height: 1088px;
    flex-direction: column;
  }
  ${media.MOBILE} {
    height: 1088px;
    flex-direction: column;
  }
`;

const FirstView = styled(SmallView)<{ imageSrc: string }>`
  position: absolute;
  top: 90px;
  left: 178px;

  background: no-repeat center/cover url(${({ imageSrc }) => imageSrc});

  ${media.TABLET} {
    width: 166px;
    height: 359px;
    top: 45px;
    left: 207px;
  }

  ${media.MOBILE} {
    width: 154px;
    height: 332px;
    top: 90px;
    left: 24px;
  }
`;

const SecondView = styled(SmallView)<{ imageSrc: string }>`
  position: absolute;
  bottom: 0px;
  right: 178px;

  background: no-repeat center/cover url(${({ imageSrc }) => imageSrc});

  ${media.TABLET} {
    width: 166px;
    height: 359px;
    top: 137px;
    left: 395px;
  }
  ${media.MOBILE} {
    width: 154px;
    height: 332px;
    top: 175px;
    right: 24px;
  }
`;

const BigView = styled.span<{
  imageSrc: string;
  notPcSrc?: string;
  positionInfo?: PositionInfo;
}>`
  /*  */
  display: inline-block;

  width: ${({ positionInfo }) => positionInfo?.pc?.width || 544}px;
  height: ${({ positionInfo }) => positionInfo?.pc?.height || 1176}px;
  top: ${({ positionInfo }) => positionInfo?.pc?.top || 100}px;
  left: ${({ positionInfo }) => positionInfo?.pc?.left || 207}px;
  position: absolute;
  box-shadow: 0px 32px 48px rgba(0, 0, 0, 0.08);
  background: no-repeat url(${({ imageSrc }) => imageSrc});
  background-size: contain;

  ${media.TABLET} {
    width: ${({ positionInfo }) => positionInfo?.tablet?.width || 311}px;
    height: ${({ positionInfo }) => positionInfo?.tablet?.height || 674}px;
    top: ${({ positionInfo }) => positionInfo?.tablet?.top || 100}px;
    left: ${({ positionInfo }) => positionInfo?.tablet?.left || 228}px;
    background: no-repeat
      url(${({ imageSrc, notPcSrc }) => notPcSrc || imageSrc});
    background-size: contain;
  }
  ${media.MOBILE} {
    width: ${({ positionInfo }) => positionInfo?.mobile?.width || 311}px;
    height: ${({ positionInfo }) => positionInfo?.mobile?.height || 674}px;
    top: ${({ positionInfo }) => positionInfo?.mobile?.top || 100}px;
    left: ${({ positionInfo }) => positionInfo?.mobile?.left || 32}px;
    background: no-repeat
      url(${({ imageSrc, notPcSrc }) => notPcSrc || imageSrc});
    box-shadow: 0px;
    background-size: contain;
  }
`;
