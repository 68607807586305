import React from "react";
import { hydrate } from "react-dom";
import { createRoot } from "react-dom/client";

import reportWebVitals from "./reportWebVitals";

import App from "./App";

const rootElement = document.getElementById("root") as HTMLDivElement;

const app = <App />;

if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  const root = createRoot(rootElement);
  root.render(app);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
