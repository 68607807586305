import React, { useEffect } from "react";
import ReactModal from "react-modal";
import { useRecoilState } from "recoil";
import styled, { css } from "styled-components";
import {
  serviceDetail,
  showableDetailServices,
} from "../../../constants/detail/ref";
import { detailKeyValueState } from "../../../constants/recoil";
import { useBodyScrollLock } from "../../../hooks/useBodyScrollLock";
import DetailSection01 from "../../sections/detailSection/DetailSection01";
import DetailSection02 from "../../sections/detailSection/DetailSection02";
import DetailSection03 from "../../sections/detailSection/DetailSection03";
import DetailSection04 from "../../sections/detailSection/DetailSection04";
import DetailSection05 from "../../sections/detailSection/DetailSection05";
import DetailSection06 from "../../sections/detailSection/DetailSection06";
import DetailSection07 from "../../sections/detailSection/DetailSection07";
import DetailSection08 from "../../sections/detailSection/DetailSection08";

/**
 * // TODO
 *  - svg width, height - current
 *
 */

const DetailModal: React.FC = () => {
  const { lockScroll, openScroll } = useBodyScrollLock();
  const [{ isModalOpen, serviceDetailKey, isAnimation }, setDetailKeyValue] =
    useRecoilState(detailKeyValueState);

  // 1
  // if (!serviceDetailKey) null;
  let data = serviceDetail.filter((list) => {
    if (list.key === serviceDetailKey) {
      return list;
    }
  });
  const handleClose = () => {
    setDetailKeyValue((prev) => {
      return {
        ...prev,
        isAnimation: false,
      };
    });
  };
  useEffect(() => {}, [serviceDetailKey]);

  // console.log(">>> section01 : ", data[0]?.section01);
  // console.log(">>> section02 : ", data[0]?.section02);

  // 2
  // console.log(ddd[`${serviceDetailKey}`]);

  // modal open
  useEffect(() => {
    const preventGoBack = () => {
      handleClose();
      setTimeout(() => {
        history.pushState(null, "", location.href);
      }, 1000);
    };

    history.pushState(null, "", location.href);
    window.addEventListener("popstate", preventGoBack);

    return () => window.removeEventListener("popstate", preventGoBack);
  }, []);
  useEffect(() => {
    if (isModalOpen) {
      lockScroll();

      setTimeout(() => {
        setDetailKeyValue((prev) => {
          return { ...prev, isAnimation: true };
        });
      }, 200);
    } else {
      openScroll();
    }
  }, [isModalOpen]);

  // modal close
  useEffect(() => {
    if (!isAnimation) {
      setTimeout(() => {
        setDetailKeyValue((prev) => {
          return { ...prev, isModalOpen: false };
        });
      }, 1000);
    }
  }, [isAnimation]);

  // style
  const customStyles = {
    overlay: {
      background: "transparent",
    },
    content: {
      overflow: "auto",
    },
  };

  return (
    <StyledModal
      isOpen={isModalOpen}
      style={customStyles}
      isAnimation={isAnimation}
      ariaHideApp={false}
    >
      {/* 메인 이미지 */}
      <DetailSection01 data={data[0]?.section01} />

      {/* overview */}
      <DetailSection02 data={data[0]?.section02} />

      {/* The Problem & goals */}
      <DetailSection03 data={data[0]?.section03} />

      {/* How do we solve...  */}
      <DetailSection04 data={data[0]?.section04} />

      {/* Solution */}
      <DetailSection05 data={data[0]?.section05} />

      {/* UIUX 01 */}
      <DetailSection06 data={data[0]?.section06} />

      {/* UIUX 02 */}
      <DetailSection07 data={data[0]?.section07} />

      {data[0]?.section08 && <DetailSection08 data={data[0]?.section08} />}
    </StyledModal>
  );
};

export default DetailModal;

const StyledModal = styled(ReactModal)<{ isAnimation?: boolean }>`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 100;

  ${({ isAnimation }) => {
    if (isAnimation) {
      return css`
        transform: translateY(0);
      `;
    } else {
      return css`
        transform: translateY(100%);
      `;
    }
  }}

  transition: transform 0.8s cubic-bezier(0.77, 0, 0.175, 1) 0.4s,
    -webkit-transform 0.8s cubic-bezier(0.77, 0, 0.175, 1) 0.4s;
`;
