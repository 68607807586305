import styled, { css, keyframes } from "styled-components";
import {
  color,
  cssTemplate,
  grid,
  media,
  pcPaddingRightLeft,
  pcPaddingTopBottom,
} from "../../../../constants/styles";
import { ReactComponent as Arrow2 } from "../../../../svgs/arrow2.svg";
import { ReactComponent as JoinArrow } from "../../../../svgs/JoinArrow.svg";

export const Caption = styled.div`
  color: ${color.GREEN};
  font-size: ${cssTemplate.vwConvertPc(40)};
  font-weight: 700;
  margin-bottom: 60px;

  ${media.TABLET} {
    font-size: ${cssTemplate.vwConvertTablet(40)};
    margin-bottom: 48px;
  }
  ${media.MOBILE} {
    font-size: ${cssTemplate.vwConvertMobile(17)};
    margin-bottom: 16px;
  }
`;
export const cardDate = css`
  // font-family: "Fivo Sans Modern";
  // font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 120% */

  /* Primary/Neon Yellow Green 05 ⭐️ */

  color: #1e1e1e;
  ${media.MOBILE} {
    // font-family: "Fivo Sans Modern";
    // font-style: italic;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height, or 114% */

    /* Primary/Neon Yellow Green 05 ⭐️ */

    color: #1e1e1e;
  }
`;
export const maxWidth = css`
  max-width: 1200px;
  ${media.TABLET} {
    max-width: 672px;
  }
  ${media.MOBILE} {
    max-width: 327px;
  }
`;
export const cardMain = css`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  /* Gray/Gray-08 (White) */

  color: #1e1e1e;
  // white-space: pre;
  ${media.MOBILE} {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    /* Gray/Gray-08 (White) */

    color: #1e1e1e;
  }
`;

export const cardSubTitle = css`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #1e1e1e;
`;
export const IntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  width: 100%;
  ${maxWidth};
  ${(props) => cssTemplate.fadeInOut(props)}
  ${media.TABLET} {
    gap: 24px;
  }
  ${media.MOBILE} {
    // gap: 12px;
    max-width: 100%;
  }
`;

export const cardDesc = css`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* Gray/Gray-05 */

  color: #1e1e1e;
  ${media.MOBILE} {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* or 143% */

    /* Gray/Gray-05 */

    color: #1e1e1e;
  }
`;

export const Wrapper = styled.div`
  max-width: 1200px;
  // height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  // padding-left: calc((100vw - 1200px) / 2);

  ${media.TABLET} {
    // padding-left: calc(100vw / 10);
    // padding-right: 48px;
  }
  ${media.MOBILE} {
    align-items: center;
    // padding-left: 24px;
    // padding-right: 24px;
  }
`;
export const MiniTitle = styled.div`
  ${maxWidth}
  margin-bottom: 12px;
  // font-family: "Fivo Sans Modern";
  // font-style: italic;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  /* identical to box height, or 117% */

  /* Primary/Neon Yellow Green 05 ⭐️ */
  text-align: center;
  color: #84d500;
  ${media.TABLET} {
    // font-family: "Fivo Sans Modern";
    // font-style: italic;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height, or 117% */

    /* Primary/Neon Yellow Green 05 ⭐️ */

    color: #84d500;
    margin-bottom: 12px;
  }
  ${media.MOBILE} {
    // font-family: "Fivo Sans Modern";
    // font-style: italic;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    /* Primary/Neon Yellow Green 05 ⭐️ */

    color: #84d500;
  }
  text-align: left;
  width: 100%;
`;
export const BigDesc = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 150%;
  /* or 90px */

  /* Gray/Gray-08 (White) */

  margin-bottom: 40px;
  color: #1e1e1e;
  ${media.TABLET} {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 68px;
    /* or 142% */

    /* Gray/Gray-08 (White) */

    // color: #ffffff;
    text-align: left;
    width: 100%;
    margin-bottom: 24px;
  }
  ${media.MOBILE} {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 38px;
    /* or 146% */
    width: 100%;
    text-align: left;
    width: 100%;

    /* Gray/Gray-08 (White) */

    // color: #ffffff;
  }
  ${maxWidth}
`;
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${media.MOBILE} {
    // align-items: unset;
    // justify-content: unset;
  }
`;
export const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0px;
  gap: 12px;
  ${maxWidth}

  & > span {
    // font-family: "Fivo Sans Modern";
    // font-style: italic;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height, or 117% */

    /* Primary/Neon Yellow Green 05 ⭐️ */

    color: #90d700;
  }
  & > h1 {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 150%;
    /* or 90px */

    /* Gray/Gray-08 (White) */

    color: #1e1e1e;
  }

  & > span.black-title {
    color: #b3ff01;
  }

  & > h1.black-title {
    color: #fff;
  }
  ${media.TABLET} {
    & > h1 {
      font-size: 48px;
      line-height: 68px;
    }
    & > span {
      font-size: 24px;
      line-height: 28px;
    }
  }
  ${media.MOBILE} {
    max-width: 100%;
    & > h1 {
      font-size: 26px;
      line-height: 38px;
    }
    & > span {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

export const SmallDesc = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* or 150% */

  /* Gray/Gray-05 */

  color: #646464;
  ${media.TABLET} {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */

    /* Gray/Gray-05 */

    color: #646464;
  }
  ${media.MOBILE} {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    word-break: keep-all;
    /* or 150% */

    /* Gray/Gray-05 */

    color: #646464;
  }
`;
export const Title = styled.div`
  color: #ffffff;
  margin-bottom: 160px;
  word-break: keep-all;
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 100px;
    line-height: 140px;
  }

  h3 {
    font-weight: 700;
    font-size: ${cssTemplate.vwConvertPc(88)};
    line-height: 1.5;
  }

  strong {
    color: ${color.GREEN};
  }

  ${media.TABLET} {
    h2 {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 150%;
    }
  }

  ${media.MOBILE} {
    h2 {
      font-size: 40px;
      line-height: 44px;
    }
  }
`;

export const ServiceContainer = styled.div<{
  layout: "full" | "half" | "reverse-half";
}>`
  ${({ layout }) => {
    switch (layout) {
      case "full":
        return css`
          .service-image {
            width: 100%;
            height: 1080px;
            margin-bottom: 120px;
          }
          .service-info {
            display: flex;
            justify-content: flex-end;
            padding: ${() => `0 ${grid.MARGIN}px`};
            word-break: keep-all;
            > div {
              width: 1025px;
              display: flex;
              flex-direction: column;
              gap: 40px;
              > span {
                :nth-child(1) {
                  font-family: "Fivo Sans Modern";
                  color: ${color.GREEN};
                  font-weight: 700;
                  font-size: ${cssTemplate.vwConvertPc(48)};
                  line-height: 1.3;
                  // font-style: italic;
                }
                :nth-child(2) {
                  color: #ffffff;
                  font-size: ${cssTemplate.vwConvertPc(48)};
                  line-height: 1.7;
                }
                :nth-child(3) {
                  display: flex;
                  width: 440px;
                  font-family: "Fivo Sans Modern";
                  color: #949494;
                  font-size: ${cssTemplate.vwConvertPc(48)};
                  line-height: 1.7;
                  // font-style: italic;
                  cursor: pointer;

                  > span {
                    transition: 0.4s;
                    &:first-child {
                    }
                    &:last-child {
                      font-style: normal;
                    }
                  }
                  &:hover {
                    color: ${color.GREEN};

                    > span {
                      &:last-child {
                        transform: translateX(50%);
                      }
                    }
                  }
                }
              }
            }
          }
        `;
      case "half":
      case "reverse-half":
        return css`
          display: flex;
          gap: 120px;
          .service-image {
            width: 1086px;
            height: 920px;
          }
          .service-info {
            display: flex;
            justify-content: flex-end;
            word-break: keep-all;
            > div {
              width: 480px;
              display: flex;
              flex-direction: column;
              gap: 40px;

              h3,
              h4 {
                width: 520px;
                font-family: "Fivo Sans Modern";
                color: #ffffff;
                font-weight: 700;
                font-size: ${cssTemplate.vwConvertPc(48)};
                line-height: 1.4;
              }
              > span {
                :nth-child(2) {
                  font-family: "Fivo Sans Modern";
                  color: ${color.GREEN};
                  font-weight: 700;
                  font-size: ${cssTemplate.vwConvertPc(32)};
                  line-height: 1.3;
                  // font-style: italic;
                }
                :nth-child(3) {
                  color: #ffffff;
                  font-size: ${cssTemplate.vwConvertPc(32)};
                  line-height: 1.7;
                }
                :nth-child(4) {
                  display: flex;
                  width: 440px;
                  font-family: "Fivo Sans Modern";
                  color: #949494;
                  font-size: ${cssTemplate.vwConvertPc(32)};
                  line-height: 1.7;
                  // font-style: italic;
                  cursor: pointer;

                  > span {
                    transition: 0.4s;
                    &:first-child {
                    }
                    &:last-child {
                      font-style: normal;
                    }
                  }
                  &:hover {
                    color: ${color.GREEN};

                    > span {
                      &:last-child {
                        transform: translateX(50%);
                      }
                    }
                  }
                }
              }
            }
          }
        `;
      default:
        return css``;
    }
  }}
  ${({ layout }) => {
    if (layout === "reverse-half") {
      return css`
        flex-direction: row-reverse;
      `;
    }
  }}

  ${media.TABLET} {
    ${({ layout }) => {
      switch (layout) {
        case "full":
        case "half":
        case "reverse-half":
          return css`
            flex-direction: column-reverse;
            .service-image {
              width: 100%;
              height: 600px;
              margin-bottom: 32px;
            }
            .service-info {
              padding: ${() => `0`};
              justify-content: flex-start;
              > div {
                width: 300px;
                gap: 16px;

                h3,
                h4 {
                  width: 550px;
                  font-size: ${cssTemplate.vwConvertTablet(40)};
                }
                > span {
                  :nth-child(2) {
                    font-weight: 700;
                    font-size: ${cssTemplate.vwConvertTablet(20)};
                  }
                  :nth-child(3) {
                    font-size: ${cssTemplate.vwConvertTablet(20)};
                  }
                  :nth-child(4) {
                    width: 180px;
                    font-size: ${cssTemplate.vwConvertTablet(20)};
                    gap: 4px;
                  }
                }
              }
            }
          `;
        default:
          return css``;
      }
    }}
  }
  ${media.MOBILE} {
    ${({ layout }) => {
      switch (layout) {
        case "full":
        case "half":
        case "reverse-half":
          return css`
            .service-image {
              height: 300px;
              margin-bottom: 32px;
            }
            .service-info {
              > div {
                width: 270px;
                gap: 16px;

                h3,
                h4 {
                  width: 310px;
                  font-size: ${cssTemplate.vwConvertMobile(22)};
                }
                > span {
                  :nth-child(2) {
                    font-size: ${cssTemplate.vwConvertMobile(17)};
                  }
                  :nth-child(3) {
                    font-size: ${cssTemplate.vwConvertMobile(17)};
                  }
                  :nth-child(4) {
                    width: 140px;
                    font-size: ${cssTemplate.vwConvertMobile(15)};
                  }
                }
              }
            }
          `;
        default:
          return css``;
      }
    }}
  }
`;

export const ServiceImage = styled.div<{ imageSrc: string }>`
  background: no-repeat center/cover url(${({ imageSrc }) => imageSrc});
`;

export const ServiceInfo = styled.div``;

const StyledArrow2 = styled(Arrow2)`
  position: absolute;

  top: 50%;
  transform: translateY(-50%);

  ${media.MOBILE} {
    width: 32px;
    height: 32px;
  }
`;

export const ArrowRight = styled(StyledArrow2)`
  right: 40px;
  ${media.MOBILE} {
    right: 20px;
  }
`;
export const ArrowLeft = styled(StyledArrow2)`
  left: 40px;
  transform: rotate(180deg) translateY(50%);
  ${media.MOBILE} {
    left: 20px;
  }
`;
export const JoinIcon = styled.div<{ hoveringImage?: string }>`
  ${({ theme, hoveringImage }) => {
    if (theme.isHovering) {
      return css`
        background-image: url(${hoveringImage ||
        "/image/webp/icons/Join.webp"});
      `;
    } else {
      return css`
        background-image: url(${"/image/webp/icons/JoinDefault.webp"});
      `;
    }
  }}
  background-size: cover;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;
export const ArrowIcon = styled(JoinArrow)`
  stroke: #646464;
  fill: #646464;
  &:hover {
    stroke: #ffffff;
    fill: #ffffff;
  }
  transition: all 0.3s;
  ${media.TABLET} {
    stroke: #ffffff;
    fill: #ffffff;
  }
  ${media.MOBILE} {
    stroke: #ffffff;
    fill: #ffffff;
  }
`;

export const ArrorRightIcon = styled.div`
  background-image: url(${"/image/webp/icons/JoinRightBtn.webp"});
`;

const ArrowDownIcon = styled.img<{
  direction: "up" | "down" | "left" | "right";
}>`
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 100px;
  left: 50%;
  opacity: 1;
  ${media.TABLET} {
    bottom: 73px;
  }

  ${({ direction }) =>
    direction === "up" &&
    ` opacity: 0;
      left: 50%;
      bottom: 100px;
      transform: translateX(-50%);
      
    `}

  ${({ direction }) =>
    direction === "down" &&
    `
      left: 50%;
      top: 0;
      transform: translateX(-50%) rotate(180deg);
    `}

  ${({ direction }) =>
    direction === "left" &&
    `
      top: 50%;
      right: 0;
      transform: translateY(-50%) rotate(-90deg);
    `}

  ${({ direction }) =>
    direction === "right" &&
    `
      top: 50%;
      left: 0;
      transform: translateY(-50%) rotate(90deg);
    `}
`;

const moveArrow = keyframes`
  0% {
    transform: translateX(-50%) translateY(-60%);
    opacity: 1;
  }
  50% {
    // transform: translateX(-50%) translateY(-75%);
  }
  100% {
    opacity: 0;
    // transform: translateX(-50%) translateY(-50%);
  }
`;

export const MovingArrow = styled(ArrowDownIcon)`
  animation: ${moveArrow} 2s ease-in-out infinite;
`;
