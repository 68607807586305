import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { cssTemplate, media } from "../../../../../constants/styles";
import { ValueUpService } from "../../../../../constants/valueUpServices";
import { useIntersecting } from "../../../../../hooks/useIntersecting";
import Responsable from "../../../../Responsable";
import { Suspense } from "../../../../Suspense";
import {
  cardDate,
  cardMain,
  cardDesc,
  maxWidth,
  ArrowIcon,
} from "../../../shared/styled";
import { ScreenType } from "../../../shared/types";
import { ReactComponent as Arrow } from "../../../../../svgs/JoinArrow.svg";
import Button from "../../../../Button";
const ValueUpCard: React.FC<{
  service: ValueUpService;
  screenType: ScreenType;
  idx: number;
}> = ({ service, screenType, idx }) => {
  const { ref, isIntersecting } = useIntersecting();
  const [interacted, setInteracted] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  useEffect(() => {
    if (isIntersecting) setInteracted(true);
  }, [isIntersecting]);
  const handleMouseOver = (e: React.TouchEvent | React.MouseEvent) => {
    e.stopPropagation();
    setIsHovering(true);
  };
  const handleMouseLeave = (e: React.TouchEvent | React.MouseEvent) => {
    e.stopPropagation();
    setIsHovering(false);
  };
  const getImageSrc = useCallback(
    (service: ValueUpService) => {
      switch (screenType) {
        case "TABLET":
          return service.tabletMainImg;
        case "MOBILE":
          return service.mobileMainImg;
        case "PC":
        default:
          return service.mainImg;
      }
    },
    [screenType]
  );

  const navigateToPage = (link: string) => {
    window.open(link, "_blank");
  };

  const renderViewText = (link: string) => {
    if (link === "end") return "서비스 종료";
    else if (link === "comming") return "서비스 준비중";
    else return "VISIT";
  };

  const renderButton = (link: string, screenType: string) => {
    if (link === "end") return <></>;
    else if (link === "comming") return <></>;
    else {
      if (screenType === "pc") {
        return (
          <Button
            style={{ marginTop: "40px" }}
            label="스쿼드 인터뷰 보기"
            isAnimated={false}
            onClick={() =>
              service.brunchLink && navigateToPage(service.brunchLink)
            }
          />
        );
      } else {
        return (
          <Button
            style={{
              padding: "10px 14px",
              borderRadius: "8px",
              marginRight: "12px",
              marginTop: screenType === "tablet" ? "32px" : "24px",
            }}
            types="gray"
            isAnimated={false}
            label={<img src="/image/webp/icons/Symbol.svg" />}
            onClick={() =>
              service.brunchLink && navigateToPage(service.brunchLink)
            }
          />
        );
      }
    }
  };

  const navLink = (link: string) => {
    if (link === "end") return;
    else if (link === "comming") return;
    else navigateToPage(link);
  };

  const renderCardContent = (screenType: string) => {
    if ((idx + 1) % 2 == 0) {
      return (
        <CardContentRight>
          <CardContentDesc>
            <CardTitle2>
              <div>
                <img
                  src={service.logSrc || "/image/webp/icons/Milk.png"}
                  width={60}
                  height={60}
                />
              </div>

              <div>{service.title}</div>
            </CardTitle2>
            <CardMainText style={{ color: "#fff" }}>
              {screenType === "mobile" && service.mobileMainDesc
                ? service.mobileMainDesc
                : service.mainDesc}
            </CardMainText>
            <CardDateText style={{ color: "#fff" }}>
              {service.date}
            </CardDateText>
            <CardSubText>{screenType === "pc" && service.subDesc}</CardSubText>
            <CardSubText>
              {screenType === "tablet" && service.tabletSubDesc}
            </CardSubText>
            <CardSubText>
              {screenType === "mobile" && service.mobileSubDesc}
            </CardSubText>
            {service.link && renderButton(service.link, screenType)}
          </CardContentDesc>
          <CardImgWrapperRight
            onClick={() => service.link && navLink(service.link)}
            url={getImageSrc(service)}
            isLink={service.link}
          >
            <div />
            {screenType === "pc" && (
              <div>
                <span>{service.link && renderViewText(service.link)}</span>
              </div>
            )}
          </CardImgWrapperRight>
        </CardContentRight>
      );
    } else {
      return (
        <CardContent>
          <CardContentDesc>
            <CardTitle2>
              <div>
                <img
                  src={service.logSrc || "/image/webp/icons/Milk.png"}
                  width={60}
                  height={60}
                />
              </div>

              <div>{service.title}</div>
            </CardTitle2>
            <CardMainText style={{ color: "#fff" }}>
              {screenType === "mobile" && service.mobileMainDesc
                ? service.mobileMainDesc
                : service.mainDesc}
            </CardMainText>
            <CardDateText style={{ color: "#fff" }}>
              {service.date}
            </CardDateText>
            <CardSubText>{screenType === "pc" && service.subDesc}</CardSubText>
            <CardSubText>
              {screenType === "tablet" && service.tabletSubDesc}
            </CardSubText>
            <CardSubText>
              {screenType === "mobile" && service.mobileSubDesc}
            </CardSubText>
            {service.link && renderButton(service.link, screenType)}
          </CardContentDesc>
          <CardImgWrapper2
            onClick={() => service.link && navLink(service.link)}
            url={getImageSrc(service)}
            isLink={service.link}
          >
            <div />
            {screenType === "pc" && (
              <div>
                <span>{service.link && renderViewText(service.link)}</span>
              </div>
            )}
          </CardImgWrapper2>
        </CardContent>
      );
    }
  };

  return (
    <CardWrapper ref={ref} theme={{ isIntersecting: interacted }}>
      <Responsable
        pc={<>{renderCardContent("pc")}</>}
        tablet={renderCardContent("tablet")}
        mobile={renderCardContent("mobile")}
      />
    </CardWrapper>
  );
};
export default ValueUpCard;

const CardContent = styled.div`
  justify-content: space-between;
  display: flex;
  gap: 40px;
  ${media.TABLET} {
    flex-wrap: wrap;
    justify-content: none;
    flex-direction: column-reverse;
    gap: 40px;
  }

  ${media.MOBILE} {
    ${media.MOBILE} {
      width: 100%;
    }
  }
`;

const CardContentRight = styled.div`
  flex-direction: row-reverse;
  justify-content: space-between;
  display: flex;
  gap: 40px;
  ${media.TABLET} {
    flex-wrap: wrap;
    justify-content: none;
    flex-direction: column-reverse;
    gap: 40px;
  }

  ${media.MOBILE} {
    width: 100%;
  }
`;

const CardContentDesc = styled.div`
  ${media.TABLET} {
    width: 100%;
  }
  ${media.MOBILE} {
    width: 100%;
  }
`;

const CardTitle2 = styled.div`
  display: flex;
  flex: 0 0 100%;
  font-size: 48px;
  line-height: 68px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 40px;
  font-family: Pretendard;
  font-style: normal;

  & > div:nth-child(1) {
    padding-right: 20px;
  }

  ${media.TABLET} {
    margin-bottom: 32px;
  }
  ${media.MOBILE} {
    font-size: 24px;
    margin-bottom: 20px;
    line-height: 36px;

    & > div:nth-child(1) {
      padding-right: 12px;
    }

    & > div:nth-child(1) img {
      width: 32px;
      height: 32px;
    }
  }
`;

const CardMainText = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 8px;

  ${media.MOBILE} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const CardDateText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 24px;
  ${media.MOBILE} {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 20px;
  }
`;

const CardSubText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #fff;
`;

const CardImg = styled.img<{ url?: string; isLink?: string }>``;

const CardImgWrapper2 = styled.div<{ url?: string; isLink?: string }>`
  overflow: hidden;
  min-width: 658px;
  height: 400px;
  width: 658px;
  position: relative;
  border-radius: 24px;
  &:hover {
    ${({ isLink }) =>
      isLink &&
      css`
        cursor: pointer;
        & > div:nth-child(1) {
          transform: scale(1.2);
          transition: all 0.3s;
          ${media.TABLET} {
            transform: none;
          }
        }
        & > div:nth-child(2) {
          display: flex;
          background-color: rgba(17, 17, 17, 0.4);
          & > span {
            opacity: 1;
            cursor: pointer;
            display: block;
          }
          transition: all 0.3s;
        }
      `}
  }

  & > div:nth-child(1) {
    /* animation: slide 0.3s; */
    /* FIXME animation !!!!! // TODO  */
    @keyframes slide {
      from {
        transform: translateY(35px);
      }
      to {
        transform: translateY(0px);
      }
    }
    transform: scale(1);
    background-image: url(${({ url }) => url});
    background-size: cover;
    height: 400px;
    // width: 658px;
    transition: all 0.3s;
    background-repeat: no-repeat;
    background-position: center;
  }

  & > div:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    background-color: rgba(17, 17, 17, 0);
    display: flex;
    & > span {
      display: none;
      font-family: "Fivo Sans Modern";
      font-style: italic;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #fff;
      vertical-align: middle;
      opacity: 0;
    }

    transition: all 0.3s;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  ${media.TABLET} {
    min-width: 327px;
    width: 100%;
    height: 100%;
    margin-left: 0;
    & > div:nth-child(1) {
      /* animation: slide 0.3s; */
      @keyframes slide {
        from {
          transform: translateY(35px);
        }
        to {
          transform: translateY(0px);
        }
      }

      transition: all 0.3s;
      background-image: url(${({ url }) => url});
      background-size: cover;
      height: 400px;
      background-position: center;
      background-repeat: no-repeat;
    }
    & > div:nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 400px;

      & > span {
        font-family: "Fivo Sans Modern";
        font-style: italic;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        // color: #1e1e1e;
        cursor: pointer;
        vertical-align: middle;
      }

      transition: all 0.3s;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
  }
  ${media.MOBILE} {
    min-width: 100px;
    width: 100%;
    height: 194px;
    & > div:nth-child(1) {
      /* animation: slide 0.3s; */
      @keyframes slide {
        from {
          transform: translateY(35px);
        }
        to {
          transform: translateY(0px);
        }
      }

      background-image: url(${({ url }) => url});
      background-size: cover;
      // max-width: 327px;
      width: 100%;
      height: 194px;
      background-position: center;
      transition: all 0.3s;
      background-repeat: no-repeat;
    }
    & > div:nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 194px;

      & > span {
        font-family: "Fivo Sans Modern";
        font-style: italic;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        // color: #1e1e1e;
        cursor: pointer;
        vertical-align: middle;
      }

      transition: all 0.3s linear;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
  }
`;

const CardImgWrapperRight = styled.div<{ url?: string; isLink?: string }>`
  min-width: 658px;
  width: 658px;
  height: 400px;
  overflow: hidden;
  position: relative;
  border-radius: 24px;
  &:hover {
    ${({ isLink }) =>
      isLink &&
      css`
        cursor: pointer;
        & > div:nth-child(1) {
          transform: scale(1.2);
          transition: all 0.3s;
          ${media.TABLET} {
            transform: none;
          }
        }
        & > div:nth-child(2) {
          display: flex;
          background-color: rgba(17, 17, 17, 0.4);
          & > span {
            opacity: 1;
            cursor: pointer;
            display: block;
          }
          transition: all 0.3s;
        }
      `}
  }

  & > div:nth-child(1) {
    /* animation: slide 0.3s; */
    /* FIXME animation !!!!! // TODO  */
    @keyframes slide {
      from {
        transform: translateY(35px);
      }
      to {
        transform: translateY(0px);
      }
    }
    transform: scale(1);
    background-image: url(${({ url }) => url});
    background-size: cover;
    height: 400px;
    // width: 658px;
    transition: all 0.3s;
    background-repeat: no-repeat;
    background-position: center;
  }

  & > div:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    background-color: rgba(17, 17, 17, 0);
    display: flex;
    & > span {
      display: none;
      font-family: "Fivo Sans Modern";
      font-style: italic;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #fff;
      vertical-align: middle;
      opacity: 0;
    }

    transition: all 0.3s;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  ${media.TABLET} {
    min-width: 327px;
    height: 100%;
    width: 100%;
    & > div:nth-child(1) {
      /* animation: slide 0.3s; */
      @keyframes slide {
        from {
          transform: translateY(35px);
        }
        to {
          transform: translateY(0px);
        }
      }
      // width: 672px;
      transition: all 0.3s;
      background-image: url(${({ url }) => url});
      background-size: cover;
      height: 400px;
      background-position: center;
      background-repeat: no-repeat;
    }
    & > div:nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 400px;

      & > span {
        font-family: "Fivo Sans Modern";
        font-style: italic;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #fff;
        cursor: pointer;
        vertical-align: middle;
      }

      transition: all 0.3s;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
  }
  ${media.MOBILE} {
    min-width: 100px;
    width: 100%;
    height: 194px;
    & > div:nth-child(1) {
      /* animation: slide 0.3s; */
      @keyframes slide {
        from {
          transform: translateY(35px);
        }
        to {
          transform: translateY(0px);
        }
      }

      background-image: url(${({ url }) => url});
      background-size: cover;
      // max-width: 327px;
      width: 100%;
      height: 194px;
      background-position: center;
      transition: all 0.3s;
      background-repeat: no-repeat;
    }
    & > div:nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 194px;

      & > span {
        font-family: "Fivo Sans Modern";
        font-style: italic;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #fff;
        cursor: pointer;
        vertical-align: middle;
      }

      transition: all 0.3s linear;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  & > div.PC {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    padding: 0px;
    gap: 40px;
    // max-width: 1200px;

    width: 100%;
    justify-content: center;
    top: 0;
    ${(props) => cssTemplate.fadeInOut(props)}
  }
  ${media.TABLET} {
    & > div.PC {
      display: none;
    }
    & > div.MOBILE {
      display: none;
    }
    & > div.TABLET {
      display: flex;
      flex-direction: column;
      align-items: space-between;
      padding: 0px;
      gap: 32px;
      // max-width: 672px;
      justify-content: column-reverse;
      top: 0;
      ${(props) => cssTemplate.fadeInOut(props)}
    }
  }
  ${media.MOBILE} {
    display: flex;
    justify-content: center;
    & > div.PC {
      display: none;
    }
    & > div.TABLET {
      display: none;
    }
    & > div.MOBILE {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 22px;
      // max-width: 327px;
      justify-content: center;
      top: 0;
      // margin: 0 auto;
      ${(props) => cssTemplate.fadeInOut(props)}
    }
  }
`;
const CardDescWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 40px;
  width: 100%;
  ${media.TABLET} {
    flex-direction: column;
    align-items: flex-start;
  }
  ${media.MOBILE} {
    gap: 26px;
    flex-direction: column;
    align-items: flex-start;
  }
`;
const CardTitle = styled.div<{ isLink?: string }>`
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 68px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 0 50%;
  padding: 0px;
  gap: 20px;
  width: 100%;
  color: #fff;
  :hover {
    ${({ isLink }) =>
      isLink &&
      css`
        cursor: pointer;
      `}
    svg {
      fill: #ffffff;
      stroke: #ffffff;
    }
  }
  ${media.MOBILE} {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    /* Gray/Gray-08 (#1e1e1e) */

    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    max-height: 40px;
    img {
      max-height: 40px;
      max-width: 40px;
    }
  }
`;
