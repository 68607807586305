import React, { CSSProperties, forwardRef } from "react";
import styled from "styled-components";
import { cssTemplate, media } from "../../constants/styles";

export interface ButtonProps {
  label: string | any;
  onClick: (e: React.MouseEvent) => void;
  isIntersecting?: any;
  isAnimated?: boolean;
  style?: CSSProperties;
  types?: string;
}
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      label,
      onClick,
      isIntersecting,
      isAnimated = true,
      style,
      types = "default",
    },
    ref
  ) => {
    return (
      <StyledButton
        style={style}
        ref={ref}
        onClick={onClick}
        isAnimated={isAnimated}
        theme={types}
      >
        {label}
      </StyledButton>
    );
  }
);
export default Button;

const StyledButton = styled.button<Pick<ButtonProps, "isAnimated">>`
  flex: none;
  background: inherit;
  border: none;
  box-shadow: none;
  border-radius: 1000px;
  padding: 0;
  overflow: visible;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 32px;
  gap: 10px;
  // width: 160px;
  // height: 48px;

  /* Primary/Neon Yellow Green 05 ⭐️ */
  &:hover {
    background-color: #1e1e1e;
    color: #fff;
  }
  transition: all 0.3s;
  background: #eee;
  color: #3c3c3c;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* or 150% */

  ${(props) => (props.isAnimated ? cssTemplate.fadeInOut(props) : "")}

  text-align: center;

  /* Gray/Gray-01 (Black) */

  ${(props) =>
    props.theme === "default"
      ? `color: #111; background-color: #B3FF01; &:hover {
    background-color: #a4eb00;
    color: #111;
  }`
      : ""}

  ${(props) =>
    props.theme === "gray"
      ? `color: #3c3c3c; background-color: #eee; &:hover {
        background-color: #dad8d8;
        color: #3c3c3c;
      }`
      : ""}

      ${(props) =>
    props.theme === "black"
      ? `color: #fff; background-color: #1e1e1e; &:hover {
            background-color: #454545;
            color: #fff;
          }`
      : ""}
  ${media.TABLET} {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */

    text-align: center;

    /* Gray/Gray-01 (Black) */

    // color: #111111;
  }
  ${media.MOBILE} {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height, or 147% */

    text-align: center;

    /* Gray/Gray-01 (Black) */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 24px;
    gap: 10px;
    // width: 160px;
    // height: 48px;

    // color: #111111;
  }
`;
