import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { joinInfo } from "../../../../constants/ref";
import {
  color,
  cssTemplate,
  grid,
  media,
  mobileContainerGap,
  mobileContainerSmallGap,
  mobilePaddingTopBottom,
  pcPaddingTopBottom,
  tabletContainerGap,
  tabletPaddingTopBottom,
} from "../../../../constants/styles";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import BaseSection from "../../BaseSection";
import {
  IntroWrapper,
  JoinIcon,
  maxWidth,
  StyledTitle,
  Title,
  TitleWrapper,
} from "../../shared/styled";

import Responsable from "../../../Responsable";
import CardWrapper from "../../shared/CardWrapper";
import Button from "../../../Button";

interface JoinSection02Props {}

const RecruitSection02: React.FC<JoinSection02Props> = (props) => {
  const { ref, isIntersecting } = useIntersecting();
  const [isInteracted, setIsInteracted] = useState(false);
  useEffect(() => {
    if (isIntersecting) setIsInteracted(true);
  }, [isIntersecting]);
  return (
    <BaseSection fullWidth>
      <SectionLayout>
        <IntroWrapper ref={ref} theme={{ isIntersecting: isInteracted }}>
          <StyledTitleWrapper>
            <StyledTitle>
              <span>Our Mission</span>
              <h1>
                필요한 프로덕트가
                <br />
                세상에 있게 만드는 것.
              </h1>
            </StyledTitle>
          </StyledTitleWrapper>
          <Responsable
            pc={
              <DescWrapper>
                이 미션을 달성하기 위해 매일 손에 잡히는 구체적인 혁신을 합니다.
                어떤 날은 성공하고 어떤 날은 실패합니다.
                <br />
                그 작고 근면한 혁신들이 쌓여 점이 선이되고 면이되어 결과물로
                나오면 놀라운 일이 벌어지는 걸 보게 됩니다.
                <br />
                그래서 우린 이 일을 좋아하고, 더 잘하고 싶습니다. 일상을 도전과
                창조로 채울 혁신가를 찾습니다.
              </DescWrapper>
            }
            tablet={
              <DescWrapper>
                이 미션을 달성하기 위해 매일 손에 잡히는 구체적인 혁신을 합니다.
                <br />
                어떤 날은 성공하고 어떤 날은 실패합니다.
                <br />
                그 작고 근면한 혁신들이 쌓여 점이 선이되고 면이되어 결과물로
                나오면 놀라운 일이 벌어지는 걸 보게 됩니다.
                <br /> 그래서 우린 이 일을 좋아하고, 더 잘하고 싶습니다.
                <br />
                일상을 도전과 창조로 채울 혁신가를 찾습니다.
              </DescWrapper>
            }
            mobile={
              <DescWrapper>
                이 미션을 달성하기 위해 매일 손에 잡히는 구체적인 혁신을 합니다.
                <br />
                어떤 날은 성공하고 어떤 날은 실패합니다.
                <br />
                그 작고 근면한 혁신들이 쌓여 점이 선이되고 면이되어 결과물로
                나오면 놀라운 일이 벌어지는 걸 보게 됩니다.
                <br /> 그래서 우린 이 일을 좋아하고, 더 잘하고 싶습니다. 일상을
                도전과 창조로 채울 혁신가를 찾습니다.
              </DescWrapper>
            }
          />
        </IntroWrapper>
        <CardWrapper cardType="Join" datas={joinInfo} />
        <Button
          isAnimated={false}
          label="채용 알아보기"
          types="black"
          onClick={(e) => {
            e.preventDefault();
            window.open(
              "https://www.rocketpunch.com/companies/slogup/jobs",
              "_blank"
            );
          }}
        />
      </SectionLayout>
    </BaseSection>
  );
};

export default RecruitSection02;

const StyledTitleWrapper = styled(TitleWrapper)``;

const DescWrapper = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  /* or 150% */

  /* Gray/Gray-05 */
  ${maxWidth}
  color: #646464;
  word-break: keep-all;

  ${media.MOBILE} {
    font-size: 16px;
    line-height: 24px;
    max-width: 100%;
  }
`;
const SectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${pcPaddingTopBottom} 48px;
  gap: 80px;
  ${media.TABLET} {
    padding: ${tabletPaddingTopBottom} 48px;
    gap: ${tabletContainerGap};
  }
  ${media.MOBILE} {
    padding: ${mobilePaddingTopBottom} 24px;
    gap: ${mobileContainerSmallGap};
  }
`;
