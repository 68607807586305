import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import outSourcingServices from "../../../../../constants/outsourcingServices";
import {
  cssTemplate,
  media,
  tabletContainerGap,
  pcContainerGap,
  tabletPaddingTopBottom,
  mobilePaddingTopBottom,
  pcPaddingTopBottom,
  mobileContainerGap,
} from "../../../../../constants/styles";
import { useIntersecting } from "../../../../../hooks/useIntersecting";
import Responsable from "../../../../Responsable";
import BaseSection from "../../../BaseSection";
import {
  BigDesc,
  cardDate,
  cardDesc,
  cardMain,
  maxWidth,
  MiniTitle,
  SmallDesc,
  Wrapper,
} from "../../../shared/styled";
import { SectionProps } from "../../../shared/types";
import Card from "./Card";

const ScrollOverlayOutsourcing: React.FC<SectionProps> = ({ screenType }) => {
  const services = outSourcingServices;
  const { ref, isIntersecting } = useIntersecting();
  const [interacted, setInteracted] = useState<boolean>(false);
  const renderBigDesc = useCallback(() => {
    switch (screenType) {
      case "MOBILE":
        return (
          <>
            국내 최고 기업들과
            <br />
            원팀으로 일합니다.
            <br />
            비즈니스까지 함께 구축합니다.
          </>
        );
      case "TABLET":
        return (
          <>
            국내 최고 기업들과
            <br />
            원팀으로 일합니다.
            <br />
            비즈니스까지 함께 구축합니다.
          </>
        );
      case "PC":
      default:
        return (
          <>
            국내 최고 기업들과 원팀으로 일합니다.
            <br />
            비즈니스까지 함께 구축합니다.
          </>
        );
    }
  }, [screenType]);
  useEffect(() => {
    if (isIntersecting) setInteracted(true);
  }, [isIntersecting]);
  return (
    <Container>
      <TextSectionWrapper>
        <TextSection ref={ref} theme={{ isIntersecting: interacted }}>
          <MiniTitle>Outsourcing Service</MiniTitle>
          <BigDesc>{renderBigDesc()}</BigDesc>

          <Responsable
            pc={
              <SmallDesc>
                아웃소싱 사업부는 SK그룹사, LG전자 등 국내 최고 기업들과
                협업합니다.
                <br />
                단순 개발·디자인의 기능적 역할이 아닌 유기적인 한 팀으로서
                비즈니스를 성공시킵니다.
              </SmallDesc>
            }
            tablet={
              <SmallDesc>
                아웃소싱 사업부는 SK그룹사, LG전자 등 국내 최고 기업들과
                협업합니다.
                <br />
                단순 개발·디자인의 기능적 역할이 아닌 유기적인 한 팀으로서
                비즈니스를
                <br />
                성공시킵니다.
              </SmallDesc>
            }
            mobile={
              <SmallDesc>
                아웃소싱 사업부는 SK그룹사, LG전자 등 국내 최고 기업들과
                협업합니다. 단순 개발·디자인의 기능적 역할이 아닌 유기적인 한
                팀으로서 비즈니스를 성공시킵니다.
              </SmallDesc>
            }
          />
        </TextSection>
      </TextSectionWrapper>
      <CardSectionWrapper>
        {services.map((service) => {
          return (
            <Card
              screenType={screenType}
              service={service}
              key={service.mainTitle}
            />
          );
        })}
      </CardSectionWrapper>
    </Container>
  );
};

export default ScrollOverlayOutsourcing;
const SectionWrapper = styled(Wrapper)`
  // padding-right: calc((100vw - 1200px) / 2);
  // padding: 20px 360px;
  align-items: flex-start;
  justify-content: flex-start;
  ${media.TABLET} {
    padding-right: 0px;
  }
`;
const TextSectionWrapper = styled(SectionWrapper)`
  gap: ${pcContainerGap};

  align-items: center;
  ${media.TABLET} {
    padding: 0px;
  }

  width: 100%;
`;
const TextSection = styled.div`
  width: 100%;
  ${(props) => cssTemplate.fadeInOut(props)}
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  ${maxWidth};

  ${media.MOBILE} {
    max-width: 100%;
  }
`;
const CardSectionWrapper = styled(SectionWrapper)`
  gap: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  ${media.MOBILE} {
    gap: 50px;
  }
`;
const Container = styled.div`
  position: relative;
  display: flex;
  max-width: 1296px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${pcPaddingTopBottom} 48px;
  gap: ${pcContainerGap};
  isolation: isolate;

  overflow: hidden;
  ${media.TABLET} {
    align-items: center;
    gap: ${tabletContainerGap};
    padding: ${tabletPaddingTopBottom} 48px;
  }
  ${media.MOBILE} {
    gap: ${mobileContainerGap};
    padding: ${mobilePaddingTopBottom} 24px;
  }
`;
