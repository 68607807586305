import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  media,
  mobilePaddingTopBottom,
  pcPaddingTopBottom,
  tabletPaddingTopBottom,
} from "../../../../../constants/styles";
import valueCards from "../../../../../constants/valueCards";
import CardWrapper from "../../../shared/CardWrapper";
import { maxWidth } from "../../../shared/styled";
import Button from "../../../../Button";
import { useIntersecting } from "../../../../../hooks/useIntersecting";

interface Props {}

const ScrollOverlayValueCardsFadeIn: React.FC<Props> = () => {
  const { ref, isIntersecting } = useIntersecting();
  const [interacted, setInteracted] = useState<boolean>(false);
  useEffect(() => {
    if (isIntersecting) setInteracted(true);
  }, [isIntersecting]);

  const data = useMemo(() => {
    return valueCards;
  }, []);
  return (
    <Container>
      <Introduction>
        <div className="smallDesc">Act Like Slogers</div>
        <div className="bigDesc">
          우리는 여섯가지 가치를
          <br />
          소중히 여깁니다.
        </div>
      </Introduction>
      <CardWrapper cardType="Value" datas={data} />
      {/* <ButtonWrapper ref={ref}>
        <Button
          types="black"
          isIntersecting={isIntersecting}
          isAnimated={false}
          label="자세히 보기"
          onClick={(e) => {
            e.preventDefault();
            window.open("https://brunch.co.kr/@team-slogup/171", "_blank");
          }}
        />
      </ButtonWrapper> */}
    </Container>
  );
};
export default ScrollOverlayValueCardsFadeIn;
const Container = styled.div`
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${pcPaddingTopBottom} 48px;
  gap: 140px;

  ${media.TABLET} {
    padding: ${tabletPaddingTopBottom} 48px;
    gap: 80px;
  }
  ${media.MOBILE} {
    padding: ${mobilePaddingTopBottom} 24px;
    gap: 80px;
  }
`;

const Introduction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%;
  ${maxWidth}
  & > div.smallDesc {
    // font-family: "Fivo Sans Modern";
    // font-style: italic;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height, or 117% */

    /* Primary/Neon Yellow Green 05 ⭐️ */

    color: #77d500;
  }

  & > div.bigDesc {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 150%;
    /* or 90px */

    /* Gray/Gray-08 (White) */

    color: #1e1e1e;
  }
  ${media.TABLET} {
    & > div.smallDesc {
      font-size: 24px;
      line-height: 28px;
    }
    & > div.bigDesc {
      font-size: 48px;
      line-height: 68px;
    }
  }
  ${media.MOBILE} {
    max-width: 100%;
    & > div.smallDesc {
      font-size: 14px;
      line-height: 16px;
    }
    & > div.bigDesc {
      font-size: 26px;
      line-height: 38px;
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 60px;
  justify-content: center;
  align-items: center;
`;
