import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { FaqCategories } from "../../constants/faqs";
import { cssTemplate, media } from "../../constants/styles";
import { useIntersecting } from "../../hooks/useIntersecting";
import { maxWidth } from "../sections/shared/styled";
interface TabMenuProps {
  menus: FaqCategories[];
  handleClickTabMenuButton: (menu: FaqCategories) => void;
  nowMenu: FaqCategories;
}
interface TabMenuButtonStyleProps {
  isNowMenu?: boolean;
}
const TabMenu: React.FC<TabMenuProps> = ({
  menus,
  nowMenu,
  handleClickTabMenuButton,
}) => {
  const { ref, isIntersecting } = useIntersecting();
  const [isInteracted, setIsInteracted] = useState(false);
  const tapBoxRef = useRef<(HTMLDivElement | null)[]>([]);

  const [divWidths, setDivWidths] = useState<number[]>([]);
  useEffect(() => {
    if (isIntersecting) setIsInteracted(true);
  }, [isIntersecting]);

  useEffect(() => {
    // 모든 DOM 요소가 렌더링된 후에 너비를 계산합니다.
    const newWidths = tapBoxRef.current
      .filter((ref: any) => ref !== null)
      .map((ref: any) => ref!.offsetWidth);
    setDivWidths(newWidths);
  }, [tapBoxRef]);

  return (
    <TabMenuWrapper ref={ref} theme={{ isIntersecting: isInteracted }}>
      {menus.map((menu, index) => {
        // console.log(tapBoxRef.current);
        return (
          <TabMenuButton
            ref={(ref) => (tapBoxRef.current[index] = ref)}
            isNowMenu={nowMenu === menu}
            onClick={(e) => {
              e.preventDefault();
              widthCheck(divWidths, index);
              handleClickTabMenuButton(menu);
            }}
            key={menu}
          >
            {menu}
          </TabMenuButton>
        );
      })}
      <Indicator nowIndex={menus.indexOf(nowMenu)} width={divWidths} />
    </TabMenuWrapper>
  );
};

export default TabMenu;

const widthCheck = (widthArray: number[], index: number) => {
  //  31 31 62 31
  let number = 0;
  for (let i = 0; i < index; i++) {
    const element = widthArray[i];
    number += element + 40;
    // console.log(element);
  }

  return number;
};

const widthMobileCheck = (widthArray: number[], index: number) => {
  //  31 31 62 31
  let number = 0;
  for (let i = 0; i < index; i++) {
    const element = widthArray[i];
    number += element + 24;
    // console.log(element);
  }

  return number;
};

const TabMenuWrapper = styled.div`
  box-sizing: border-box;
  background-color: #fff;
  // border-bottom: 1px solid #eee;
  /* Auto layout */
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  overflow-x: auto;
  width: 100%;
  // min-height: 40px;
  ${maxWidth};
  /* ${(props) => cssTemplate.fadeInOut(props)}; */
`;

const TabMenuButton = styled.div<TabMenuButtonStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin-right: 40px;
  // gap: 7px;

  // flex: 0 0 120px;
  font-family: "Fivo Sans Modern";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  // min-height: 40px;
  padding-bottom: 5px;
  cursor: pointer;
  ${({ isNowMenu }) => (isNowMenu ? nowMenuCss : defaultMenuCss)}
  ${media.TABLET} {
    // flex: 0 0 120px;
    font-size: 18px;
    line-height: 28px;
  }
  ${media.MOBILE} {
    // flex: 0 0 100px;
    font-size: 18px;
    line-height: 26px;
    margin-right: 24px;
  }
`;
const nowMenuCss = css`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #1e1e1e;
  // border-bottom: 2px solid #1e1e1e;
`;
const Indicator = styled.div<{ nowIndex: number; width?: any }>`
  position: absolute;
  bottom: 0;
  transition: transform 0.3s;
  transition-delay: 0.2s;
  ${nowMenuCss}
  width: ${({ nowIndex, width }) => width[nowIndex]}px;
  border-bottom: 2px solid #1e1e1e;

  transform: translate3d(
    ${({ nowIndex, width }) => widthCheck(width, nowIndex)}px,
    0,
    0
  );

  ${media.MOBILE} {
    transform: translate3d(
      ${({ nowIndex, width }) => widthMobileCheck(width, nowIndex)}px,
      0,
      0
    );
  }
`;

const defaultMenuCss = css`
  font-weight: 500;
  color: #1e1e1e;
`;
