import React, { useEffect, useState } from "react";
import styled, { CSSProperties, css } from "styled-components";
import { Culture } from "../../../../constants/cultures";
import { cssTemplate, media } from "../../../../constants/styles";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import Responsable from "../../../Responsable";
import {
  ArrorRightIcon,
  ArrowIcon,
  JoinIcon,
  maxWidth,
} from "../../shared/styled";
import Button from "../../../Button";

const CultureCard: React.FC<Culture> = ({
  pcImgUrl,
  tabletImgUrl,
  mobileImgUrl,
  title,
  desc,
  link,
}) => {
  const { ref, isIntersecting } = useIntersecting();
  const [isInteracted, setIsInteracted] = useState(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const handleMouseOver = (e: React.TouchEvent | React.MouseEvent) => {
    e.stopPropagation();
    setIsHovering(true);
  };
  const handleMouseLeave = (e: React.TouchEvent | React.MouseEvent) => {
    e.stopPropagation();
    setIsHovering(false);
  };

  useEffect(() => {
    if (isIntersecting) setIsInteracted(true);
  }, [isIntersecting]);
  return (
    <CardWrapper ref={ref} theme={{ isIntersecting: isInteracted }} key={title}>
      <Responsable
        widthFull
        pc={<CardImage src={pcImgUrl}></CardImage>}
        tablet={<CardImage src={tabletImgUrl}></CardImage>}
        mobile={<CardImage src={mobileImgUrl}></CardImage>}
      />
      <CardDescWrapper>
        <CardTitle>{title}</CardTitle>
        <CardInfoWrapper>
          <CardInfo>{desc}</CardInfo>
          <StyledButton
            theme="gray"
            isAnimated={false}
            onClick={() => window.open(link, "_blank")}
          >
            자세히 보기
          </StyledButton>
          {/* <CardButton
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onTouchStart={handleMouseOver}
            onTouchEnd={handleMouseLeave}
            onClick={() => window.open(link, "_blank")}
          >
            <div>자세히 보기</div>
            <CardRightIcon
              src="/image/webp/icons/JoinRightBtn.webp"
              alt="right"
            />
          </CardButton> */}
        </CardInfoWrapper>
      </CardDescWrapper>
    </CardWrapper>
  );
};

export default React.memo(CultureCard);

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 32px;
  width: 100%;
  ${maxWidth}
  ${(props) => cssTemplate.fadeInOut(props)}
  ${media.TABLET} {
    gap: 32px;
  }
  ${media.MOBILE} {
    gap: 24px;
    max-width: 100%;
  }
`;
const CardImage = styled.div<{ src: string }>`
  width: 100%;
  border-radius: 24px;
  ${maxWidth}
  height: 360px;
  background-image: url(${({ src }) => src});
  background-size: cover;
  ${media.MOBILE} {
    height: 200px;
    border-radius: 12px;
    max-width: 100%;
  }
`;

const CardDescWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 80px;
  height: 60px;
  ${media.TABLET} {
    height: unset;
  }
  ${media.MOBILE} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    height: unset;
  }
`;
const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 20px;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 38px;
  flex: 0 0 180px;
  /* identical to box height, or 146% */

  /* Gray/Gray-08 (White) */

  color: #1e1e1e;
  ${media.TABLET} {
    font-size: 26px;
    line-height: 38px;
  }
  ${media.MOBILE} {
    flex: unset;
    font-size: 20px;
    line-height: 30px;
    gap: 12px;
  }
  &:hover {
    svg {
      fill: #ffffff;
      stroke: #ffffff;
    }
  }
`;

const CardInfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const CardInfo = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* or 150% */

  /* Gray/Gray-05 */

  color: #505050;
  ${media.TABLET} {
    word-break: keep-all;
  }
  ${media.MOBILE} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export interface ButtonProps {
  label: string | any;
  onClick: (e: React.MouseEvent) => void;
  isIntersecting?: any;
  isAnimated?: boolean;
  style?: CSSProperties;
  types?: string;
}

const StyledButton = styled.button<Pick<ButtonProps, "isAnimated">>`
  flex: none;
  background: inherit;
  border: none;
  box-shadow: none;
  border-radius: 1000px;
  padding: 0;
  overflow: visible;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 32px;
  gap: 10px;
  // width: 160px;
  // height: 48px;

  /* Primary/Neon Yellow Green 05 ⭐️ */
  &:hover {
    background-color: #1e1e1e;
    color: #fff;
  }
  transition: all 0.3s;
  background: #eee;
  color: #3c3c3c;
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* or 150% */

  ${(props) => (props.isAnimated ? cssTemplate.fadeInOut(props) : "")}

  text-align: center;

  /* Gray/Gray-01 (Black) */

  ${(props) =>
    props.theme === "default"
      ? `color: #111; background-color: #B3FF01; &:hover {
    background-color: #a4eb00;
    color: #111;
  }`
      : ""}

  ${(props) =>
    props.theme === "gray"
      ? `color: #3c3c3c; background-color: #eee; &:hover {
        background-color: #dad8d8;
        color: #3c3c3c;
      }`
      : ""}

      ${(props) =>
    props.theme === "black"
      ? `color: #fff; background-color: #1e1e1e; &:hover {
            background-color: #454545;
            color: #fff;
          }`
      : ""}

      margin-top: 16px;
  ${media.TABLET} {
    margin-top: 24px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height, or 150% */

    text-align: center;

    /* Gray/Gray-01 (Black) */

    // color: #111111;
  }
  ${media.MOBILE} {
    margin-top: 12px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height, or 147% */

    text-align: center;

    /* Gray/Gray-01 (Black) */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 24px;
    gap: 10px;
    // width: 160px;
    // height: 48px;

    // color: #111111;
  }
`;
