import _ from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { interviews } from "../../constants/ref";
import { cssTemplate, media } from "../../constants/styles";
import useInterval from "../../hooks/useInterval";
import { maxWidth } from "../sections/shared/styled";
import { ScreenType } from "../sections/shared/types";

enum LOCATION {
  START,
  MIDDLE,
  END,
}
interface Props {
  screenType: ScreenType;
}
const newInterviews = [...interviews];
newInterviews.pop();
newInterviews.shift();
const Carousel: React.FC<Props> = ({ screenType }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [location, setLocation] = useState(LOCATION.MIDDLE);
  const [index, setIndex] = useState(newInterviews.length + 4);

  const [isClickButton, setIsClickButton] = useState<boolean>(false);
  const width = screenType === "PC" ? 400 : screenType === "TABLET" ? 386 : 300;
  const gap = 20;
  const scrollAmount = width + gap;
  const data = useMemo(() => {
    switch (screenType) {
      case "PC":
      case "TABLET":
      case "MOBILE":
        return [...newInterviews, ...newInterviews, ...newInterviews];

      default:
        return interviews;
    }
  }, [screenType, newInterviews]);
  const [array, setArray] = useState(data);

  const handleClick = useCallback(
    (direction: string) => {
      setIsClickButton(true);
      if (scrollRef.current) {
        if (direction === "left") {
          if (index <= newInterviews.length - 4) {
            // setArray([...newInterviews, ...newInterviews, ...newInterviews]);
            scrollRef.current?.scrollTo({
              left: scrollAmount * (index + newInterviews.length),
              behavior: "auto",
            });
            setLocation(LOCATION.START);
          } else {
            scrollRef.current?.scrollTo({
              left: scrollAmount * (index - 1),
              behavior: "smooth",
            });
            setIndex(index - 1);
          }
        } else {
          if (index >= newInterviews.length * 2 + 4) {
            scrollRef.current?.scrollTo({
              left: scrollAmount * (index - newInterviews.length),
              behavior: "auto",
            });

            setLocation(LOCATION.END);
          } else {
            scrollRef.current?.scrollTo({
              left: scrollAmount * (index + 1),
              behavior: "smooth",
            });
            setIndex(index + 1);
          }
        }
      }
    },
    [index, scrollRef.current, isClickButton, setIsClickButton]
  );
  useEffect(() => {
    if (!isClickButton) {
      if (index >= newInterviews.length * 2 + 4) {
        setIndex(index - newInterviews.length);
        setArray([...newInterviews, ...newInterviews, ...newInterviews]);
      } else if (index <= newInterviews.length - 4) {
        setIndex(index + newInterviews.length + 1);
        setArray([...newInterviews, ...newInterviews, ...newInterviews]);
      }
    }
  }, [index]);
  useEffect(() => {
    if (!isClickButton) scrollRef.current?.scrollTo(scrollAmount * index, 0);
    scrollRef.current?.addEventListener(
      "scroll",
      _.throttle((e) => {
        if (!isClickButton)
          setIndex(
            Math.floor((scrollRef.current?.scrollLeft || 0) / scrollAmount)
          );
      }, 50)
    );
  }, [array]);

  useInterval(
    () => {
      setIsClickButton(false);
    },
    isClickButton ? 1000 : null
  );
  useInterval(
    () => {
      if (location === LOCATION.START) {
        scrollRef.current?.scrollTo({
          left: scrollAmount * (index - 1),
          behavior: "smooth",
        });
      } else if (location === LOCATION.END)
        scrollRef.current?.scrollTo({
          left: scrollAmount * (index + 1),
          behavior: "smooth",
        });
      setLocation(LOCATION.MIDDLE);
    },
    location === LOCATION.START || location === LOCATION.END ? 10 : null
  );
  return (
    <ListWrapper>
      <CardButtonWrapper>
        <CardListNavButton isActive onClick={() => handleClick("left")}>
          <ArrowIcon style={{ transform: `rotate(180deg)` }}></ArrowIcon>
          <span> PREV</span>
        </CardListNavButton>
        <CardListNavButton isActive onClick={() => handleClick("right")}>
          <span>NEXT</span>
          <ArrowIcon></ArrowIcon>
        </CardListNavButton>
      </CardButtonWrapper>
      <StyledRelativeDiv>
        <ScrollContainer gap={20} ref={scrollRef}>
          {Array.from(array).map((item, index) =>
            item.blank ? (
              <BlankCard key={"blank" + index} />
            ) : (
              <Card key={(item.name || "") + index}>
                <CardInterview>{item.interview}</CardInterview>
                <CardProfile>
                  <div>
                    <h4>{item.name}</h4>
                    <span>{item.team}</span>
                  </div>
                  <img src={item.profileUrl} alt={item.name} />
                </CardProfile>
              </Card>
            )
          )}
        </ScrollContainer>
      </StyledRelativeDiv>
    </ListWrapper>
  );
};

export default Carousel;
const StyledRelativeDiv = styled.div`
  position: relative;
  width: 100vw;
`;

const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 40px;
  ${media.TABLET} {
    gap: 20px;
  }
`;
const CardButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  ${maxWidth};
  width: 100%;
`;
const CardListNavButton = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  /* identical to box height, or 120% */

  /* Gray/Gray05, White */
  color: #ffffff;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  cursor: ${({ isActive }) => (isActive ? "pointer" : "normal")};
  & > span {
    // font-family: "Fivo Sans Modern";
    // font-style: italic;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    margin-top: 4px;
    height: 24px;
  }
  ${media.TABLET} {
    & > span {
      font-size: 20px;
      line-height: 24px;
    }
  }
  ${media.MOBILE} {
    & > span {
    }
  }
`;

const ArrowIcon = styled.div`
  width: 32px;
  height: 32px;
  background-image: url("/image/team/ArrowWhite.png");
  background-size: cover;
`;

interface FlexProps {
  gap?: number;
}

const StyledFlexDiv = styled.div<FlexProps>`
  display: flex;
  align-items: center;
  // margin: 0px calc((100vw - 1200px) / 2);
  // ${media.TABLET} {
  //   margin: 0px calc((100vw - 672px) / 2);
  // }
  // ${media.MOBILE} {
  //   margin: 0px calc((100vw - 372px) / 2);
  // }
  ${({ gap }) =>
    css`
      gap: ${gap}px;
    `}
`;

const ScrollContainer = styled(StyledFlexDiv)`
  overflow-x: scroll;
  padding: 0px 48px;
  ${media.TABLET} {
    padding: 0px calc((100vw - 672px) / 2);
  }
  ${media.MOBILE} {
    padding: 0px calc((100vw - 372px) / 2);
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &::-ms-scrollbar {
    display: none;
  }
  &::-moz-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const Card = styled.div`
  border-radius: 24px;
  width: 400px;
  min-width: 400px;
  height: 498px;
  color: #ffffff;
  padding: ${() => `40px`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #1e1e1e;
  ${media.TABLET} {
    width: 386px;
    min-width: 386px;
    height: 560px;
    padding: ${() => `40px`};
  }
  ${media.MOBILE} {
    width: 300px;
    min-width: 300px;
    height: 400px;
    padding: ${() => `20px`};
  }
`;
const BlankCard = styled.div`
  width: 400px;
  min-width: 400px;
  height: 498px;
  background-color: transparent;
`;
const CardInterview = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  word-break: keep-all;
  ${media.TABLET} {
    font-size: 20px;
    line-height: 34px;
  }
  ${media.MOBILE} {
    font-size: 15px;
    line-height: 24px;
  }
`;

const CardProfile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  > div {
    display: flex;
    flex-direction: column;
    h4 {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 8px;
    }
    > span {
      font-size: 18px;
      font-weight: 400;
      color: #949494;
    }
  }
  > img {
    width: 98px;
    height: 98px;
  }

  ${media.TABLET} {
    > div {
      h4 {
        font-size: 24px;
        line-height: 36px;
      }
      > span {
        font-size: 18px;
        line-height: 28px;
      }
    }
    > img {
      width: 98px;
      height: 98px;
    }
  }
  ${media.MOBILE} {
    > div {
      h4 {
        font-size: 22px;
        margin-bottom: 8px;
        line-height: 1.5;
      }
      > span {
        font-size: 14px;
      }
    }
    > img {
      width: 70px;
      height: 70px;
    }
  }
`;
