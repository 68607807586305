import React from "react";
import styled from "styled-components";
import { whoAreSlogers } from "../../../../constants/ref";
import { color, cssTemplate, grid, media } from "../../../../constants/styles";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import Responsable from "../../../Responsable";
import BaseSection from "../../BaseSection";
import { Caption, Title } from "../../shared/styled";
import ScrollOverlayValueCardsFadeIn from "../scrollOverlays/ScrollOverlayValueCardsFadeIn";

interface TeamSection02Props {}

const TeamSection02: React.FC<TeamSection02Props> = (props) => {
  const { ref, isIntersecting } = useIntersecting();

  return (
    <CustomBaseSection ref={ref} fullWidth>
      <ScrollOverlayValueCardsFadeIn />
    </CustomBaseSection>
  );
};

export default TeamSection02;
const CustomBaseSection = styled(BaseSection)``;
