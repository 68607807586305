import React from "react";
import styled from "styled-components";
import { Section03 } from "../../../../constants/detail/types";
import { cssTemplate, media } from "../../../../constants/styles";
import BaseSection from "../../BaseSection";
import {
  ContentLayout,
  GridItem,
  GridWrap,
  ItemContents,
  SectionLayout,
  Title,
} from "../../shared/DetailSectionStyled";

interface DetailSection03Props {
  data: Section03;
}

const DetailSection03: React.FC<DetailSection03Props> = ({
  data: { goals, problemDescription, problemImage, problemTabletImage },
}) => {
  return (
    <BaseSection fullWidth autoHeight>
      <StyledSectionLayout>
        <StyledContentLayout>
          {/* Problem */}
          <ProblemWrap>
            <Title>The Problem</Title>
            <span>{problemDescription}</span>

            <ProblemImgWrap>
              {problemImage.map((image) => {
                return <ProblemImg key={image} imageSrc={image} />;
              })}
            </ProblemImgWrap>
          </ProblemWrap>

          {/* goals */}
          <div>
            <Title>Project Goals</Title>

            <GoalsWrap>
              {goals.map(({ title, subTitle }, idx) => {
                return (
                  <GoalItem key={title + idx.toString()}>
                    <GoalCnt>0{idx + 1}</GoalCnt>
                    <ItemContents>
                      <p>{title}</p>
                      <span>{subTitle}</span>
                    </ItemContents>
                  </GoalItem>
                );
              })}
            </GoalsWrap>
          </div>
        </StyledContentLayout>
      </StyledSectionLayout>
    </BaseSection>
  );
};

export default DetailSection03;

const StyledSectionLayout = styled(SectionLayout)`
  /*  */
  background: #f8f8f8;
  height: 1231px;

  ${media.TABLET} {
    padding: 60 48;
    height: auto;
  }
  ${media.MOBILE} {
    height: 1312px;
  }
`;

const StyledContentLayout = styled(ContentLayout)`
  /*  */
  > div {
    > span {
      font-size: ${cssTemplate.vwConvertPc(18)};
      line-height: 1.6;
    }
  }
  ${media.TABLET} {
    > div {
      > span {
        font-size: ${cssTemplate.vwConvertTablet(16)};
        line-height: 1.6;
      }
    }
  }
  ${media.MOBILE} {
    > div {
      > span {
        font-size: ${cssTemplate.vwConvertMobile(16)};
        line-height: 1.6;
      }
    }
  }
`;

const ProblemWrap = styled.div`
  /*  */
  margin-bottom: 80px;

  ${media.TABLET} {
    margin-bottom: 80px;
  }

  ${media.MOBILE} {
    margin-bottom: 80px;
  }
`;

const ProblemImgWrap = styled.div`
  /*  */
  display: flex;
  gap: 8px;
  margin-top: 80px;
  height: 420px;
  ${media.TABLET} {
    height: 160px;
    margin-top: 24px;
  }
  ${media.MOBILE} {
    height: 160px;
    margin-top: 24px;
  }
`;

const ProblemImg = styled.div<{ imageSrc: string }>`
  /*  */
  width: 516px;
  height: 420px;
  background: no-repeat center/cover url(${({ imageSrc }) => imageSrc});
  ${media.TABLET} {
    width: 332px;
    height: 160px;
  }
  ${media.MOBILE} {
    width: 160px;
    height: 160px;
  }
`;

const GoalsWrap = styled(GridWrap)`
  ${media.TABLET} {
    height: 324px;
    gap: 24px;
  }
  ${media.MOBILE} {
    width: 327px;
    height: 640px;
    gap: 24px;
  }
`;

const GoalItem = styled(GridItem)`
  ${media.TABLET} {
    height: 56px;
    display: block;
  }
  ${media.MOBILE} {
    width: 327px;
    height: 142px;
    display: block;
  }
`;

const GoalCnt = styled.span`
  /*  */
  width: 56px;
  height: 56px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.TABLET} {
    width: 48px;
    height: 48px;
    font-size: ${cssTemplate.vwConvertTablet(17)};
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 16px;
  }
  ${media.MOBILE} {
    width: 48px;
    height: 48px;
    font-size: ${cssTemplate.vwConvertMobile(17)};
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 16px;
  }
`;
