import React, { useEffect, useLayoutEffect, useRef } from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/Layout";

import Frame03 from "../../components/frames/Frame03";

import HomeSection01 from "../../components/sections/homeSections/HomeSection01";
import HomeSection02 from "../../components/sections/homeSections/HomeSection02";
import HomeSection03 from "../../components/sections/homeSections/HomeSection03";
import HomeSection04 from "../../components/sections/homeSections/HomeSection04";
import HomeSection05 from "../../components/sections/homeSections/HomeSection05";

import LastSection from "../../components/sections/homeSections/LastSection";
import { useLocation } from "react-router-dom";
import { navigationState } from "../../constants/ref";
import HomeSection02_2 from "../../components/sections/homeSections/HomeSection02-1";

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const valueUpRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  useLayoutEffect(() => {
    if (location.state === navigationState.SCROLL_TO_VALUE_UP)
      window.scrollTo(
        0,
        (valueUpRef.current?.getBoundingClientRect().y || 0) +
          window.innerHeight || 0
      );
    else window.scrollTo(0, 0);
  }, []);
  // useLayoutEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
    <Layout>
      <Helmet>
        <title>Slogup - Home</title>
      </Helmet>
      {/* <Frame03 /> */}
      {/* WHAT’S UP?... */}
      <HomeSection01 />

      {/*  슬로그업은 하루를... */}
      {/* <HomeSection02 /> */}

      <HomeSection02_2 />

      {/* 세상에 필요한 서비스가... */}
      <HomeSection03 ref={valueUpRef} />

      {/* 국내 최고 기업들과... */}
      <HomeSection04 />

      {/* 혁신을 손에 잡히는 모습... */}
      <HomeSection05 />

      {/* 아이디어를 문제를 해결하는... */}
      <LastSection />
    </Layout>
  );
};

export default Home;
