export enum FaqCategories {
  // ALL = "전체",
  Support = "지원",
  Recruitment = "채용",
  Company = "슬로그업",
  Etc = "기타",
}

export interface Faq {
  title: string;
  desc: string;
  category: FaqCategories;
}

export type FaqList = {
  [key in FaqCategories]: Faq[];
};
export const categories: FaqCategories[] = [
  // FaqCategories.ALL,
  FaqCategories.Company,
  FaqCategories.Support,
  FaqCategories.Recruitment,
  FaqCategories.Etc,
];
// 지원
const supportFaqs = [
  {
    title: "불합격한 이력이 있어도 재지원이 가능한가요?",
    desc: `네, 가능해요. 자세한 안내는 아래의 내용을 참고해 주세요.\n
  - 동일한 포지션의 경우: 지원 시점으로부터 6개월이 지난 이후 가능
  - 다른 포지션의 경우: 언제든 가능`,
    category: FaqCategories.Support,
  },
  {
    title: "외국인도 지원 가능한가요?",
    desc: `취업 가능한 비자를 소지하고 계시거나 발급에 문제가 없는 분이라면 지원이 가능해요.\n채용 진행과 협업은 모두 한국어를 기본으로 이루어지고 있으니 지원 시참고 부탁드려요.`,
    category: FaqCategories.Support,
  },
  {
    title: `나이, 학력, 성별에 제한이 있을까요?`,
    desc: `모든 포지션에 나이나 학력 및 성별에 제한을 두고 있지 않아요.\n 그러나, 지원자님께서 20세 미만 이신 경우에는 지원 하실 때  recruit@slogup.com 으로 별도의 공유 부탁드려요.`,
    category: FaqCategories.Support,
  },
  {
    title: `단기간 프리랜서, 인턴십, 아르바이트로 일한 경력도 인정 해주나요?`,
    desc: `프리랜서 경력의 경우 선발 직무와의 업무 관련성을 고려하여 포트폴리오 검토를 통해 인정해 드리고 있어요.\n 그러나, 인턴십과 아르바이트 경력은 경력 인정 기준에서 제외하고 있어요.`,
    category: FaqCategories.Support,
  },
  {
    title: `지원 예정인 포지션의 채용 인원이 궁금해요?`,
    desc: `홈페이지 채용 공고 또는 채용 사이트의 채용 공고를 통해 확인 하실 수 있어요.
    하지만 슬로그업에 꼭 필요한 분이라고 판단되면 채용 인원에 관련 없이 모셔오고 있어요.`,
    category: FaqCategories.Support,
  },
  {
    title: `공고 지원 마감일은 언제인가요?`,
    desc: `모집 중인 포지션에 적합한 분을 모시기 전까지 상시로 운영 되고 있어요.`,
    category: FaqCategories.Support,
  },
  {
    title: `경력직 공고에 신입도 지원이 가능한가요?`,
    desc: `공고에 명시된 경력 연차는 지원에 필요한 기본적인 참고 사항이며,
    자신이 해당 경력 연차 수준의 경험과 역량을 가지고 있다고 판단되시면 지원할 수 있으세요.`,
    category: FaqCategories.Support,
  },
  {
    title: `포트폴리오를 제출해야 하나요?`,
    desc: `포트폴리오를 필수로 제출해야 하는 직무에 해당할 경우에만 제출해 주세요. 
 필수로 제출해야 하는 직무는 아래의 내용을 참고해 주세요.\n
    - 개발자
    - 프로덕트 디자이너
    - PM`,
    category: FaqCategories.Support,
  },
  {
    title: `지원 예정이던 포지션의 채용이 마감되었습니다. 추가 모집 예정일을 알 수 있을까요?`,
    desc: `추가 모집 예정일을 정확하게 알려드리긴 어려워요. 
 해당 포지션의 채용이 완료 되었거나 채용 계획이 미정이라면 홈페이지 및 채용 사이트 공고에서 확인이 어려울 수 있어요.
 추후 더 많은 분을 모실 수 있는 슬로그업이 되도록 노력할게요.`,
    category: FaqCategories.Support,
  },
  {
    title: `이메일 지원과 외부 플랫폼 지원을 통한 지원은 채용 절차와 혜택의 차이가 있나요?`,
    desc: `동일한 절차로 진행돼요. 편하신 방법으로 지원해 주세요.
    단, recruit@slogup.com 으로 지원하실 경우 합격하신 분에게 수습기간 종료시 소정의 합격 축하금을 드리고 있어요.`,
    category: FaqCategories.Support,
  },
  {
    title: `서류 제출 양식은 없나요?`,
    desc: `자신을 가장 잘 표현할 수 있는 형태로 자유롭게 제출해 주세요.
 파일형식: 맥으로 지원하시는 분은 pdf변환 후 제출해 주시는 것을 추천드려요. 윈도우에서 호환이 안되는 문제가 있어요.`,
    category: FaqCategories.Support,
  },
  {
    title: `지원서 제출 후, 추가 서류 업데이트는 어떻게 하나요?`,
    desc: `추가서류 업데이트 및 보강이 필요한 분들은 recruit@slogup.com으로 지원 포지션 명, 성함과 함께\n 추가서류를 pdf 양식으로 송부해 주세요.`,
    category: FaqCategories.Support,
  },
  {
    title: `군 미필자, 병역 특례등의 경우 지원이 가능한가요?`,
    desc: `면제자와 미필자의 경우 지원에 제한을 두고 있지는 않아요.
 병역 특례에 관련한 사항은 recruit@slogup.com으로 문의를 통해 확인해 주세요.`,
    category: FaqCategories.Support,
  },
];

// 채용
const recruitmentFaqs = [
  {
    title: `전형에 대한 결과는 언제 알 수 있나요?`,
    desc: `각 전형의 결과는 면밀히 검토 후 이메일과 문자 또는 유선으로 최대한 빠르게 안내해 드리고 있어요.
 내부 상황에 따라 결과 안내가 지연되는 경우도 있으며, 일주일 이상 지연되는 경우에는 recruit@slogup.com을 통해 질문 부탁드려요.
 문의해 주시면 신속하게 확인 후 도와드릴게요.`,
    category: FaqCategories.Recruitment,
  },
  {
    title: `유선 면접에서는 어떤 이야기를 나누게 되나요?`,
    desc: `
 유선 면접을 위해 사전 안내 문자를 발송해 드리고 상호간 약속한 시간에 진행해요.
 진행은 인사 담당자와 편안한 분위기 속에서 15~30분 내외로 이야기를 나누시게 되어요.
 가벼운 컬처핏에 관련한 질문을 드리고 있어요.`,
    category: FaqCategories.Recruitment,
  },
  {
    title: `부족한 부분을 개선하기 위해 불합격에 대한 피드백을 받을 수 있나요?`,
    desc: `불합격에 대한 피드백은 아쉽게도 내부 가이드에 따라 공유해 드릴 수 없는 점 양해 부탁드려요.`,
    category: FaqCategories.Recruitment,
  },
  {
    title: `개발 직무의 경우 코딩테스트 전형이 따로 있나요?`,
    desc: `코딩 테스트의 경우 상황에 따라 다르게 진행되고 있어요.
 인사 담당자가 개별 안내를 드리고 있으며, 진행 일정 또한 지원자의 스케줄에 맞추어 조율해 드리고 있으니 참고 부탁드려요.`,
    category: FaqCategories.Recruitment,
  },
  {
    title: `사전 과제가 있는 포지션은 어떻게 진행 되나요?`,
    desc: `사전 과제는 포지션에 따라 상이하기에 서류 합격 후 인사 담당자가 개별로 연락드려 안내해 드리고 있어요.`,
    category: FaqCategories.Recruitment,
  },
  {
    title: `면접 시 복장 등 미리 준비해야 하는 사항이 있나요?`,
    desc: `복장은 자율 복장이며 면접관 분들도 캐주얼 의상을 입고 참석하실 예정이에요.
 화상 면접일 경우 조용하고 인터넷이 원활한 환경에서 접속해 주세요.
 카메라와 마이크도 잘 작동하는지 미리 확인 부탁드려요.`,
    category: FaqCategories.Recruitment,
  },
  {
    title: `인터뷰는 화상으로 진행 되나요?`,
    desc: `인터뷰 대부분은 대면 면접으로 진행하고 있어요.
 개인적인 사정과 거리상의 이유로 화상 면접을 원하시는 분은 유선 면접시 알려 주세요.`,
    category: FaqCategories.Recruitment,
  },
  {
    title: `대면 면접 일정은 어떻게 확정되나요?`,
    desc: `대면 면접 일정은 유선 면접 진행시에 상호간에 조율해서 진행하고 있어요.`,
    category: FaqCategories.Recruitment,
  },
  {
    title: `면접은 몇차례에 걸쳐 진행되나요?`,
    desc: `대면 면접은 1대다 면접으로 진행되며 일반적으로 1회만 진행 되요.
 추가적인 면접이 있을경우 인사 담당자가 개별적으로 연락을 드리고 있어요.`,
    category: FaqCategories.Recruitment,
  },
  {
    title: `면접위원으로 어떤 분들이 들어오시나요?`,
    desc: `면접위원으로는 채용 직무별 리더와 인사 담당자가 참여해요.
 이는 회사 사정에 의하여 변경 될 수 있어요.`,
    category: FaqCategories.Recruitment,
  },
  {
    title: `레퍼런스 체크는 어떻게 진행 되나요?`,
    desc: `대면 면접 단계에서 후보자 분의 동의 하에 레퍼런스 체크가 진행될 수 있어요.
 희망하지 않으시는 경우 레퍼런스 체크를 진행하지 않지만 채용 전형에 영향을 미칠 수 있음을 말씀드려요.`,
    category: FaqCategories.Recruitment,
  },
];

// 슬로그업
const companyFaqs: Faq[] = [
  {
    title: `어떤 복지들이 있나요?`,
    desc: `- 장기 근속자 특별 휴가 및 포상
    - 프로젝트별 회식비 지원
    - 자기 계발을 위한 도서 및 교육 지원
    - 업무 과중 발생시 보상 시차 & 휴가 지원 (연차 차감X) 등이 있어요. `,
    category: FaqCategories.Company,
  },
  {
    title: `재택근무 비율은 어떻게 되나요?`,
    desc: `프로젝트의 진행 상황에 따라 PM과 논의 후 이루어 지고 있어요.`,
    category: FaqCategories.Company,
  },
  {
    title: `슬로그업에 대해 더 알고 싶어요. 참고할 만한 사이트가 있을까요?`,
    desc: `홈페이지 푸터의 슬로그업 브런치, 플립보드 등에서 더욱 다양한 정보를 참고하실 수 있어요.`,
    category: FaqCategories.Company,
  },
];

const etcFaqs: Faq[] = [
  {
    title: `수습제도는 어떻게 운영되고 있나요?`,
    desc: `모든 입사자는 3개월 간의 온보딩 및 수습기간이 있어요.
 단, 수습평과 결과에 따라 수습 조기 종료 / 수습연장(최대 3개월 이내) / 채용 취소가 될 수 있어요.`,
    category: FaqCategories.Etc,
  },
  {
    title: `최종 합격 이후 입사 안내 메일이 오지 않았어요.`,
    desc: `간혹 Welcome mail이 프로모션함이나 스펨메일함에 들어가 있는 경우가 있으니 확인을 부탁드려요.\n 프로모션함이나 스펨메일함에서도 확인되지 않을 경우 recruit@slogup.com 으로 연락을 부탁드려요.`,
    category: FaqCategories.Etc,
  },
  {
    title: `면접 확인서와 지원 확인서는 어떻게 발급 받을 수 있을까요?`,
    desc: `면접 확인서와 지원 확인서 발급을 희망하시는 경우 recruit@slogup.com으로 문의해 주세요.`,
    category: FaqCategories.Etc,
  },
  {
    title: `각 직무별 인원은 어느정도 되나요?`,
    desc: `슬로그업 직무의 인원 구성은 대략 아래와 같아요.\n
    - PM: 5명 ~
    - 프로덕트 디자이너: 5명 ~
    - IT 엔지니어: 30명 ~`,
    category: FaqCategories.Etc,
  },
  {
    title: `급여일은 언제인가요?`,
    desc: `매월 영업일 마지막날에 개인 명의 통장으로 지급해 드리고 있어요.`,
    category: FaqCategories.Etc,
  },
  {
    title: `입사축하금은 어떻게 받을수 있나요?`,
    desc: `recruit@slogup.com 통해 이력서와 포트폴리오를 제출하신 분중 채용되신 분에게 수습이 종료되는 월에 급여와 함께 지급되요.`,
    category: FaqCategories.Etc,
  },
];

const faqs: FaqList = {
  // 전체: [...supportFaqs, ...recruitmentFaqs, ...companyFaqs, ...etcFaqs],
  지원: supportFaqs,
  채용: recruitmentFaqs,
  슬로그업: companyFaqs,
  기타: etcFaqs,
};

export default faqs;
