import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import { Title } from "../components/sections/shared/styled";

interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps> = (props) => {
  /**
   * hooks
   * ex) const [state, setState] = useState();
   */

  /**
   * handler
   * ex) const handleClick = () => {};
   */

  /**
   * renderer
   * ex) const renderComponent = () => {};
   */

  return (
    <>
      <Helmet>
        <title>404</title>
        <meta name="description" content="NotFound Page" />
      </Helmet>
      <Layout>
        <Title
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            height: "calc(var(--vh, 1vh) * 100);",
            margin: 0,
          }}
        >
          <h2>
            <strong>404</strong>
          </h2>
          <h3>Not found</h3>
        </Title>
      </Layout>
    </>
  );
};

export default NotFound;
