import React from "react";
import styled from "styled-components";
import slogupEnvironments from "../../../../constants/slogupEnvironments";
import {
  media,
  mobilePaddingTopBottom,
  mobileContainerSmallGap,
  tabletContainerGap,
  tabletPaddingTopBottom,
  pcPaddingTopBottom,
} from "../../../../constants/styles";
import BaseSection from "../../BaseSection";
import { StyledTitle, TitleWrapper } from "../../shared/styled";
import SlogupEnvironmentCard from "./SlogupEnvironmentCard";

const RecruitSection03: React.FC = () => {
  return (
    <Base>
      <SectionLayout>
        <TitleWrapper>
          <>
            <StyledTitle>
              <span>Work Environment</span>
              <h1>
                일하기 좋은 환경 <br />
                다함께 만들고 있습니다.
              </h1>
            </StyledTitle>
          </>
        </TitleWrapper>
        <CardWrapper>
          {slogupEnvironments.map((slogupEnvironment) => {
            return (
              <SlogupEnvironmentCard
                key={slogupEnvironment.arrayKey}
                {...slogupEnvironment}
              />
            );
          })}
        </CardWrapper>
      </SectionLayout>
    </Base>
  );
};

export default RecruitSection03;

const Base = styled.div`
  display: block;
  width: 100%;
  background-color: #f8f8f8;
`;
const SectionLayout = styled.div`
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: ${pcPaddingTopBottom} 48px;

  ${media.TABLET} {
    padding: ${tabletPaddingTopBottom} 48px;
  }
  ${media.MOBILE} {
    padding: ${mobilePaddingTopBottom} 24px;
  }
`;
const CardWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0px ${0} 0px;
  gap: 108px;
  // width: 100vw;
  ${media.TABLET} {
    padding: 80px 0px ${0}px 0px;
    gap: ${tabletContainerGap};
  }
  ${media.MOBILE} {
    width: 100%;
    padding: ${mobileContainerSmallGap} 0px ${0} 0px;
    gap: ${mobileContainerSmallGap};
  }
`;
