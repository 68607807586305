import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DEFAULT_INTERVAL } from "../../../../constants/animationValue";
import { media } from "../../../../constants/styles";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import useInterval from "../../../../hooks/useInterval";
import Button from "../../../Button";
import Responsable from "../../../Responsable";
import {
  JoinCard,
  JoinCardProps,
} from "../../recruitSection/RecruitSection02/JoinCard";
import ValueCard from "../../teamSections/scrollOverlays/ScrollOverlayValueCardsFadeIn/ValueCard";
import { maxWidth } from "../styled";
type CardType = "Value" | "Team" | "Join";

interface WrapperProps<T> {
  datas: T[][];
  cardType: CardType;
}
interface RowProps<T> {
  cards: T[];
  // nowInteracted?: boolean;
  // setRowIndex?: () => void;
  cardType?: CardType;
}
const JoinCardWrapper: React.FC<{
  cardType: CardType;
  mobileData: JoinCardProps[];
}> = ({ mobileData, cardType }) => {
  const { ref, isIntersecting } = useIntersecting();
  return (
    <Wrapper>
      {mobileData.map((card, index) => (
        <MobileCard
          cardType={cardType}
          key={"mobileCard" + index}
          card={card}
        />
      ))}
      {cardType !== "Join" && (
        <ButtonWrapper ref={ref}>
          <Button
            isIntersecting={isIntersecting}
            types="black"
            isAnimated={false}
            label="자세히 보기"
            onClick={(e) => {
              e.preventDefault();
            }}
          />
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};
const NotMobileCardWrapper: React.FC<WrapperProps<any>> = ({
  datas,
  cardType,
}) => {
  const { ref, isIntersecting } = useIntersecting();
  return (
    <Wrapper>
      {datas.map((cards, index) => (
        <CardRow key={cardType + index} cards={cards} cardType={cardType} />
      ))}
      {cardType !== "Join" && (
        <ButtonWrapper ref={ref}>
          <Button
            types="black"
            isIntersecting={isIntersecting}
            isAnimated={false}
            label="자세히 보기"
            onClick={(e) => {
              e.preventDefault();
              window.open("https://brunch.co.kr/@team-slogup/171", "_blank");
            }}
          />
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};
const CardRow: React.FC<RowProps<any>> = ({
  cards,
  // nowInteracted,
  // setRowIndex,
  cardType,
}) => {
  const [interActed, setInterActed] = useState<number>(-1);
  const { ref, isIntersecting } = useIntersecting();
  const animationDelay = DEFAULT_INTERVAL / 2 + 100;

  useInterval(
    () => {
      setInterActed(interActed + 1);
    },
    interActed >= 0
      ? interActed > cards.length * 2 + 1
        ? null
        : animationDelay
      : null
  );

  useEffect(() => {
    if (isIntersecting && interActed < 0) {
      setInterActed(0);
    }
  }, [isIntersecting]);
  return (
    <StyledCardRow ref={ref}>
      {cards.map((card, index) =>
        cardType === "Value" ? (
          <ValueCard
            intersecting={index + 1 <= interActed}
            key={card.arrayKey}
            {...card}
          />
        ) : (
          <JoinCard
            intersecting={index + 1 <= interActed}
            key={card.arrayKey}
            {...card}
          />
        )
      )}
    </StyledCardRow>
  );
};
const MobileCard: React.FC<{ cardType: CardType; card: any }> = ({
  cardType,
  card,
}) => {
  const { ref, isIntersecting } = useIntersecting();
  const [isInteracted, setIsInteracted] = useState(false);
  useEffect(() => {
    if (isIntersecting) setIsInteracted(true);
  }, [isIntersecting]);
  return (
    <div ref={ref}>
      {cardType === "Value" ? (
        <ValueCard intersecting={isInteracted} key={card.arrayKey} {...card} />
      ) : (
        <JoinCard intersecting={isInteracted} key={card.arrayKey} {...card} />
      )}
    </div>
  );
};
const CardWrapper: React.FC<WrapperProps<any>> = ({ datas, cardType }) => {
  const { ref, isIntersecting } = useIntersecting();
  const mobileData = datas.reduce((prev, next) => {
    const newArray: any[] = [...prev, ...next];
    return newArray;
  }, []);
  return (
    <Responsable
      pc={<NotMobileCardWrapper cardType={cardType} datas={datas} />}
      tablet={
        cardType === "Join" ? (
          <JoinCardWrapper mobileData={mobileData} cardType={cardType} />
        ) : (
          <NotMobileCardWrapper cardType={cardType} datas={datas} />
        )
      }
      mobile={
        <Wrapper>
          {mobileData.map((card, index) => (
            <MobileCard
              cardType={cardType}
              key={"mobileCard" + index}
              card={card}
            />
          ))}
          {cardType === "Value" && (
            <ButtonWrapper ref={ref}>
              <Button
                types="black"
                isIntersecting={isIntersecting}
                isAnimated={false}
                label="자세히 보기"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    "https://brunch.co.kr/@team-slogup/171",
                    "_blank"
                  );
                }}
              />
            </ButtonWrapper>
          )}
        </Wrapper>
      }
    />
  );
};

export default CardWrapper;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  ${maxWidth};
  ${media.TABLET} {
    gap: 24px;
  }
  ${media.MOBILE} {
    gap: 20px;
    max-width: 100%;
  }
`;

const StyledCardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;

  ${media.TABLET} {
    width: 100%;
    gap: 24px;
    align-items: center;
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 60px;
  justify-content: center;
  align-items: center;
`;
