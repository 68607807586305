import React from "react";
import styled, { css } from "styled-components";
import { grid, media } from "../../constants/styles";
import { Suspense } from "../Suspense";

interface ResponsableProps {
  pc?: React.ReactNode;
  pcWidthFull?: boolean;
  tablet?: React.ReactNode;
  tableWidthFull?: boolean;
  mobile?: React.ReactNode;
  mobileWidthFull?: boolean;
  backgroundColor?: string;
  widthFull?: boolean;
}

const Responsable: React.FC<ResponsableProps> = (props) => {
  return (
    <>
      {props.pc && (
        <Pc {...props} className="PC">
          {props.pc}
        </Pc>
      )}
      {props.tablet && (
        <Tablet {...props} className="TABLET">
          {props.tablet}
        </Tablet>
      )}
      {props.mobile && (
        <Mobile {...props} className="MOBILE">
          {props.mobile}
        </Mobile>
      )}
    </>
  );
};

export default Responsable;

const Pc = styled.div<ResponsableProps>`
  display: flex;
  justify-content: center;
  ${(props) =>
    (props.pcWidthFull || props.widthFull) &&
    css`
      width: 100%;
    `}

  ${({ backgroundColor }) =>
    backgroundColor === "black" &&
    css`
      background: #111;
    `}
  ${media.TABLET} {
    display: none !important;
  }
`;

const Tablet = styled.div<ResponsableProps>`
  ${(props) =>
    (props.tableWidthFull || props.widthFull) &&
    css`
      width: 100%;
    `}
  // margin: 0 auto;
  max-width: ${grid.MAX_TABLET};
  display: none;
  ${media.TABLET} {
    display: flex;
    justify-content: center;
  }
  ${media.MOBILE} {
    display: none !important;
  }
`;

const Mobile = styled.div<ResponsableProps>`
  ${(props) =>
    (props.mobileWidthFull || props.widthFull) &&
    css`
      width: 100%;
    `}

  display: none;
  ${media.MOBILE} {
    display: flex;
    justify-content: center;
  }
`;
