import React, { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import BusinessSection01 from "../../components/sections/businessSections/BusinessSection01";
import BusinessSection02 from "../../components/sections/businessSections/BusinessSection02";
import BusinessSection03 from "../../components/sections/businessSections/BusinessSection03";
import BusinessSection04 from "../../components/sections/businessSections/BusinessSection04";
import LastSection from "../../components/sections/businessSections/LastSection";

interface WorksProps {}

const Business: React.FC<WorksProps> = (props) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>Slogup - Business</title>
      </Helmet>
      {/*   With Slogup... */}
      <BusinessSection01 />

      {/* 슬로그업이 직접... */}
      <BusinessSection02 />

      {/* 슬로그업이 협력한... */}
      <BusinessSection03 />

      {/*  시간과 비용을 아낄... */}
      <BusinessSection04 />

      {/*  빛나는 아이디어에... */}
      <LastSection />
    </Layout>
  );
};

export default Business;
