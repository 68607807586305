import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { interviews } from "../../../../constants/ref";
import {
  cssTemplate,
  grid,
  media,
  mobilePaddingTopBottom,
  pcPaddingTopBottom,
  tabletPaddingTopBottom,
} from "../../../../constants/styles";
import { useIntersecting } from "../../../../hooks/useIntersecting";
import Carousel from "../../../Carousel";
import MobileCarousel from "../../../Carousel/MobileCarousel";
import Responsable from "../../../Responsable";
import BaseSection from "../../BaseSection";
import { StyledTitle, Title, TitleWrapper } from "../../shared/styled";

interface TeamSection03Props {}

const TeamSection03: React.FC<TeamSection03Props> = (props) => {
  const { ref, isIntersecting } = useIntersecting();
  const [isInteracted, setIsInteracted] = useState(false);
  useEffect(() => {
    if (isIntersecting) setIsInteracted(true);
  }, [isIntersecting]);
  return (
    <BaseSection3 ref={ref}>
      <SectionLayout theme={{ isIntersecting: isInteracted }}>
        <TitleWrapper>
          <StyledTitle>
            <span className="black-title">Reasons Why</span>
            <h1 className="black-title">
              여러분은 왜<br />
              슬로그업을 선택했나요?
            </h1>
          </StyledTitle>
        </TitleWrapper>
        <Responsable
          pc={<Carousel screenType="PC" />}
          tablet={<MobileCarousel screenType="TABLET" />}
          mobile={<MobileCarousel screenType="MOBILE" />}
        />
      </SectionLayout>
    </BaseSection3>
  );
};

export default TeamSection03;

const SectionLayout = styled.div`
  display: flex;
  background-color: #111;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: 120px;
  padding: ${pcPaddingTopBottom} 48px;

  ${(props) => cssTemplate.fadeInOut(props)};

  ${media.TABLET} {
    padding: ${tabletPaddingTopBottom} 48px;
    gap: 80px;
  }

  ${media.MOBILE} {
    padding: ${mobilePaddingTopBottom} 24px;
    gap: 60px;
  }
`;

const BaseSection3 = styled.div`
  display: block;
  width: 100%;
`;
