import React, { useEffect, useRef } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { Section01 } from "../../../../constants/detail/types";
import { detailKeyValueState } from "../../../../constants/recoil";
import { cssTemplate, media } from "../../../../constants/styles";
import { ReactComponent as Close2 } from "../../../../svgs/close.svg";
import { ReactComponent as Close } from "../../../../svgs/white-close.svg";
import Responsable from "../../../Responsable";
import BaseSection from "../../BaseSection";

interface DetailSection1Props {
  data: Section01;
}

const DetailSection01: React.FC<DetailSection1Props> = ({
  data: {
    backgroundSrc,
    mobileBackgroundSrc,
    serviceDescription,
    tableBackgroundSrc,
  },
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const setIsDetailOpen = useSetRecoilState(detailKeyValueState);
  const stateCheck = useRecoilValue(detailKeyValueState);

  const handleClose = () => {
    setIsDetailOpen((prev) => {
      return {
        ...prev,
        isAnimation: false,
      };
    });
  };

  return (
    <BaseSection ref={ref} fullWidth autoHeight>
      <SectionLayout
        backgroundSrc={backgroundSrc}
        tableBackgroundSrc={tableBackgroundSrc}
        mobileBackgroundSrc={mobileBackgroundSrc}
      >
        <Responsable
          pc={
            <CloseWrap onClick={handleClose}>
              <StyledClose />
              Close
            </CloseWrap>
          }
          tablet={
            <>
              <CloseWrap onClick={handleClose}>
                <StyledClose2 />
              </CloseWrap>
            </>
          }
          mobile={
            <>
              <CloseWrap onClick={handleClose}>
                <StyledClose2 />
              </CloseWrap>
            </>
          }
        />

        <ProjectDescription>
          {serviceDescription.map((description) => {
            return <li key={description}>{description}</li>;
          })}
        </ProjectDescription>
      </SectionLayout>
    </BaseSection>
  );
};

export default DetailSection01;

const SectionLayout = styled.div<{
  backgroundSrc: string;
  tableBackgroundSrc?: string;
  mobileBackgroundSrc: string;
}>`
  /*  */
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  background: no-repeat center/cover
    url(${({ backgroundSrc }) => backgroundSrc});

  ${media.TABLET} {
    height: 1024px;
    background-image: url(${({
      tableBackgroundSrc,
      backgroundSrc,
      mobileBackgroundSrc,
    }) => tableBackgroundSrc || backgroundSrc || mobileBackgroundSrc});
  }
  ${media.MOBILE} {
    height: 420px;
    background-image: url(${({ mobileBackgroundSrc }) => mobileBackgroundSrc});
  }
`;

const CloseWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 77px;

  position: absolute;
  right: 120px; // FIXME 수정될꺼 같음
  top: 72px;
  font-size: ${cssTemplate.vwConvertPc(20)};
  line-height: 1.5;
  color: #ffffff;
  cursor: pointer;

  ${media.MOBILE} {
    width: 32px;
    right: 20px;
    top: 20px;
  }
`;

const StyledClose = styled(Close)`
  /*  */
  width: 18px;
  height: 18px;
`;

const StyledClose2 = styled(Close2)`
  /*  */
  width: 32px;
  height: 32px;
`;

const ProjectDescription = styled.ul`
  /*  */
  position: absolute;
  left: 60px;
  bottom: 60px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: white;

  > li {
    font-size: ${cssTemplate.vwConvertPc(20)};
    font-weight: 400;
    line-height: 1.5;
  }

  ${media.MOBILE} {
    left: 24px;
    bottom: 24px;
    gap: 4px;

    > li {
      font-size: ${cssTemplate.vwConvertMobile(14)};
      line-height: 1.4;
    }
  }
`;
