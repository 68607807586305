import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { cssTemplate, media } from "../../../../constants/styles";
import { useIntersecting } from "../../../../hooks/useIntersecting";
interface Props {
  smallText: string;
  bigText: React.ReactNode;
}
const Text: React.FC<Props> = ({ smallText, bigText }) => {
  const { ref, isIntersecting } = useIntersecting();
  const [isInteracted, setIsInteracted] = useState<boolean>(false);
  useEffect(() => {
    if (isIntersecting) setIsInteracted(true);
  }, [isIntersecting]);
  return (
    <TextWrapper ref={ref} theme={{ isIntersecting: isInteracted }}>
      <div className="small">{smallText}</div>
      <div className="big">{bigText}</div>
    </TextWrapper>
  );
};
export default Text;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 12px;
  ${(props) => cssTemplate.fadeInOut(props)}
  & > div.small {
    // font-family: "Fivo Sans Modern";
    // font-style: italic;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height, or 117% */

    text-align: center;

    /* Primary/Neon Yellow Green 05 ⭐️ */

    color: #b3ff01;
  }
  & > div.big {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 150%;
    /* or 90px */

    text-align: center;

    /* Gray/Gray-08 (White) */

    color: #ffffff;

    /* Title */

    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
  }
  ${media.TABLET} {
    & > div.small {
      // font-family: "Fivo Sans Modern";
      // font-style: italic;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      /* identical to box height, or 117% */

      text-align: center;

      /* Primary/Neon Yellow Green 05 ⭐️ */

      color: #b3ff01;
    }
    & > div.big {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 68px;
      /* or 142% */

      text-align: center;

      /* Gray/Gray-08 (White) */

      color: #ffffff;
    }
  }
  ${media.MOBILE} {
    & > div.small {
      // font-family: "Fivo Sans Modern";
      // font-style: italic;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      /* identical to box height, or 114% */

      text-align: center;

      /* Primary/Neon Yellow Green 05 ⭐️ */

      color: #b3ff01;
    }
    & > div.big {
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 38px;
      /* or 146% */

      text-align: center;

      /* Gray/Gray-08 (White) */

      color: #ffffff;
    }
  }
`;
