import React from "react";

export interface SlogupEnvironment {
  arrayKey: string;
  pcImgUrl: string;
  tabletImgUrl: string;
  mobileImgUrl: string;
  mainText: React.ReactNode;
  pcSubText: React.ReactNode;
  mobileSubText: React.ReactNode;
  explains1: React.ReactNode[];
  explains2: React.ReactNode[];
}

const slogupEnvironments: SlogupEnvironment[] = [
  {
    arrayKey: "env1",
    pcImgUrl: "/image/webp/recruit/Env1-Pc.webp",
    tabletImgUrl: "/image/webp/recruit/Env1-Tablet.webp",
    mobileImgUrl: "/image/webp/recruit/Env1-Mobile.webp",
    mainText: "기본에 충실합니다.",
    pcSubText: (
      <>
        일에 온전히 집중할 수 있도록 업무환경을 <br />
        돌보고 개선합니다.
      </>
    ),
    mobileSubText: (
      <>일에 온전히 집중할 수 있도록 업무환경을 돌보고 개선합니다.</>
    ),
    explains1: [
      "100% 실력기반, 합리적 연봉평가",
      "닉네임 호칭, 수평적 토론문화",
      "도서/교육 구입 등 개인성장 지원",
      "청년내일채움공제 등 정부 연계지원",
      "4대보험 등 기본사항 당연히 지원",
    ],
    explains2: [
      "합정역 인근 힙한 건물 통사용",
      "웰컴 KIT와 굿즈",
      "맥북 등 최고급 업무장비",
      "듀얼 모니터 / 시디즈 의자",
      "사내 미니 문방구 / 도서관",
    ],
  },
  {
    arrayKey: "env2",
    pcImgUrl: "/image/webp/recruit/Env2-Pc.webp",
    tabletImgUrl: "/image/webp/recruit/Env2-Tablet.webp",
    mobileImgUrl: "/image/webp/recruit/Env2-Mobile.webp",
    mainText: "헌신에 보답합니다",
    pcSubText: (
      <>
        서로를 위한 서로의 헌신을 감사히 여기고
        <br />
        최대한 보답합니다.
      </>
    ),
    mobileSubText: (
      <>서로를 위한 서로의 헌신을 감사히 여기고 최대한 보답합니다.</>
    ),

    explains1: [
      "인센티브 및 스톡옵션 제도",
      "장기근속자 특별휴가 및 포상",
      "연차 차감 없는 오버워크 보상휴가",
      "직무별 유연출근제",
    ],
    explains2: [
      "불가피한 야근시 카카오T안심귀가",
      "야근시 식대 지원",
      "명절/경조사비 지원",
      "주차비 지원",
    ],
  },
  {
    arrayKey: "env3",
    pcImgUrl: "/image/webp/recruit/Env3-Pc.webp",
    tabletImgUrl: "/image/webp/recruit/Env3-Tablet.webp",
    mobileImgUrl: "/image/webp/recruit/Env3-Mobile.webp",
    mainText: "쉴 때는 푹 쉽니다",
    pcSubText: (
      <>
        컨디션 관리 위한 강약조절과 <br />
        휴식의 중요성을 놓치지 않습니다.
      </>
    ),
    mobileSubText: (
      <>컨디션 관리 위한 강약조절과 휴식의 중요성을 놓치지 않습니다.</>
    ),
    explains1: [
      "1시간 10분의 여유로운 점심시간",
      "액티비티데이 (조기퇴근/문화회식)",
      "월 적립 가능한 팀 회식비",
      "운동 공간 (탁구대/헬스장)",
      "생일 케익 및 반차",
    ],
    explains2: [
      "평화로운 휴게실 / 안마의자",
      "무한리필 미니 스낵바",
      "최고급 원두 커피 및 머신",
      "맥주 냉장고",
      "게임기 (PlayStation)",
    ],
  },
];
export default slogupEnvironments;
